import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  CancelButton, SubmitButton, FontAwesome,
  DefaultModal, BasicCard, Cards,
} from '~UI/index';
import { sortArray } from '~utils/sort';

const MultipleMachinesPopup = ({
  showPopup,
  showMachineList,
  operation,
  validMachines,
  machinesSelected,
  cancelPopup,
  setShowMachineList,
  selectMachine,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {showPopup && <div className="fade modal-backdrop in" />}
      <DefaultModal
        closePopup={cancelPopup}
        show={showPopup}
        title={showMachineList
          ? t('selectYourMachines')
          : t('modifyMultipleMachines')
        }
        style={{ zIndex: 1500 }}
      >
        {
          !showMachineList ? (
            <div className="ConfirmationText">
              {t('applyToOtherMachines')}
              <div className="actions">
                <button
                  type="button"
                  className="PopupButton cancel"
                  onClick={operation}
                >
                  {t('no')}
                </button>
                <button
                  type="button"
                  className="PopupButton"
                  style={{ backgroundColor: '#0078FF' }}
                  onClick={() => setShowMachineList(true)}
                >
                  {t('yes')}
                </button>
              </div>
            </div>
          ) : (
            <div>
              <Cards>
                {
                  sortArray('alphabetically', validMachines, 'name')
                    .map(machine => (
                      <BasicCard
                        key={machine.id}
                        title={machine.name}
                        icon={<FontAwesome icon="gears" size="4x" />}
                        onClick={() => selectMachine(machine.id)}
                        style={{
                          ...(machinesSelected.includes(machine.id) && ({ boxShadow: '0px 0px 4px 2px #0078FF' })),
                        }}
                      />
                    ))
                }
              </Cards>
              <div className="buttonsHolder flexSpaceBetween">
                <div />
                <div>
                  <CancelButton onClick={cancelPopup} />
                  <SubmitButton label={t('confirm')} onClick={e => operation(e, machinesSelected)} />
                </div>
              </div>
            </div>
          )
        }
      </DefaultModal>
    </>
  );
};

MultipleMachinesPopup.propTypes = {
  showPopup: PropTypes.bool,
  showMachineList: PropTypes.bool.isRequired,
  operation: PropTypes.func.isRequired,
  validMachines: PropTypes.arrayOf(PropTypes.shape({})),
  machinesSelected: PropTypes.arrayOf(PropTypes.shape({})),
  cancelPopup: PropTypes.func.isRequired,
  setShowMachineList: PropTypes.func.isRequired,
  selectMachine: PropTypes.func.isRequired,
};

MultipleMachinesPopup.defaultProps = {
  showPopup: false,
  validMachines: [],
  machinesSelected: [],
};

export default MultipleMachinesPopup;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Select, Loader, ButtonGroup, Checkbox } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import API from '~services/endpoints';
import { RootState } from '~services/store';
import { sortArray } from '~utils/sort';
import { SubmitButton, TextArea, IntervalTimePicker } from '~UI/index';
import HTMLEditor from '../CodeEditor/HTMLEditor';
import './Forms.scss';

const MACHINE_STATE_SCHEMA = {
  plannedDowntime: 3600000,
  idleDowntime: 3600000,
  changeoverDowntime: 14219322,
  minorStopDowntime: 3600000,
  failureDowntime: 3600000,
  stopCauses: [
    {
      stopCauseId: 'ccedbaec-5819-4fff-b3ac-dfcb6f0cb154',
      duration: 14219322,
    },
  ],
  uptime: 3600000,
  useRate: 0.76,
  operatingRate: 0.76,
  plannedProductionTime: 14219322,
  availability: 0.76,
  unplannedDowntime: 14219322,
  totalDowntime: 14219322,
  totalTime: 14219322,
  loadFactor: 1,
  filledInDowntime: 14219322,
  filledInStopCauseRatio: 1,
  inputCount: 4,
  outputCount: 4,
  scrapCount: 2,
  acceptableOutputCount: 2,
  qualityRatio: 0.5,
  unitCompletionTime: 30000,
  idealOperatingTime: 3600000,
  lostOperatingTime: 14219322,
  plannedOutputCount: 473.9774,
  hourlyPlannedOutputCount: 120,
  hourlyInputCount: 0,
  hourlyOutputCount: 0,
  hourlyAcceptableOutputCount: 0,
  hourlyScrapCount: 0,
  minutelyPlannedOutputCount: 2,
  minutelyInputCount: 0,
  minutelyOutputCount: 0,
  minutelyAcceptableOutputCount: 0,
  minutelyScrapCount: 0,
  performanceRatio: 0.5,
  projectedRemainingTime: 14219322,
  overallEffectiveness: 0.5,
  totalEffectivePerformance: 0.5,
  skuNumbers: [
    'Spoon',
  ],
  operations: [
    'Operation 1',
  ],
  workOrders: [
    'Work Order 1',
  ],
  currentOperation: 'Operation 1',
  currentSKUNumber: 'Spoon',
  currentWorkOrder: 'Work Order 1',
  currentOperator: 'Antonio Bandera de la vega',
  currentStopCause: {
    id: 'ccedbaec-5819-4fff-b3ac-dfcb6f0cb154',
    name: 'Setup',
    type: 'changeover',
  },
  timestampStart: 1721818800000,
  timestampEnd: 1721844000000,
};

const VALUE_SCHEMA = {
  id: 'a6b1b3b4-0b3b-4b3b-8b3b-0b3b3b3b3b3b',
  name: 'refers to "variable" field in the variables list',
  value: 14219322,
};

const FormPrompt = ({ selectedObject }) => {
  const { t } = useTranslation();

  const machines = useSelector((state: RootState) => state.machines);
  const variables = useSelector((state: RootState) => state.variables);
  const streams = useSelector((state: RootState) => state.streams);
  const settings = useSelector((state: RootState) => state.settings.settings);
  const assistantsOptions = (settings?.aiAssistants?.filter(a => a.type === 'make') || [])
    .map(a => ({ label: a.name, value: a.id }));
  const [assistantId, setAssistantId] = useState('');

  const [dataType, setDataType] = useState(selectedObject.dataType || '');

  const [machineId, setMachineId] = useState(selectedObject.machineId);
  const [currentOperation, setCurrentOperation] = useState(selectedObject.currentOperation || false);
  const [currentSKUNumber, setCurrentSKUNumber] = useState(selectedObject.currentSKUNumber || false);
  const [currentWorkOrder, setCurrentWorkOrder] = useState(selectedObject.currentWorkOrder || false);
  const [intervalType, setIntervalType] = useState(selectedObject.intervalType || 'shift');

  const [url, setUrl] = useState(selectedObject.url);
  const [text, setText] = useState('');
  const [htmlResult, setHTMLResult] = useState(selectedObject.html);
  const [isLoading, setIsLoading] = useState(false);

  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));

  const handleMachineIdChange = value => {
    setMachineId(value);
  };

  const sendPromptToGenerateHTML = async () => {
    setIsLoading(true);

    const machine = dataType === 'machine' ? machines.find(m => m.id === machineId) : null;

    let variablesList: any[] = [];
    if (dataType === 'variables') {
      const properties = [].concat(...streams.map(s => s.properties));
      const kpis = [].concat(...machines.map(m => m.kpis || []));
      variablesList = [...variables, ...properties, ...kpis];

      const { values } = await API.getMultiplesValues(variablesList.map(v => v.id));

      variablesList = variablesList.map((v, index) => ({
        ...v,
        value: values[index]?.value || null,
      }));
    }

    fetch(`https://hook.us1.make.com/${assistantId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prompt: text,
        ...(machine ? { machine } : {}),
        ...(variablesList.length ? { variables: variablesList } : {}),
        ...(dataType === 'machine' ? { schema: MACHINE_STATE_SCHEMA } : {}),
        ...(dataType === 'variables' ? { schema: VALUE_SCHEMA } : {}),
      }),
    })
      .then(response => response.text())
      .then(async html => {
        setIsLoading(false);
        if (html) {
          const htmlWitoutMarkdown = html.replace('```html', '').replace('```', '');
          setHTMLResult(htmlWitoutMarkdown);
        }
      })
      .catch(error => {
        console.error('error:', error);
      });
  };

  const generateFile = htmlToGenerate => {
    setHTMLResult(htmlToGenerate);
    // Convert HTML string to a Blob
    const blob = new Blob([htmlToGenerate], { type: 'text/html' });
    const file = new File([blob], `${new Date().toISOString()}.html`, { type: 'text/html' });

    // Prepare FormData
    const formData = new FormData();
    formData.append('file', file);

    // Send the FormData with the file
    API.sendPromptTileHtmlFile(formData)
      .then(response => {
        setUrl(response.fileResponse);
      })
      .catch(error => {
        console.error('error:', error);
      });
  };

  const renderMachineForm = () => (
    <>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        value={machineId}
        onChange={handleMachineIdChange}
        placeholder={t('select')}
      />
      <div>
        <div className="multipleSelectionInput">
          <Checkbox
            controlledCheck={currentOperation}
            checked={currentOperation}
            onChange={setCurrentOperation}
            label={t('currentOperation')}
          />
        </div>
        <div className="multipleSelectionInput">
          <Checkbox
            controlledCheck={currentSKUNumber}
            checked={currentSKUNumber}
            onChange={setCurrentSKUNumber}
            label={t('currentSKUNumber')}
          />
        </div>
        <div className="multipleSelectionInput">
          <Checkbox
            controlledCheck={currentWorkOrder}
            checked={currentWorkOrder}
            onChange={setCurrentWorkOrder}
            label={t('currentWorkOrder')}
          />
        </div>
      </div>
      <div>
        <input
          type="hidden"
          name="boolean:currentOperation"
          value={currentOperation as any}
        />
        <input
          type="hidden"
          name="boolean:currentSKUNumber"
          value={currentSKUNumber as any}
        />
        <input
          type="hidden"
          name="boolean:currentWorkOrder"
          value={currentWorkOrder as any}
        />
      </div>

      <div className="inputTitle">{t('dataCollectionPeriod')}</div>
      <IntervalTimePicker
        intervalType={intervalType}
        intervalTypeInput
        changeIntervalType={value => setIntervalType(value)}
      />
    </>
  );

  const dataTypesButtons = [
    { label: t('machine'), value: 'machine' },
    { label: t('variables'), value: 'variables' },
  ];

  return (
    <div className="Form">
      <div className="inputTitle">{t('DataType')}</div>
      <input type="hidden" name="dataType" value={dataType} />
      <ButtonGroup
        buttons={dataTypesButtons}
        onChange={setDataType}
        value={dataType}
      />
      {dataType === 'machine' && renderMachineForm()}
      <div className="inputTitle">{t('assistantAI')}</div>
      <Select
        options={assistantsOptions}
        value={assistantId}
        onChange={setAssistantId}
        placeholder={t('selectAssistant')}
      />
      <div className="inputTitle">Prompt</div>
      <TextArea
        onChange={setText}
        value={text}
      />
      {
        text && assistantId && dataType && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {
              isLoading
                ? <Loader />
                : (
                  <SubmitButton
                    label={`${t('generateHTML')}`}
                    onClick={sendPromptToGenerateHTML}
                  />
                )
            }
          </div>
        )
      }
      <div className="inputTitle">Generated Html</div>
      <HTMLEditor
        html={htmlResult}
        onSubmit={generateFile}
      />
      <input type="hidden" value={htmlResult} name="html" />
      <div className="inputTitle">URL</div>
      <input
        name="url"
        type="text"
        value={url}
        onChange={e => setUrl(e.target.value)}
        className="fullwidth"
      />
    </div>
  );
};

FormPrompt.propTypes = {
  selectedObject: PropTypes.shape({
    machineId: PropTypes.string,
    intervalType: PropTypes.string,
    currentOperation: PropTypes.bool,
    currentSKUNumber: PropTypes.bool,
    currentWorkOrder: PropTypes.bool,
    url: PropTypes.string,
    html: PropTypes.string,
    dataType: PropTypes.string,
  }),
};
FormPrompt.defaultProps = {
  selectedObject: {},
};

export { FormPrompt };

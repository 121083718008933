import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SelectionGrid } from '@intelligenceindustrielle/react-ui-components';
import { CancelButton, SubmitButton, FontAwesome } from '~UI';
import { reducersTypes } from '~services';
import { getTriggerIcon } from '~utils/icons';
import { getFormData } from '~utils';
import { triggerTypes } from '~utils/types';
import { showError } from '~utils/toast';
import { validateVarExists } from '~utils/parser';
import {
  CronTriggerForm,
  MachineParamTriggerForm,
  StopCauseTriggerForm,
  ValueTriggerForm,
  WebhookTriggerForm,
} from './triggerForms';
import '../RuleEditionPages.scss';

const propTypes = {
  trigger: reducersTypes.triggers.trigger.isRequired,
  handleUpdateTrigger: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

const defaultProps = {};

const types = [
  { type: triggerTypes.BUTTON, name: 'button', icon: getTriggerIcon(triggerTypes.BUTTON) },
  { type: triggerTypes.CRON, name: 'cronJob', icon: getTriggerIcon(triggerTypes.CRON) },
  { type: triggerTypes.MACHINE_PARAM_CHANGE, name: 'machineParam', icon: getTriggerIcon(triggerTypes.MACHINE_PARAM_CHANGE) },
  { type: triggerTypes.STOP_CAUSE, name: 'stopCause', icon: getTriggerIcon(triggerTypes.STOP_CAUSE) },
  { type: triggerTypes.VALUE, name: 'value', icon: getTriggerIcon(triggerTypes.VALUE) },
  { type: triggerTypes.WEBHOOK, name: 'webhook', icon: getTriggerIcon(triggerTypes.WEBHOOK) },
];

const TriggerEditionForm = ({ trigger: triggerProp, handleUpdateTrigger, closePopup }) => {
  const { t } = useTranslation();

  const didMount = useRef(false);

  const [trigger, setTrigger] = useState({ ...triggerProp } || {});
  const [selectIconMenu, setSelectIconMenu] = useState(!triggerProp);

  const handleSubmit = e => {
    if (e) { e.preventDefault(); }

    const formElement = document.getElementById('typedTriggerForm');
    let formData = {};
    if (formElement) {
      formData = { ...getFormData('typedTriggerForm') };
    }

    if (!validateVarExists(formData)) {
      showError(t('variableUsedIsNotDefined'));
      return;
    }

    handleUpdateTrigger(trigger, formData);
    closePopup();
  };

  const selectTriggerType = newTriggerType => {
    trigger.type = newTriggerType;
    setTrigger({
      ...trigger, type: newTriggerType,
    });
    setSelectIconMenu(false);
    if ([triggerTypes.DEFAULT, triggerTypes.BUTTON].includes(trigger.type)) {
      // skip form for default and button trigger
      handleSubmit();
    }
  };

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    }
  }, [trigger, selectIconMenu]);

  const setSelectionIconMenu = () => {
    setSelectIconMenu(true);
  };

  const selectedTriggerForm = () => {
    switch (trigger.type) {
      case 'CronTrigger':
        return <CronTriggerForm trigger={trigger} />;
      case 'MachineParamChangeTrigger':
        return <MachineParamTriggerForm trigger={trigger} selectTriggerType={selectTriggerType} />;
      case 'StopCauseAssignTrigger':
      case 'StopCauseTrigger':
        return <StopCauseTriggerForm trigger={trigger} selectTriggerType={selectTriggerType} />;
      case 'ValueChangeTrigger':
      case 'ValueSetTrigger':
      case 'ValueTrigger':
        return <ValueTriggerForm trigger={trigger} selectTriggerType={selectTriggerType} />;
      case 'WebhookTrigger':
      case 'DefaultTrigger':
        return <WebhookTriggerForm trigger={trigger} />;
      default:
        return null;
    }
  };

  const typeTranslation = {
    DefaultTrigger: t('webhook'),
    ButtonTrigger: t('button'),
    CronTrigger: t('cronJob'),
    MachineParamChangeTrigger: t('machineParam'),
    StopCauseAssignTrigger: t('stopCause'),
    StopCauseTrigger: t('stopCause'),
    ValueChangeTrigger: t('value'),
    ValueSetTrigger: t('value'),
    ValueTrigger: t('value'),
    WebhookTrigger: t('webhook'),
  };
  return (
    <div className="triggerComponent">
      {selectIconMenu ? (
        <SelectionGrid
          onChange={selectTriggerType}
          options={
            types.map(({ icon, name, type }) => ({
              bgColor: '#1B62FF',
              Icon: <FontAwesome icon={icon} size="3x" height="51" />,
              label: t(name),
              value: type,
            }))
          }
        />
      )
        : (
          <>
            <div
              style={{ display: 'none' }}
              className="triggerType"
            />
            <div className="selectedTriggerControl">
              <div className="selectedTrigger">
                <FontAwesome icon={getTriggerIcon(trigger.type)} />
                {typeTranslation[trigger.type]}
              </div>
              <div
                className="closeTrigger"
                onClick={setSelectionIconMenu}
                role="button"
              >
                <FontAwesome
                  icon="times"
                  size="2x"
                />
              </div>
            </div>
            <form id="typedTriggerForm" onSubmit={handleSubmit}>
              {selectedTriggerForm()}
              <div className="buttonsHolder" style={{ textAlign: 'right' }}>
                <CancelButton onClick={closePopup} />
                <SubmitButton label={t('modify')} />
              </div>
            </form>
          </>
        )}
    </div>
  );
};

TriggerEditionForm.propTypes = propTypes;
TriggerEditionForm.defaultProps = defaultProps;

export default TriggerEditionForm;

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useShift } from '~utils/hooks';
import { FontAwesome } from '~UI';
import { ParetoChart } from '~UI/svg';
import API from '~services/endpoints';
import Tick from '~utils/Tick';
import { canNavigateTime, navigateTimeUtil } from '../BarChartTile/utils';
import { getDataRangeStartEnd, dateToStringWithoutYearAndMillis } from '~utils/time';
import TileContents from '../TileContents';
import '../TileContents.scss';

const FETCH_STATS_DELAY_MS = 10000;

const propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  tile: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    machineId: PropTypes.string,
    intervalType: PropTypes.string,
    showArrows: PropTypes.bool,
    nbDisplayedRows: PropTypes.number,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

const ParetoChartTile = ({ backgroundColor, height, tile, width }) => {
  const container = useRef();

  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const language = useSelector(state => state.views.language);

  const [currentShift, shifts] = useShift(tile.machineId);
  const [timePeriod, setTimePeriod] = useState(getDataRangeStartEnd(tile.intervalType || 'shift', currentShift));
  const [hasUsedArrows, setHasUsedArrows] = useState(false);

  const updateTimePeriod = () => {
    if (!hasUsedArrows) {
      const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
      setTimePeriod(newTimePeriod);
    }
  };

  const machine = machines.find(m => m.id === tile.machineId);
  if (!machine) {
    return (
      <h3>{t('machineIsNotConfiguredOrDeleted')}</h3>
    );
  }

  const { data: stats } = useQuery({
    queryKey: ['fetchParetoMachineStats', tile.machineId, tile.intervalType || 'shift', timePeriod],
    queryFn: () => {
      const intervalType = tile.intervalType || 'shift';

      const filter = {
        currentOperation: false,
        currentSKUNumber: false,
        currentWorkOrder: false,
      };
      if (intervalType !== 'always') {
        const { start, end } = timePeriod;
        filter.timestamp = {
          $gt: start,
          $lt: end,
        };
      }

      return API.getMachineStats(tile.machineId, filter);
    },
    refetchInterval: FETCH_STATS_DELAY_MS,
    keepPreviousData: true,
  });

  const navigateTime = goBackward => {
    const { newTimePeriod, hasUsedArrows: newHasUsedArrows } = navigateTimeUtil(goBackward, timePeriod, shifts, tile.intervalType || 'shift');
    setTimePeriod(newTimePeriod);
    setHasUsedArrows(newHasUsedArrows);
  };

  useEffect(() => {
    Tick.subscribe(updateTimePeriod, 10);

    return () => {
      Tick.unsubscribe(updateTimePeriod);
    };
  }, [currentShift, hasUsedArrows]);

  useEffect(() => {
    const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
    setTimePeriod(newTimePeriod);
  }, [tile, tile.intervalType, currentShift]);

  useEffect(() => {
    setHasUsedArrows(false);
  }, [tile.intervalType]);

  const navigationText = hasUsedArrows
    && `${t('from2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.start))} ${t('to2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.end))}`;

  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      ref={container}
      height={height}
      width={width}
    >
      <div className="timeNavigationContainer">
        {navigationText ? <div>{navigationText}</div> : <div>&nbsp;</div>}
        <div className="timeNavigation">
          {tile.showArrows && (
            <FontAwesome
              icon="arrow-left"
              className="timeNavigationButton"
              style={{
                opacity: !canNavigateTime(true, timePeriod, shifts, tile.intervalType || 'shift') && 0.4,
                cursor: !canNavigateTime(true, timePeriod, shifts, tile.intervalType || 'shift') && 'default',
              }}
              onClick={() => navigateTime(true)}
            />
          )}
          <ParetoChart
            height={height}
            width={width}
            stats={stats}
            tile={tile}
            machines={machines}
            showArrows={tile.showArrows}
            nbDisplayedRows={tile.nbDisplayedRows}
            language={language}
          />
          {tile.showArrows && (
            <FontAwesome
              icon="arrow-right"
              className="timeNavigationButton"
              style={{
                opacity: !canNavigateTime(false, timePeriod, shifts, tile.intervalType || 'shift') && 0.4,
                cursor: !canNavigateTime(false, timePeriod, shifts, tile.intervalType || 'shift') && 'default',
              }}
              onClick={() => navigateTime(false)}
            />
          )}
        </div>
      </div>
    </TileContents>
  );
};

ParetoChartTile.propTypes = propTypes;

export default ParetoChartTile;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getSocket } from '~services/socket';
import { DefaultModal, PushButton } from '~UI/index';
import FontAwesome from '~UI/FontAwesome/FontAwesome';
import { evaluate } from '~utils/parser';
import { getApproximateFontSize } from '~utils/responsiveValue';
import JsxWidget from './BaseWidgets/JsxWidget';

function InfoWidget({
  backgroundColor, h, w, x, y, widget,
}) {
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [mediaURL, setMediaURL] = useState();
  let isSocketInitialized = false;

  const handleClosePopup = () => {
    setShowInfoPopup(false);
  };

  const handleInfoClick = () => {
    setShowInfoPopup(true);
  };

  const handleSocketData = socketData => {
    const websocketData = JSON.parse(socketData);
    if (widget.rules) {
      let newURL = widget.url || '';
      const mediaRules = widget.rules.filter(rule => rule.type === 'media');
      for (const mediaRule of mediaRules) {
        if (evaluate(mediaRule.condition, websocketData, mediaRule.variableType)) {
          newURL = mediaRule.media;
          break;
        }
      }
      setMediaURL(newURL);
    }
  };

  useEffect(() => {
    const socket = getSocket();
    if (socket && widget.url && !isSocketInitialized) {
      socket.on('data', handleSocketData);
      isSocketInitialized = true;
    }
    return () => {
      if (socket) {
        socket.removeListener('data', handleSocketData);
      }
    };
  }, [widget, mediaURL]);

  const btnLength = Math.min(w, h) / 1.2;
  const fontSize = getApproximateFontSize(widget.buttonText, btnLength, btnLength, widget.zoom);

  return (
    <JsxWidget
      dimension={{ x, y, w, h }}
      backgroundColor={backgroundColor}
      shape={widget.format.shape}
    >

      {
        widget.style === 'button' ? (
          <div className="ButtonTile framed flex">
            <PushButton
              buttonSize={btnLength}
              buttonColor="#CCCCCC"
              buttonText="Info"
              onClick={handleInfoClick}
              fontSize={fontSize / 1.7}
            />
          </div>
        ) : (
          <div className="framed flex V Info">
            <button
              className="info-button"
              onClick={handleInfoClick}
              type="button"
            >
              <FontAwesome
                icon="info-circle"
                style={{ fontSize: btnLength, position: 'relative' }}
              />
            </button>
          </div>
        )
      }

      <DefaultModal
        show={showInfoPopup}
        title="Info"
        closePopup={handleClosePopup}
        fullSize
      >
        <div style={{ height: '80vh' }}>
          {(widget.mediaType === 'iframe') ? (
            <div
              className="framed flex V"
            >
              <iframe
                style={{
                  border: 0,
                  width: '100%',
                  height: '100%',
                }}
                title="iframeTile"
                src={mediaURL}
              >
                <p>Your browser does not support iframes</p>
              </iframe>
            </div>
          ) : (
            <div className="framed flex V">
              <img
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                src={mediaURL}
                alt="Failed to load"
              />
            </div>
          )}
        </div>
      </DefaultModal>
    </JsxWidget>
  );
}

InfoWidget.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export default InfoWidget;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getSocket } from '~services/socket';
import { evaluate } from '~utils/parser';
import WidgetContents from './WidgetContents';

const DEFAULT_BACKGROUND_COLOR = '#444444';

const Widget = ({
  onClick, h, w, x, y, widget, fetchDelay,
}) => {
  const socket = getSocket();
  const widgetRef = useRef(widget);

  useEffect(() => {
    widgetRef.current = widget;
  }, [widget]);

  const [backgroundColor, setBackgroundColor] = useState(DEFAULT_BACKGROUND_COLOR);
  const [isSocketInitialized, setIsSocketInitialized] = useState(false);

  const handleSocketData = socketData => {
    const websocketData = JSON.parse(socketData);

    let newBackgroundColor = DEFAULT_BACKGROUND_COLOR;
    if (widgetRef.current.rules) {
      const colorRules = widgetRef.current.rules.filter(rule => rule.type === 'color');
      for (const rule of colorRules) {
        if (evaluate(rule.condition, websocketData, rule.variableType)) {
          newBackgroundColor = rule.color;
          break;
        }
      }
    }
    setBackgroundColor(newBackgroundColor);
  };

  useEffect(() => {
    if (socket && !isSocketInitialized) {
      socket.on('data', handleSocketData);
      setIsSocketInitialized(true);
    }

    return () => {
      socket?.removeListener('data', handleSocketData);
    };
  }, []);

  useEffect(() => {
    if (socket && !isSocketInitialized) {
      socket.on('data', handleSocketData);
      setIsSocketInitialized(true);
    }

    return () => {
      socket?.removeListener('data', handleSocketData);
    };
  }, [isSocketInitialized, socket]);

  const WidgetContent = WidgetContents[widget.type] || WidgetContents.empty;

  return (
    <g onClick={onClick}>
      <WidgetContent
        widget={widget}
        fetchDelay={fetchDelay}
        backgroundColor={backgroundColor}
        x={x}
        y={y}
        w={w}
        h={h}
      />
    </g>
  );
};

Widget.propTypes = {
  onClick: PropTypes.func,
  fetchDelay: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string,
  }).isRequired,
};
Widget.defaultProps = {
  onClick: () => undefined,
};

export default Widget;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IdleTimer from 'react-idle-timer';
import { useQuery } from '@tanstack/react-query';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { getMetric, getStatisticsFrom } from '~components/utils';
import { useShift } from '~utils/hooks';
import { getDataRangeStartEnd } from '~utils/time';
import { round } from '~utils/math';
import CircularChart from './CircularChartSVG';

const FETCH_STATS_DELAY_MS = 5000;

const CircularChartMachine = ({
  selectedObject, dimension, textColor, isTile, fetchDelay,
}) => {
  const socket = getSocket();
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);

  const [currentShift] = useShift(selectedObject.machineId);
  const [goal, setGoal] = useState(selectedObject.goal);

  const idleTimerRef = useRef(null);

  const handleSocketData = socketData => {
    if (selectedObject.objectiveType === 'variable' && socketData.id === selectedObject.goal) {
      const goalToSet = round(socketData.value, 2);
      if (goalToSet >= 0 && goalToSet <= 100) {
        setGoal(goalToSet);
        return;
      }
      setGoal(-1);
    }
  };

  const machine = machines.find(m => m.id === selectedObject.machineId);
  if (!machine) {
    return (
      <h3>{t('machineIsNotConfiguredOrDeleted')}</h3>
    );
  }

  const { data: statistics, refetch } = useQuery({
    queryKey: ['fetchGaugeMachineStats', selectedObject.machineId, selectedObject.intervalType || 'shift',
      selectedObject.currentOperation, selectedObject.currentSKUNumber,
      selectedObject.currentWorkOrder, selectedObject.currentWorkOrder, selectedObject.currentOperation && getStatisticsFrom(selectedObject.valueType || 'useRate') === 'operation'],
    queryFn: () => {
      const {
        machineId,
        valueType = 'useRate',
        intervalType = 'shift',
        currentOperation = false,
        currentSKUNumber = false,
        currentWorkOrder = false,
      } = selectedObject;

      if (selectedObject.valueType === 'operationProgress') {
        selectedObject.currentOperation = true;
      }

      if (currentOperation && getStatisticsFrom(valueType) === 'operation') {
        const filter = {
          machineId,
        };
        if (intervalType !== 'always') {
          const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
          filter.timestamp = {
            $gt: start,
            $lt: end,
          };
        }

        return API.getOperationStats(machine.params.operation, filter);
      }
      const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
      const filter = {
        currentOperation,
        currentSKUNumber,
        currentWorkOrder,
        timestamp: {
          $gt: start,
          $lt: end,
        },
      };

      return API.getMachineStats(machineId, filter);
    },
    refetchInterval: fetchDelay || FETCH_STATS_DELAY_MS,
    keepPreviousData: true,
  });

  const getInterval = () => {
    const metric = getMetric(selectedObject.valueType || 'useRate');
    if (selectedObject.valueType === 'operationProgress') {
      return { max: statistics?.requiredQuantity, min: 0 };
    }
    if (metric === 'unit') {
      return { max: selectedObject.intervalMax, min: 0 };
    }
    return null;
  };

  useEffect(() => {
    const listener = data => handleSocketData(data);
    socket.on('value', listener);

    if (selectedObject.objectiveType === 'variable' && selectedObject.goal) {
      API.getValues(selectedObject.goal, {}, 1).then(response => {
        const goalToSet = response?.values[0] && round(response.values[0].value, 2);
        if (goalToSet >= 0 && goalToSet <= 100) {
          setGoal(goalToSet);
          return;
        }
        setGoal(-1);
      });
    } else {
      setGoal(selectedObject.goal);
    }

    return () => {
      if (socket) {
        socket.removeListener('value', listener);
      }
    };
  }, [selectedObject, currentShift]);

  const handleOnIdle = () => {
    refetch();
    idleTimerRef.current.reset();
  };

  const handleOnActive = () => {
    refetch();
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        onIdle={handleOnIdle}
        onActive={handleOnActive}
        debounce={250}
        timeout={1000 * 90}
      />
      <CircularChart
        dimension={dimension}
        textColor={textColor}
        currentValue={statistics ? statistics[selectedObject.valueType || 'useRate'] : 0}
        metric={getMetric(selectedObject.valueType || 'useRate')}
        interval={getInterval()}
        showGoalToggle={typeof selectedObject.showGoalToggle === 'boolean' ? selectedObject.showGoalToggle : typeof selectedObject.goal === 'number'} // for backward compatibiltity with no toogle
        goal={getMetric(selectedObject.valueType) === 'percentage' ? goal / 100 : goal}
        isTile={isTile}
        title={selectedObject.title}
        aboveGoalColor={selectedObject.aboveGoalColor}
        belowGoalColor={selectedObject.belowGoalColor}
      />
    </>
  );
};

CircularChartMachine.propTypes = {
  dimension: PropTypes.object.isRequired,
  selectedObject: PropTypes.shape({
    currentOperation: PropTypes.bool,
    currentSKUNumber: PropTypes.bool,
    currentWorkOrder: PropTypes.bool,
    objectiveType: PropTypes.string,
    goal: PropTypes.number,
    id: PropTypes.string,
    intervalMax: PropTypes.number,
    intervalType: PropTypes.string,
    machineId: PropTypes.string,
    showGoalToggle: PropTypes.bool,
    title: PropTypes.string,
    valueType: PropTypes.string,
    aboveGoalColor: PropTypes.string,
    belowGoalColor: PropTypes.string,
  }).isRequired,
  textColor: PropTypes.string.isRequired,
  isTile: PropTypes.bool,
  fetchDelay: PropTypes.number,
};
CircularChartMachine.defaultProps = {
  fetchDelay: FETCH_STATS_DELAY_MS,
  isTile: false,
};

export default CircularChartMachine;

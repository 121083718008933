import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import API from '~services/endpoints';
import { AudioRecorder } from '~components/UI/AudioRecorder/AudioRecorder';
import { Popover } from '~components/Popover';
import HTMLEditor from '~components/CodeEditor/HTMLEditor';
import { FontAwesome, TextInput, CheckboxToggle } from '~UI/index';
import { RootState } from '~services/store';
import { idToReadableExpr, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import './ActionForm.scss';

const EmailActionForm = ({ action }) => {
  const { params } = action;

  const { t } = useTranslation();
  const variables = useSelector((state: RootState) => state.variables);
  const machines = useSelector((state: RootState) => state.machines);
  const streams = useSelector((state: RootState) => state.streams);
  const users = useSelector((state: RootState) => state.users.users);

  const [emailAddresses, setEmaiAddresses] = useState((params && ((params.emailAddresses && params.emailAddresses.toString()) || params.emailAddress)) || '');
  const [message, setMessage] = useState((params && params.message) || '');
  const [subject, setSubject] = useState((params && params.subject) || '');
  const [disableStyling, setDisableStyling] = useState((params && params.disableStyling) || false);
  const [userEmails] = useState(sortArray('alphabetically', users, 'username')
    .map(user => (user.username.includes('@') ? user.username : null)).filter(e => !!e));

  const [showAIPopover, setShowAIPopover] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [threadId, setThreadId] = useState('');
  const [aiGeneratedHTML, setAIGeneratedHTML] = useState(false);
  const [loading, setLoading] = useState(false);

  const emailAssistant = useSelector((state: RootState) => state.settings.settings.aiAssistants.find(assistant => assistant.name === 'Email HTML'));

  const onSubjectChange = subjectArg => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];
    let id = subjectArg;
    try {
      id = variableToId(subjectArg, inputsAndVariables);
    } catch (error) {
      // Error handling of non existent variable is made in the ActionEditionForm
      // This way the error message is popped only on submit and not on change
    }
    setSubject(id);
  };

  const onMessageChange = messageArg => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    let id = messageArg;
    try {
      id = variableToId(messageArg, inputsAndVariables);
    } catch (error) {
      // Error handling of non existent variable is made in the ActionEditionForm
      // This way the error message is popped only on submit and not on change
    }

    setMessage(id);
  };

  const idToReadable = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    return idToReadableExpr(value, inputsAndVariables);
  };

  const handleDisableStylingToggle = () => {
    setDisableStyling(prevDisableStyling => !prevDisableStyling);
  };

  const handlePromptSubmit = async promptArg => {
    if (aiGeneratedHTML && threadId) {
      API.sendMessageGPT(promptArg, emailAssistant.id, threadId).then(response => {
        setMessage(response.messages[response.messages.length - 1].text);
        setLoading(false);
        setAIGeneratedHTML(true);
      },
      );
    } else {
      API.initializeChat(emailAssistant.id, promptArg).then(response => {
        setMessage(response.result);
        setThreadId(response.threadId);
        setLoading(false);
        setAIGeneratedHTML(true);
      },
      );
    }
  };

  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable').map(x => x.variable);
  inputsAndVariables.splice(0, 0, 'NOW');

  return (
    <div className="emailActionForm">
      <div>
        <div className="inputTitle">{t('sendTo')}</div>
        <TextInput
          name="emailAddresses"
          options={userEmails}
          value={emailAddresses}
          className="fullwidth"
          onChange={e => setEmaiAddresses(e)}
          placeholder={`${t('email')}1, ${t('email')}2`}
        />
      </div>

      <div>
        <div className="inputTitle">{t('emailSubject')}</div>
        <input
          type="hidden"
          value={subject}
          name="subject"
        />
        <TextInput
          options={inputsAndVariables}
          trigger="$"
          value={subject ? idToReadable(subject) : null}
          className="fullwidth"
          onChange={e => onSubjectChange(e)}
          placeholder={t('triggerVariableList')}
        />
      </div>

      <div>
        <div className="messageInput">
          <div className="inputTitle">
            {t('message')}
          </div>
          <div>
            <Popover
              show={showAIPopover}
              setShow={setShowAIPopover}
              className="ai-email-body"
              content={(
                <>
                  <div className="promptTitleSection">
                    <div className="promptTitle">{t('Prompt')}</div>
                    <div className="promptAudio">
                      <AudioRecorder setText={text => setPrompt(prompt + text)} />
                    </div>
                  </div>
                  <textarea
                    value={prompt}
                    onChange={e => setPrompt(e.target.value)}
                    placeholder={t('describeEmailMessage')}
                    className="promptTextArea"
                  />
                  <div className="emailAIFooter">
                    <button
                      type="button"
                      onClick={() => {
                        setShowAIPopover(false);
                        setAIGeneratedHTML(false);
                        setMessage('');
                      }}
                      className="promptButtonCancel"
                    >
                      {t('cancel')}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setShowAIPopover(false);
                        setLoading(true);
                        handlePromptSubmit(prompt);
                      }}
                      className="promptButtonSubmit"
                    >
                      {t('generateEmail')}
                    </button>
                  </div>
                </>
              )}
            >
              <button
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  setShowAIPopover(!showAIPopover);
                }}
                className="AIButton"
              >
                <FontAwesome icon="sparklesGradient" height="10px" />
              </button>
            </Popover>
          </div>
        </div>
        <input
          type="hidden"
          value={message}
          name="message"
        />
        {loading && <Loader />}
        {!loading && aiGeneratedHTML && (
          <HTMLEditor
            html={message}
          />
        )}
        {!loading && !aiGeneratedHTML && (
          <TextInput
            options={inputsAndVariables}
            trigger="$"
            value={message ? idToReadable(message) : null}
            className="fullwidth"
            onChange={e => onMessageChange(e)}
            placeholder={t('triggerVariableList')}
          />
        )}
      </div>
      <div>
        <div className="inputTitle">{t('disableStyling')}</div>
        <CheckboxToggle
          name="disableStyling"
          controlledCheck={disableStyling}
          onChange={handleDisableStylingToggle}
        />
      </div>
    </div>
  );
};

EmailActionForm.propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      emailAddress: PropTypes.string,
      emailAddresses: PropTypes.string,
      message: PropTypes.string,
      subject: PropTypes.string,
      disableStyling: PropTypes.bool,
    }).isRequired,
  }),
};
EmailActionForm.defaultProps = {
  action: {
    params: {
      emailAddress: '',
      emailAddresses: '',
      message: '',
      subject: '',
      disableStyling: false,
    },
  },
};

export default EmailActionForm;

const idToReadableExpr = (expression, expressionList) => {
  let readableExpr = ` ${expression}`.slice(1);
  if (typeof expression === 'string') {
    // eslint-disable-next-line no-template-curly-in-string
    const regExNOW = new RegExp('\\${NOW}', 'g');
    readableExpr = readableExpr.replace(regExNOW, '$NOW');
    const regex = /\$\{[A-Za-z0-9_-]*\}/g;
    const variablesInExpression = expression.match(regex) || [];
    variablesInExpression.forEach(match => {
      const idMatch = match.substring(2, match.length - 1);
      expressionList.forEach(elem => {
        if (idMatch === elem.id) {
          readableExpr = readableExpr.replace(`\${${elem.id}}`, `$${elem.variable}`);
        }
      });
    });
  } else if (typeof expression !== 'number') {
    throw new Error('Invalid expression in idToReadableExpr');
  }

  return readableExpr;
};

export default idToReadableExpr;

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Duration } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import IdleTimer from 'react-idle-timer';
import { reducersTypes } from '~services';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { useShift } from '~utils/hooks';
import { getStatisticsFrom } from '~components/utils';
import { roundStr, floor } from '~utils/math';
import { getDataRangeStartEnd } from '~utils/time';
import ResponsiveScorecard from './ResponsiveScorecard';

const FETCH_STATS_DELAY_MS = 5000;

const ScorecardMachine = ({
  backgroundColor, dimension, isCircle, loaderPos, selectedObject, fetchDelay,
}) => {
  const socket = getSocket();
  const selectedObjectRef = useRef(selectedObject);
  const { t } = useTranslation();

  useEffect(() => {
    selectedObjectRef.current = selectedObject;
  });

  const machines = useSelector(state => state.machines);

  const machine = machines.find(m => m.id === selectedObject.machineId);

  const [machineParams, setMachineParams] = useState(machine && machine.params);
  const [currentShift] = useShift(selectedObject.machineId);
  const idleTimerRef = useRef(null);

  const { data: statistics, refetch } = useQuery({
    queryKey: ['fetchScorecardMachineStats', selectedObject.machineId, selectedObject.currentOperation,
      selectedObject.currentSKUNumber, selectedObject.currentWorkOrder, selectedObject.intervalType,
      selectedObject.currentOperation && getStatisticsFrom(selectedObject.machineProperty) === 'operation'],
    queryFn: () => {
      const intervalType = selectedObject.intervalType || 'shift';
      if (!selectedObject.machineId) {
        return null;
      }

      if (selectedObject.currentOperation && getStatisticsFrom(selectedObject.machineProperty) === 'operation') {
        const filter = {
          machineId: selectedObject.machineId,
        };
        if (intervalType !== 'always') {
          const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
          filter.timestamp = {
            $gt: start,
            $lt: end,
          };
        }

        return API.getOperationStats(machineParams.operation, filter);
      }
      const filter = {
        currentOperation: selectedObject.currentOperation,
        currentSKUNumber: selectedObject.currentSKUNumber,
        currentWorkOrder: selectedObject.currentWorkOrder,
      };
      if (intervalType !== 'always') {
        const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
        filter.timestamp = {
          $gt: start,
          $lt: end,
        };
      }

      return API.getMachineStats(selectedObject.machineId, filter);
    },
    refetchInterval: fetchDelay || FETCH_STATS_DELAY_MS,
    keepPreviousData: true,
  });

  const formatValue = () => {
    const { machineProperty, machinePropertyStopCauseId, decimals } = selectedObject;
    if (statistics) {
      if (statistics[machineProperty] === null) {
        return { value: '-', units: '' };
      }

      switch (machineProperty) {
        case 'productionRate':
          return { value: `${roundStr(statistics.hourlyInputCount, decimals || 0)} / ${roundStr(statistics.hourlyPlannedOutputCount, decimals || 0)}` };
        case 'minutelyProductionRate':
          return { value: `${roundStr(statistics.minutelyInputCount, decimals || 0)} / ${roundStr(statistics.minutelyPlannedOutputCount, decimals || 0)}` };
        case 'productionRatio':
          return { value: `${roundStr(statistics.inputCount, decimals || 0)} / ${roundStr(statistics.uptime / statistics.unitCompletionTime, decimals || 0)}` };
        case 'minutelyProductionRatio':
          return { value: `${roundStr(statistics.inputCount / 60, decimals || 0)} / ${roundStr((statistics.uptime / statistics.unitCompletionTime) / 60, decimals || 0)}` };
        case 'plannedProductionRatio':
          return { value: `${roundStr(statistics.inputCount, decimals || 0)} / ${roundStr(statistics.plannedOutputCount, decimals || 0)}` };
        case 'minutelyPlannedProductionRatio':
          return { value: `${roundStr(statistics.inputCount / 60, decimals || 0)} / ${roundStr(statistics.plannedOutputCount / 60, decimals || 0)}` };
        case 'plannedOutputCount':
        case 'inputCount':
        case 'outputCount':
        case 'acceptableOutputCount':
        case 'scrapCount':
        case 'hourlyPlannedOutputCount':
        case 'hourlyInputCount':
        case 'hourlyOutputCount':
        case 'hourlyAcceptableOutputCount':
        case 'hourlyScrapCount':
        case 'minutelyPlannedOutputCount':
        case 'minutelyInputCount':
        case 'minutelyOutputCount':
        case 'minutelyAcceptableOutputCount':
        case 'minutelyScrapCount':
        case 'requiredQuantity':
        case 'quantityLeft':
          return { value: floor(statistics[machineProperty], decimals || 0) };
        case 'availability':
        case 'useRate':
        case 'overallEffectiveness':
        case 'totalEffectivePerformance':
        case 'operatingRate':
          return { value: `${roundStr(statistics[machineProperty] * 100, decimals)}`, units: '%' };
        case 'performanceRatio':
        case 'qualityRatio':
        case 'filledInStopCauseRatio':
          return { value: `${roundStr(statistics[machineProperty] * 100, decimals)}`, units: '%' };
        case 'unitCompletionTime':
        case 'uptime':
        case 'changeoverDowntime':
        case 'failureDowntime':
        case 'idleDowntime':
        case 'plannedDowntime':
        case 'minorStopDowntime':
        case 'unplannedDowntime':
        case 'plannedProductionTime':
          return { value: Duration.fromMillis(statistics[machineProperty]).toFormat('hh:mm').replace(':', 'h') };
        case 'timeLeft':
        case 'projectedRemainingTime':
          if (statistics[machineProperty] >= 3600000) {
            return { value: Duration.fromMillis(statistics[machineProperty]).toFormat('hh:mm').replace(':', 'h') };
          }
          return { value: Duration.fromMillis(statistics[machineProperty]).toFormat('mm:ss').replace(':', 'm') };
        case 'stopCauseDowntime': {
          const stopCause = statistics.stopCauses?.find(obj => obj.stopCauseId === machinePropertyStopCauseId);
          return { value: Duration.fromMillis((stopCause || {}).duration || 0).toFormat('hh:mm').replace(':', 'h') };
        }
        case 'currentStopCause':
          if (statistics[machineProperty]?.name === 'unknown') {
            return { value: t('unknown') };
          }
          return { value: statistics[machineProperty]?.name || '-' };
        default:
          return { value: statistics[machineProperty] };
      }
    }

    return { value: '', units: '' };
  };

  const handleSocketMachines = message => {
    const { machineId } = selectedObjectRef.current;
    if (message.type === 'MACHINE_PARAM_UPDATE' && message.machineId === machineId) {
      setMachineParams(message.params);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('machines', handleSocketMachines);
    }

    const newMachine = machines.find(m => m.id === selectedObject.machineId);
    setMachineParams(newMachine && newMachine.params);

    return () => {
      if (socket) {
        socket.removeListener('machines', handleSocketMachines);
      }
    };
  }, [socket, selectedObject, currentShift]);

  const handleOnActive = () => {
    refetch();
  };

  const handleOnIdle = () => {
    refetch();
    idleTimerRef.current.reset();
  };

  const { value, units } = formatValue();

  if (!machine) {
    return (
      <h3>{t('machineIsNotConfiguredOrDeleted')}</h3>
    );
  }

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <ResponsiveScorecard
        selectedObject={selectedObject}
        value={value}
        units={selectedObject.units || units}
        backgroundColor={backgroundColor}
        hasLoaded={!!statistics}
        isCircle={isCircle}
        {...dimension}
        loaderPos={loaderPos}
      />
    </>
  );
};

ScorecardMachine.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  fetchDelay: PropTypes.number,
  dimension: PropTypes.object.isRequired,
  isCircle: PropTypes.bool,
  loaderPos: PropTypes.object,
  machines: reducersTypes.machines,
  selectedObject: PropTypes.object,
};
ScorecardMachine.defaultProps = {
  isCircle: false,
  loaderPos: null,
  machines: [],
  selectedObject: {},
  fetchDelay: FETCH_STATS_DELAY_MS,
};

export default ScorecardMachine;

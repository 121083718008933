import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, NavLink } from '@mantine/core';
import './SideBar.scss';
import omit from 'lodash.omit';
import { Input } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { showSuccess } from '~utils/toast';
import { sortArray } from '~utils/sort';
import { configurationFeature } from '~utils/featureToggles';
import { RootState } from '~services/store';
import { reduxOperations } from '~services/index';
import { idsToTags } from '~utils/tags';
import DashboardPopUpForm from '../../../pages/Dashboards/DashboardPopUpForm';
import TopviewPopUpForm from '../../../pages/Topviews/TopviewSelectionPage/TopviewPopUpForm';
import { ReportPopupForm } from '../../../pages/Reports/ReportSelectionPage/ReportPopupForm';
import FolderPopupForm from '~components/Popups/FolderPopupForm/FolderPopupForm';
import FontAwesome from '~components/UI/FontAwesome';
import WorkspaceItem from './WorkspaceItem';
import WorkspaceFolder from './WorkspaceFolder';

const CHILDREN_OFFSET = 60;
const MENU_WIDTH = 360;

const SideBar = ({ isSideBarOpened }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const folders = useSelector((state: RootState) => state.folders);
  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const reports = useSelector((state: RootState) => state.reports.reports);
  const favorites = useSelector((state: RootState) => state.users.selectedUser.favorites);
  const searchInput = useSelector((state: RootState) => state.views.sideBarSearchInput);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const users = useSelector((state: RootState) => state.users.users);
  const userId = useSelector((state: RootState) => (state.session.connexion.infos.id));

  const [interfaceToCreate, setInterfaceToCreate] = useState<string | null>(null);

  const handleScroll = () => {
    document.getElementById('scroll')?.scrollTo(0, parseInt(localStorage.getItem('sideBarScrollPosition') || '0', 10));
  };

  const onHide = () => {
    setInterfaceToCreate('');
  };

  const isLeftClick = e => {
    if (!(e.ctrlKey || e.shiftKey || e.button === 1)) {
      e.preventDefault();
      return true;
    }
    return false;
  };

  const updateUsersPermissions = (id, type, usersArg) => {
    if (configurationFeature.isUserAllowedAccessUsers()) {
      const permissionsData = {
        users: usersArg.map(user => user.value),
        content: {
          type,
          id,
        },
      };
      reduxOperations.users.updateUsersContentsPermissions(permissionsData)(dispatch).then(() => {
        dispatch(reduxOperations.users.fetchAllUsersPermissions() as any);
      });
    }
  };

  const handleFavoriteChange = (favoriteType, favoriteId, favoriteToRemoveId = null) => {
    const newFavorites = { ...favorites };
    let confirmationMessage;

    if (favorites[favoriteType].includes(favoriteId)) {
      newFavorites[favoriteType] = favorites[favoriteType].filter(f => f !== favoriteId);
      confirmationMessage = t('favoriteRemovedSuccessfully');
    } else {
      newFavorites.dashboards = favorites.dashboards.filter(f => f !== favoriteToRemoveId);
      newFavorites.reports = favorites.reports.filter(f => f !== favoriteToRemoveId);
      newFavorites.topviews = favorites.topviews.filter(f => f !== favoriteToRemoveId);
      newFavorites.pages = favorites.pages.filter(f => f !== favoriteToRemoveId);

      newFavorites[favoriteType].push(favoriteId);
      confirmationMessage = favoriteToRemoveId
        ? t('favoriteReplacedSuccessfully')
        : t('favoriteAddedSuccessfully');
    }

    reduxOperations.users.updateUser({ favorites: newFavorites }, userId)(dispatch).then(() => {
      showSuccess(confirmationMessage);
    });
  };

  const handleDuplication = (item, type) => {
    let usersWithPermission;
    if (item.id) {
      usersWithPermission = users
        .reduce((result, user) => {
          const userHasItemsAccess = user.permissions.contents.some(
            content => content.type === type && content.id === item.id,
          );
          return userHasItemsAccess ? [...result, { value: user.id }] : result;
        }, []);
    }

    // taken from DashboardSelectionGrid.jsx, TopviewSelectionGrid.jsx and ReportSelectionGrid.jsx
    // change code here and in those files if change is needed
    let newItem;
    switch (type) {
      case 'Dashboard':
        newItem = Object.assign(
          omit(item, ['id', 'createdAt', 'modifiedAt']),
          { name: `${item.name} (${t('Copy')})` },
          { tags: idsToTags(item.tags || [], tags) },
        );
        if (item.tiles) {
          newItem.tiles = item.tiles.map(tile => (
            Object.assign(
              omit(tile, ['i', 'id', '_id']),
              { rules: (tile.rules || []).map(r => omit(r, ['id'])) },
            )
          ));
        }
        reduxOperations.dashboards.addDashboard(newItem)(dispatch).then(res => {
          showSuccess(t('showSuccessCreated'));
          updateUsersPermissions(res.payload.dashboard.id, type, usersWithPermission);
          history.push(`/dashboard/${res.payload.dashboard.id}`);
        });
        break;
      case 'Topview':
        newItem = {
          ...omit(item, ['id']),
          name: `${item.name} (${t('Copy')})`,
          widgets: item.widgets.map(widget => ({
            ...omit(widget, ['id']),
            rules: (widget.rules || []).map(r => omit(r, ['id'])),
          })),
          tags: (item.tags || [])
            .map(tagId => tags.find(tag => tagId === tag.id))
            .filter(tag => !!tag),
        };
        reduxOperations.topviews.createTopview(newItem)(dispatch).then(res => {
          showSuccess(t('showSuccessCreated'));
          updateUsersPermissions(res.payload.topview.id, type, usersWithPermission);
        });
        break;
      case 'Report':
        newItem = {
          ...omit(item, ['id', 'createdAt', 'modifiedAt']),
          name: (() => { // create item name while avoiding duplicate names
            let i = 1;
            while (true) {
              const tail = `(${i})`;
              if (!reports.find(rep => rep.name === `${item.name}_${t('Copy')} ${tail}`)) {
                break;
              }
              i += 1;
            }
            return `${item.name}_${t('Copy')} (${i})`;
          })(),
          url: item.url,
          tags: (item.tags || [])
            .map(tagId => tags.find(tag => tagId === tag.id))
            .filter(tag => !!tag),
        };
        reduxOperations.reports.addReport(newItem)(dispatch).then(res => {
          showSuccess(t('showSuccessCreated'));
          updateUsersPermissions(res.payload.report.id, type, usersWithPermission);
        });
        break;
      default:
        break;
    }
  };

  // TODO: SHOULD BE DONE IN THE BACKEND
  const handleDeleteFolder = folder => {
    reduxOperations.folders.deleteFolder(folder.id)(dispatch);

    dashboards?.filter(dash => dash.folderId === folder.id)
      .forEach(item => reduxOperations.dashboards.updateDashboard(item.id, { ...item, folderId: '' })(dispatch));

    topviews?.filter(tv => tv.folderId === folder.id)
      .forEach(item => reduxOperations.topviews.updateTopview(item.id, { ...item, folderId: '' })(dispatch));

    reports?.filter(rep => rep.folderId === folder.id)
      .forEach(item => reduxOperations.reports.updateReport(item.id, { ...omit(item, ['id', 'modifiedAt', 'modifiedBy']), folderId: '' })(dispatch));

    localStorage.removeItem(`.${folder.name}Folder`);
  };

  const getFavoritesItems = () => {
    const favoritesItems: JSX.Element[] = [];
    if (favorites) {
      for (const [key, ids] of Object.entries(favorites)) {
        let favoriteType;
        let icon;
        switch (key) {
          case 'dashboards':
            favoriteType = dashboards;
            icon = <FontAwesome icon="ii-dashboard" />;
            break;
          case 'topviews':
            favoriteType = topviews;
            icon = <FontAwesome icon="desktop" />;
            break;
          case 'reports':
            favoriteType = reports;
            icon = <FontAwesome icon="clipboard" />;
            break;
          default:
            break;
        }
        (ids as string[]).forEach(id => {
          const favoriteItem = favoriteType?.find(dash => dash.id === id);
          if (favoriteItem) {
            favoritesItems.push(
              <a href={`/${key.slice(0, -1)}/${id}`}>
                <NavLink
                  className="item"
                  label={favoriteItem.name}
                  icon={icon}
                  onClick={e => isLeftClick(e) && history.push(`/${key.slice(0, -1)}/${id}`)}
                  rightSection={<FontAwesome icon="star" style={{ color: 'gold' }} onClick={e => { e.stopPropagation(); handleFavoriteChange(key, id, null); }} />}
                />
              </a>,
            );
          }
        });
      }
    }
    return favoritesItems.sort((a, b) => (a.props.children.props.label.localeCompare(b.props.children.props.label)));
  };

  const getFolderItems = (folderId, folderName) => {
    const interfaceList: JSX.Element[] = [];
    dashboards?.filter(dash => ((!folderId && !dash.folderId) || dash.folderId === folderId)
      && (dash.name.toLowerCase().includes(searchInput.toLowerCase())
      || folderName.toLowerCase().includes(searchInput.toLowerCase())))
      .map(dash => interfaceList.push(
        <WorkspaceItem
          disabled={!configurationFeature.isUserAllowedAccessDashboards()}
          handleFavoriteChange={handleFavoriteChange}
          handleDuplication={() => handleDuplication(dash, 'Dashboard')}
          handleDelete={() => {
            if (history.location.pathname === `/dashboard/${dash.id}`) {
              history.push('/home');
            }
            reduxOperations.dashboards.deleteDashboard(dash.id)(dispatch);
          }}
          item={{
            ...dash,
            createdByUser: users?.find(user => user.id === dash.createdBy)?.name,
            modifiedByUser: users?.find(user => user.id === dash.modifiedBy)?.name,
          }}
          onClick={e => ((e.target as Element).className !== 'fa fa-ellipsis-h' ? history.push(`/dashboard/${dash.id}`) : null)}
          url={`/dashboard/${dash.id}`}
          icon={<FontAwesome icon="ii-dashboard" />}
          type="dashboards"
        />,
      ));
    topviews?.filter(tv => ((!folderId && !tv.folderId) || tv.folderId === folderId)
      && (tv.name.toLowerCase().includes(searchInput.toLowerCase())
      || folderName.toLowerCase().includes(searchInput.toLowerCase())))
      .map(tv => interfaceList.push(
        <WorkspaceItem
          disabled={!configurationFeature.isUserAllowedAccessTopviews()}
          handleFavoriteChange={handleFavoriteChange}
          handleDuplication={() => handleDuplication(tv, 'Topview')}
          handleDelete={() => {
            if (history.location.pathname === `/topview/${tv.id}`) {
              history.push('/home');
            }
            reduxOperations.topviews.deleteTopview(tv.id)(dispatch);
          }}
          item={{
            ...tv,
            createdByUser: users?.find(user => user.id === tv.createdBy)?.name,
            modifiedByUser: users?.find(user => user.id === tv.modifiedBy)?.name,
          }}
          onClick={e => ((e.target as Element).className !== 'fa fa-ellipsis-h' ? history.push(`/topview/${tv.id}`) : null)}
          url={`/topview/${tv.id}`}
          icon={<FontAwesome icon="desktop" />}
          type="topviews"
        />,
      ));
    reports?.filter(rep => ((!folderId && !rep.folderId) || rep.folderId === folderId)
      && (rep.name.toLowerCase().includes(searchInput.toLowerCase())
      || folderName.toLowerCase().includes(searchInput.toLowerCase())))
      .map(rep => interfaceList.push(
        <WorkspaceItem
          disabled={!configurationFeature.isUserAllowedAccessReports()}
          handleFavoriteChange={handleFavoriteChange}
          handleDuplication={() => handleDuplication(rep, 'Report')}
          handleDelete={() => {
            if (history.location.pathname === `/report/${rep.id}`) {
              history.push('/home');
            }
            reduxOperations.reports.deleteReport(rep.id)(dispatch);
          }}
          item={{
            ...rep,
            createdByUser: users?.find(user => user.id === rep.createdBy)?.name,
            modifiedByUser: users?.find(user => user.id === rep.modifiedBy)?.name,
          }}
          onClick={e => ((e.target as Element).className !== 'fa fa-ellipsis-h' ? history.push(`/report/${rep.id}`) : null)}
          url={`/report/${rep.id}`}
          icon={<FontAwesome icon="clipboard" />}
          type="reports"
        />,
      ));
    return interfaceList.sort((a, b) => (
      a.props.item.name.localeCompare(b.props.item.name)));
  };

  const topNav = (
    <>
      <a href="/home">
        <NavLink
          className="root"
          label={t('home')}
          icon={<FontAwesome icon="home" />}
          onClick={e => isLeftClick(e) && history.push('/home')}
        />
      </a>
      <NavLink
        className="root"
        label={`${t('favorite')}s`}
        icon={<FontAwesome icon="star" />}
        childrenOffset={CHILDREN_OFFSET}
        defaultOpened={localStorage.getItem('favoritesFolder') === 'opened'}
        onChange={e => localStorage.setItem('favoritesFolder', e ? 'opened' : 'closed')}
      >
        {getFavoritesItems()}
      </NavLink>
      <a href="/events">
        <NavLink
          className="root"
          label={t('events')}
          icon={<FontAwesome icon="list" />}
          onClick={e => isLeftClick(e) && history.push('/events')}
        />
      </a>
      <NavLink
        className="root"
        label={t('configuration')}
        icon={<FontAwesome icon="gear" />}
        childrenOffset={CHILDREN_OFFSET}
        defaultOpened={configurationFeature.isUserAllowedAccess() && localStorage.getItem('configFolder') === 'opened'}
        onChange={e => localStorage.setItem('configFolder', e ? 'opened' : 'closed')}
        disabled={!configurationFeature.isUserAllowedAccess()}
      >
        {configurationFeature.isUserAllowedAccessStreams() && (
          <a href="/config/streams">
            <NavLink className="item" label={t('streams')} icon={<FontAwesome icon="microchip" />} onClick={e => isLeftClick(e) && history.push('/config/streams')} />
          </a>
        )}
        {configurationFeature.isUserAllowedAccessMachines() && (
          <a href="/config/machines">
            <NavLink className="item" label={t('machines')} icon={<FontAwesome icon="cogs" />} onClick={e => isLeftClick(e) && history.push('/config/machines')} />
          </a>
        )}
        {configurationFeature.isUserAllowedAccessOperations() && (
          <a href="/config/operations">
            <NavLink className="item" label={t('operations')} icon={<FontAwesome icon="gear" />} onClick={e => isLeftClick(e) && history.push('/config/operations')} />
          </a>
        )}
        {configurationFeature.isUserAllowedAccessVariables() && (
          <a href="/config/variables">
            <NavLink className="item" label={t('variables')} icon={<FontAwesome icon="ii-variable" />} onClick={e => isLeftClick(e) && history.push('/config/variables')} />
          </a>
        )}
        {configurationFeature.isUserAllowedAccessRuleEngine() && (
          <a href="/config/ruleEngine">
            <NavLink className="item" label={t('ruleEngine')} icon={<FontAwesome icon="play" />} onClick={e => isLeftClick(e) && history.push('/config/ruleEngine')} />
          </a>
        )}
        {configurationFeature.isUserAllowedAccessStopwatches() && (
          <a href="/config/stopwatches">
            <NavLink className="item" label={t('stopwatches')} icon={<FontAwesome icon="ii-stopwatch" />} onClick={e => isLeftClick(e) && history.push('/config/stopwatches')} />
          </a>
        )}
        {configurationFeature.isUserAllowedAccessShifts() && (
          <a href="/config/shifts">
            <NavLink className="item" label={t('shifts')} icon={<FontAwesome icon="clock-o" />} onClick={e => isLeftClick(e) && history.push('/config/shifts')} />
          </a>
        )}
        {configurationFeature.isUserAllowedAccessUsers() && (
          <a href="/config/users">
            <NavLink className="item" label={t('users')} icon={<FontAwesome icon="user" />} onClick={e => isLeftClick(e) && history.push('/config/users')} />
          </a>
        )}
        {configurationFeature.isUserAllowedAccessDashboards() && (
          <a href="/config/images">
            <NavLink className="item" label={t('Images')} icon={<FontAwesome icon="image" />} onClick={e => isLeftClick(e) && history.push('/config/images')} />
          </a>
        )}
        {configurationFeature.isUserAllowedAccessLogs() && (
          <a href="/config/logs">
            <NavLink className="item" label={t('logs')} icon={<FontAwesome icon="file-text-o" />} onClick={e => isLeftClick(e) && history.push('/config/logs')} />
          </a>
        )}
        {configurationFeature.isUserAllowedAccessAdmin() && (
          <a href="/config/admin">
            <NavLink className="item" label={t('admin')} icon={<FontAwesome icon="user-secret" />} onClick={e => isLeftClick(e) && history.push('/config/admin')} />
          </a>
        )}
      </NavLink>
    </>
  );

  const workspaceNav = (
    <>
      {/* <NavLink
        className="header"
        label="Main Workspace"
        icon={<FontAwesome icon="check" />}
      /> */}
      <div className="workspace-top">
        <Input
          clearable
          style={{ padding: '5px', width: '83%', height: '45px' }}
          onChange={value => dispatch(reduxOperations.views.setSideBarSearchInput(value))}
          onClear={() => dispatch(reduxOperations.views.setSideBarSearchInput(''))}
          value={searchInput}
          placeholder={t('Search')}
        />
        <Menu offset={10} width={MENU_WIDTH} position="right-start" transitionProps={{ transition: 'fade', duration: 200 }}>
          <Menu.Target>
            <button type="button" className="addCardButton">
              <FontAwesome icon="plus" />
            </button>
          </Menu.Target>
          <Menu.Dropdown className="plusMenu">
            <Menu.Item
              icon={<FontAwesome icon="ii-dashboard" />}
              onClick={() => setInterfaceToCreate('dashboards')}
              disabled={!configurationFeature.isUserAllowedAccessDashboards()}
              style={!configurationFeature.isUserAllowedAccessDashboards() ? { opacity: '0.6' } : {}}
            >
              {t('newDashboard')}
            </Menu.Item>
            <Menu.Item
              icon={<FontAwesome icon="desktop" />}
              onClick={() => setInterfaceToCreate('topviews')}
              disabled={!configurationFeature.isUserAllowedAccessTopviews()}
              style={!configurationFeature.isUserAllowedAccessTopviews() ? { opacity: '0.6' } : {}}
            >
              {t('newTopView')}
            </Menu.Item>
            <Menu.Item
              icon={<FontAwesome icon="clipboard" />}
              onClick={() => setInterfaceToCreate('reports')}
              disabled={!configurationFeature.isUserAllowedAccessReports()}
              style={!configurationFeature.isUserAllowedAccessReports() ? { opacity: '0.6' } : {}}
            >
              {t('newReport')}
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              icon={<FontAwesome icon="folder" />}
              onClick={() => setInterfaceToCreate('folders')}
              disabled={!configurationFeature.isUserAllowedAccessFolders()}
              style={!configurationFeature.isUserAllowedAccessFolders() ? { opacity: '0.6' } : {}}
            >
              {t('newFolder')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </>
  );

  const workspaceItems = (
    <>
      {sortArray('alphabetically', folders, 'name')?.map(folder => {
        const folderItems = getFolderItems(folder.id, folder.name) || [];
        const subfolderMatch = folder.subfolders?.some(
          subfolder => subfolder.name.toLowerCase().includes(searchInput.toLowerCase()),
        );
        if (!(folder.name.toLowerCase().includes(searchInput.toLowerCase()) || folderItems.length || subfolderMatch)) {
          return null;
        }
        return (
          <WorkspaceFolder
            folder={folder}
            folderItems={folderItems}
            getFolderItems={getFolderItems}
            searchInput={searchInput}
            disabled={!configurationFeature.isUserAllowedAccessFolders()}
            handleDelete={() => handleDeleteFolder(folder)}
            icon={<FontAwesome icon="folder" style={{ color: `${folder.color || 'white'}` }} />}
          />
        );
      })}
      <WorkspaceFolder
        folderItems={getFolderItems(null, '')}
      />
    </>
  );

  useLayoutEffect(() => {
    handleScroll();
  }, []);

  useEffect(() => {
    dispatch(reduxOperations.users.fetchAllUsersPermissions() as any);
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="sidebar"
        id="scroll"
        onScroll={e => localStorage.setItem('sideBarScrollPosition', e.currentTarget.scrollTop.toString())}
        style={isSideBarOpened ? { left: '0' } : {}}
      >
        <div className="topNav">
          {topNav}
        </div>
        <div className="workspace">
          {workspaceNav}
          {workspaceItems}
        </div>
        <DashboardPopUpForm show={interfaceToCreate === 'dashboards'} onHide={onHide} />
        <TopviewPopUpForm show={interfaceToCreate === 'topviews'} onHide={onHide} />
        <ReportPopupForm show={interfaceToCreate === 'reports'} onHide={onHide} />
        <FolderPopupForm show={interfaceToCreate === 'folders'} onHide={onHide} />
      </div>
    </DndProvider>
  );
};

SideBar.propTypes = {
  isSideBarOpened: PropTypes.bool.isRequired,
};

export { SideBar };

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import API from '~services/endpoints';
import EventFilterForm from './EventFilterForm';
import { Popover } from '~components/Popover';
import { getSocket } from '~services/socket';
import { MAX_NB_EVENTS_ONE_PAGE, MAX_NB_PAGES } from '~utils/constants';
import { eventsFeature } from '~utils/featureToggles';
import { PartEventPopup } from '~components/Popups';
import { EventsList } from './EventsList';
import { showSuccess } from '~utils/toast';

const PartEventsList = ({ setParentFilterData, filters }) => {
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const settings = useSelector(state => state.settings.settings);

  const [isSocketInitialized, setSocketInitialized] = useState(false);
  const [isUpdatingEvent, setIsUpdatingEvent] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [filterData, setFilterData] = useState(filters);
  const [socketsOn, setSocketsOn] = useState(false);

  const socket = getSocket();

  const fetchEvents = async filter => {
    const filterApi = {
      type: 'PartEvent',
      machineId: filter.machine,
      eventType: filter.eventType || undefined,
      'infosObject.workOrder': filter.workOrder || undefined,
      'infosObject.skuNumber': filter.sku || undefined,
      'infosObject.operation': filter.operation || undefined,
      timestamp: {
        $gt: filter.timestampStart,
        $lt: filter.timestampEnd,
      },
    };
    const { events } = await API.getEvents(filterApi);
    setEventsList(events);
  };

  const fetchAllEvents = async () => {
    const sort = { timestamp: -1 };
    const limit = MAX_NB_EVENTS_ONE_PAGE * MAX_NB_PAGES;
    const { events } = await API.getEvents({ type: 'PartEvent' }, sort, limit);
    setEventsList(events);
  };

  const filterColumns = () => {
    const existingKeys = new Set([
      ...machines.map(machine => Object.keys(machine.params || {})
        .filter(key => key === 'operator' || settings.eventPageFilters[key])).flat(),
    ]);
    const columns = ['machineName', 'name', 'startDate', 'eventType', 'count', ...existingKeys, 'motif'];
    const colNames = ['machineName', 'name', 'date', 'type', 'count', ...existingKeys, 'rejectCause'];
    return { columns, colNames };
  };

  const handleSocketEvent = newEvent => {
    if (newEvent.type === 'PartEvent') {
      const updatedEventsList = [...eventsList];
      const eventAlreadyExists = updatedEventsList.some(elem => newEvent.id === elem.id);
      if (!eventAlreadyExists) {
        eventsList.unshift(newEvent);
      } else {
        const index = eventsList.findIndex(event => event.id === newEvent.id);
        eventsList[index] = newEvent;
      }
      setEventsList(updatedEventsList);
      fetchAllEvents();
    }
  };

  const handleFilter = data => {
    setShowFilter(false);
    setFilterData(data);
    setParentFilterData(data);
  };

  useEffect(() => {
    if (filterData.eventType !== undefined) {
      setSocketsOn(false);
      fetchEvents(filterData);
    }
  }, [filterData]);

  useEffect(() => {
    if (socket && !isSocketInitialized && eventsList.length && !filterData && !isUpdatingEvent) {
      socket.on('event', handleSocketEvent);
      setSocketInitialized(true);
    }

    return () => {
      if (socket) {
        socket.removeListener('event', handleSocketEvent);
      }
    };
  }, []);

  useEffect(() => {
    setSocketsOn(true);
    fetchAllEvents();
  }, []);

  const handleModifyEvent = () => {
    setIsUpdatingEvent(true);
    fetchEvents(filterData);
    showSuccess(t('eventUpdated'));
  };

  const { columns, colNames } = filterColumns();

  return (
    <div className="list">
      <EventsList
        columnNames={colNames}
        entriesProperties={columns}
        filteredList={eventsList}
        socketsOn={socketsOn}
        editForm={eventsFeature.isUserAllowedToConfigure() ? PartEventPopup : null}
        editFormProps={{ eventsList }}
        storageName="K2_part_events"
        style={{ minHeight: '600px' }}
        onModifyEvent={handleModifyEvent}
        showFields={showFields}
        setShowFields={setShowFields}
        setShowFilter={setShowFilter}
        leftHeader={(
          <div className="filterContainer">
            <Popover
              show={showFilter}
              setShow={setShowFilter}
              className="filter-body"
              content={<EventFilterForm type="part" filter={filterData} onSubmit={handleFilter} />}
            >
              <button
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  setShowFilter(!showFilter);
                  setShowFields(false);
                }}
                className="filterButton"
              >
                {t('filter')}
              </button>
            </Popover>
          </div>
        )}
      />
    </div>
  );
};

PartEventsList.propTypes = {
  setParentFilterData: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

PartEventsList.defaultProps = {
  filters: {},
};

export default PartEventsList;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { reduxOperations, reducersTypes } from '~services';
import { DefaultTable } from '~components/Pages';
import { DeleteConfirmationForm, LinkRedirectionPopup } from '~components/Popups';
import {
  Cards, CreateCard, FontAwesome, ImageCard,
  ModalHandler, SquaredAddButton,
} from '~UI';
import TagsSelection from '~UI/Tags/TagsSelection';
import { MAX_WIDTH_VERTICAL_TABLET } from '~utils/constants';
import { getLocalStorageObject, getLocalStorageSetter } from '~utils/localStorage';
import { selectedTagsToTitle, handleTagSelection, getTagListFromUsedTags } from '~utils/tags';
import { filterItems, sortItems } from './utils';
import './SelectionGrid.scss';

const contentOptions = {
  DASHBOARD: 'Dashboard',
  REPORT: 'Report',
  TOPVIEW: 'Topview',
};
const displayOptions = {
  CARD: 'card',
  LIST: 'list',
};

function SelectionGrid({
  contentType, deleteFunction, directLinkType, EditForm, handleDuplication, isUserAllowedToConfig,
  items, folders, redirectRessource, storageType, supportFavorites, tagInfoText, titleCreateCard,
}) {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const tags = useSelector(state => state.tags.tags);
  const storage = getLocalStorageObject(storageType);
  const updateLocalStorage = getLocalStorageSetter(storageType);

  const [display, setDisplay] = useState(storage.display || displayOptions.CARD);
  const [filterText, setFilterText] = useState('');
  const [isTabletVertical, setIsTabletVertical] = useState(false);
  const [sortInput, setSortInput] = useState(storage.sortParameter || '-');
  const [selectedTags, setSelectedTags] = useState(storage.tags || ['all']);

  function handleResize() {
    setIsTabletVertical(window.innerWidth < MAX_WIDTH_VERTICAL_TABLET);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    if (isUserAllowedToConfig) {
      dispatch(reduxOperations.users.fetchAllUsersPermissions());
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Tooltip.rebuild();
  });

  const onSortChange = e => {
    updateLocalStorage('sortParameter', e);
    setSortInput(e);
  };

  function handleDisplay(newValue) {
    if (newValue === displayOptions.LIST && sortInput === 'byTag') {
      setSortInput('-');
    }
    updateLocalStorage('display', newValue);
    setDisplay(newValue);
  }

  const selectTag = (selectedTag, withCtrl) => {
    setSelectedTags(prevState => {
      let newTags = handleTagSelection(selectedTag, prevState, withCtrl);
      const usedList = getTagListFromUsedTags(items, newTags);
      newTags = newTags.filter(tag => usedList.find(usedTag => usedTag === tag) || tag === 'all');
      updateLocalStorage('tags', newTags);
      return newTags;
    });
  };

  function getCards(itemsClone, usedSelectedTags) {
    let usedTags = getTagListFromUsedTags(items, tags);
    usedTags = usedTags.length ? usedTags : [{ name: 'all' }];
    // If "Sort by Tag" is not selected, just map an empty array
    let shownTags = [''];
    // Else show only one tag when only one is selected (except if it's "all", show everything)
    // If multiple tags are selected, show only one tag section with a parsed title and prefilled tags
    if (sortInput === 'byTag') {
      shownTags = usedSelectedTags.length === 1
        ? usedTags.filter(tag => usedSelectedTags.includes('all') || usedSelectedTags.includes(tag.id))
        : [selectedTagsToTitle(usedTags, usedSelectedTags)];
    }

    return shownTags.map(tag => (
      <div key={tag}>
        <div className="tagSectionTitle">{tag.name}</div>
        <Cards>
          {isUserAllowedToConfig && (
            <CreateCard
              title={titleCreateCard}
              modal={{
                Component: EditForm,
                props: {
                  folders,
                  prefillTag: sortInput === 'byTag' ? (tag.prefillTags || [tag]) : undefined,
                },
              }}
            />
          )}
          {/* We have to add an extra filter when "All" is selected if "Sort by Tags" is selected. */}
          {itemsClone
            .filter(item => sortInput !== 'byTag' || !usedSelectedTags.includes('all') || item.tags.find(tr => tr === tag.id))
            .map(item => (
              <ImageCard
                key={item.id}
                card={item}
                usedTags={item.tags}
                duplicateAction={handleDuplication}
                editModal={isUserAllowedToConfig && (contentType !== contentOptions.REPORT || item.deletable) ? {
                  Component: EditForm,
                  props: {
                    folders,
                    topviewId: item.id,
                    modifiedItemId: item.id,
                  },
                } : null}
                deleteModal={isUserAllowedToConfig && (contentType !== contentOptions.REPORT || item.deletable) ? {
                  Component: DeleteConfirmationForm,
                  props: { handleDelete: () => deleteFunction(item.id) },
                } : null}
                redirectRessource={redirectRessource}
                supportFavorites={supportFavorites}
                optionalAction={contentType !== contentOptions.REPORT && {
                  optText: t('share'),
                  optIcon: 'share',
                  optModal: {
                    Component: LinkRedirectionPopup,
                    props: {
                      contentType,
                      contentId: item.id,
                    },
                  },
                }}
                configureLink={contentType !== contentOptions.REPORT && `/config/${contentType.toLowerCase()}/${item.id}`}
              />
            ))}
        </Cards>
      </div>
    ));
  }

  function addTagColorToName(itemsCopy, usedTags) {
    return itemsCopy.map(item => ({
      ...item,
      name: (
        <div className="listName">
          <span>{item.name}</span>
          {
            usedTags
              .filter(tag => item.tags.includes(tag.id))
              .map(tag => (
                <div
                  data-tip={tag.name}
                  key={tag.id}
                  className="listTag"
                  style={{ background: tag.color }}
                />
              ))
          }
        </div>
      ),
    }));
  }

  const sortedItems = sortItems(items, sortInput);
  let usedSelectedTags = getTagListFromUsedTags(sortedItems, selectedTags);
  usedSelectedTags = usedSelectedTags.length ? usedSelectedTags : ['all'];
  const usedTags = getTagListFromUsedTags(sortedItems, tags);

  const filteredItems = filterItems(filterText, usedSelectedTags, sortedItems);
  const listView = addTagColorToName(filteredItems, usedTags);

  return (
    <div className="ItemsSelectionGrid">
      {usedTags.length > 0 && !(usedTags.length === 1 && tags.includes('all')) && (
        <TagsSelection
          tags={usedTags}
          selectedTags={usedSelectedTags}
          selectTag={selectTag}
          tagInfo={tagInfoText}
        />
      )}
      <div className="SearchBar">
        <div>
          {`${t('Search')}:`}
          <Input
            clearable
            onChange={value => setFilterText(value)}
            onClear={() => setFilterText('')}
            placeholder={t('searchByName')}
            style={{
              display: 'inline-block',
              margin: '0 5px',
              width: 250,
            }}
            value={filterText}
          />
          {isTabletVertical && <br />}

          {`${t('sortBy')}:`}
          <Select
            className="sortInput"
            value={sortInput}
            onChange={e => onSortChange(e)}
            options={
              contentType !== contentOptions.REPORT && display === displayOptions.CARD
                ? [
                  { label: '-', value: '-' },
                  { label: t('name'), value: 'byName' },
                  { label: t('tags'), value: 'byTags' },
                  { label: t('description'), value: 'byDescription' },
                  { label: t('creationDateNewest'), value: 'byCreationDateAscending' },
                  { label: t('creationDateOldest'), value: 'byCreationDateDescending' },
                  { label: t('modificationDateNewest'), value: 'byModificationDateAscending' },
                  { label: t('modificationDateOldest'), value: 'byModificationDateDescending' },
                ]
                : [
                  { label: '-', value: '-' },
                  { label: t('name'), value: 'byName' },
                ]
            }
            style={{ display: 'inline-block', width: 300 }}
          />
        </div>

        <div className="displayIconContainer" style={{ marginTop: isTabletVertical && 'auto' }}>
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.CARD)}
          >
            <FontAwesome icon="th" />
          </div>
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.LIST)}
          >
            <FontAwesome icon="list-ul" />
          </div>
          {
            isUserAllowedToConfig && display === displayOptions.LIST && (
              <ModalHandler
                Trigger={{
                  Component: SquaredAddButton,
                  props: {},
                }}
                Modal={{
                  Component: EditForm,
                  props: {},
                }}
              />
            )
          }
        </div>
      </div>
      { /* <Tooltip className="icon-tooltip" /> */}

      {
        display === displayOptions.CARD ? (
          <div>
            {getCards(filteredItems, usedSelectedTags)}
          </div>
        ) : (
          <DefaultTable
            columnNames={[
              { name: t('name') },
              { name: t('description') },
              { name: t('createdBy') },
              { name: t('createdAt') },
              { name: t('modifiedBy') },
              { name: t('modifiedAt') },
            ]}
            entriesProperties={['name', 'description', 'createdByUser', 'createdAt', 'modifiedByUser', 'modifiedAt']}
            entries={listView}
            deleteFunction={deleteFunction}
            editForm={contentType !== contentOptions.REPORT && EditForm}
            onClickEvent={id => history.push(directLinkType(id, location.pathname))}
          />
        )
      }
    </div>
  );
}

SelectionGrid.propTypes = {
  contentType: PropTypes.string.isRequired,
  deleteFunction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  directLinkType: PropTypes.func.isRequired,
  EditForm: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.bool,
  ]),
  handleDuplication: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  isUserAllowedToConfig: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  folders: reducersTypes.folders.isRequired,
  redirectRessource: PropTypes.string.isRequired,
  storageType: PropTypes.string.isRequired,
  supportFavorites: PropTypes.bool,
  tagInfoText: PropTypes.string,
  titleCreateCard: PropTypes.string,
};

SelectionGrid.defaultProps = {
  deleteFunction: null,
  EditForm: null,
  handleDuplication: null,
  isUserAllowedToConfig: false,
  supportFavorites: false,
  tagInfoText: '',
  titleCreateCard: '',
};

export { SelectionGrid };

import React from 'react';
import PropTypes from 'prop-types';
import { Gauge } from '~UI/svg';
import { PERCENTAGE_HEIGHT_OUTER_RADIUS } from '~UI/svg/Gauge';
import { getApproximateFontSize } from '~utils/responsiveValue';
import { invertColor } from '~utils/index';
import './WidgetContents.scss';

const TITLE_HEIGHT = 0.15;
const TITLE_POSITION = 0.12;
const TITLE_FONT_SIZE_HEIGHT = 0.22;

const GaugeWidget = ({
  backgroundColor, h, w, x, y, widget, fetchDelay,
}) => {
  const hitBoxHeight = Math.min(h, w / 2) * PERCENTAGE_HEIGHT_OUTER_RADIUS;
  const hitBoxWidth = hitBoxHeight * 2;
  const hitBoxPosX = x + ((widget.w - hitBoxWidth) / 2);
  const hitBoxPosY = y + (Math.min(h, w / 2) * (1 - PERCENTAGE_HEIGHT_OUTER_RADIUS));

  const height = widget.title && widget.title.length > 0 ? h - h * TITLE_HEIGHT : h;
  const posY = widget.title && widget.title.length > 0 ? y + h * TITLE_HEIGHT : y;
  const fontSize = widget.titleSize || getApproximateFontSize(widget.title, w, h * TITLE_FONT_SIZE_HEIGHT);

  return (
    <>
      <rect x={x} y={y} width={w} height={h} fill={backgroundColor} stroke="none" className="noClick" />
      <text
        x={x + w / 2}
        y={y + h * TITLE_POSITION}
        fill={invertColor(backgroundColor)}
        style={{ fontSize: `${fontSize}px`, fontWeight: 'bold' }}
      >
        {widget.title}
      </text>
      <Gauge
        dimension={{ height, width: w, x, y: posY }}
        textColor={invertColor(backgroundColor)}
        selectedObject={widget}
        fetchDelay={fetchDelay}
      />
      <rect x={hitBoxPosX} y={hitBoxPosY} width={hitBoxWidth} height={hitBoxHeight} fillOpacity="0" stroke="none" />
    </>
  );
};

GaugeWidget.propTypes = {
  backgroundColor: PropTypes.string,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  fetchDelay: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    goal: PropTypes.number,
    machineId: PropTypes.string,
    showGoalToggle: PropTypes.bool,
    title: PropTypes.string,
    titleSize: PropTypes.number,
    valueType: PropTypes.string,
    w: PropTypes.number,
    zoom: PropTypes.string,
  }).isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};
GaugeWidget.defaultProps = {
  backgroundColor: '',
};

export default GaugeWidget;

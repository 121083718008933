import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from '@intelligenceindustrielle/react-ui-components';
import { reduxOperations } from '~services';
import { DeleteIcon } from '~components/UI/IconButtons';
import { showSuccess, showError } from '~utils/toast';
import './AssistantsSettings.scss';
import {
  SubmitButton,
} from '~UI';

const AssistantsSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allAssistants = useSelector(state => state.settings.settings?.aiAssistants);

  const [aiAssistants, setAiAssistants] = useState(allAssistants);

  useEffect(() => {
    dispatch(reduxOperations.settings.fetchSettings());
  }, []);

  const handleChangeAssistantName = (event, assistantIndex) => {
    const newName = event;

    const newAssistants = aiAssistants.map((assistant, index) => (
      index === assistantIndex ? { ...assistant, name: newName } : assistant
    ));

    setAiAssistants(newAssistants);
  };

  const handleCreateAssistantId = (event, assistantIndex) => {
    const newId = event;

    const newAssistants = aiAssistants.map((assistant, index) => (
      index === assistantIndex ? { ...assistant, id: newId } : assistant
    ));
    setAiAssistants(newAssistants);
  };

  const onRemoveItem = assistantId => {
    const newAssistants = aiAssistants.filter(assistant => assistant.id !== assistantId);
    setAiAssistants(newAssistants);
  };

  const handleAddAssistant = () => {
    const newAssistant = {
      id: '',
      name: '',
    };
    setAiAssistants([...aiAssistants, newAssistant]);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const updatedAssistants = aiAssistants.map(assistant => ({
      ...assistant,
      type: assistant.id.startsWith('asst_') ? 'gpt' : 'make',
    }));
    dispatch(reduxOperations.settings.updateSettings({ aiAssistants: updatedAssistants })).then(() => {
      showSuccess(t('success'));
    }).catch(error => showError(error.status));
  };

  return (
    <div className="assistantsSettingsModificationForm">
      <div className="formsContainer">
        {aiAssistants.map((assistant, index) => (
          <div key={index} className="assistantItem">
            <div className="rowFields">
              <div className="rowInputName">
                <span className="label">
                  {t('name')}
                  :
                </span>
                {' '}
                <Input
                  className="fullwidth"
                  value={assistant.name}
                  onChange={event => handleChangeAssistantName(event, index)}
                  name="assistantName"
                  placeholder={t('valueInput')}
                  style={{ marginRight: '10px', flexGrow: 1 }}
                />
              </div>
              <div className="rowInputId">
                <span className="label">ID:</span>
                {' '}
                <Input
                  className="largewidth"
                  value={assistant.id}
                  onChange={event => handleCreateAssistantId(event, index)}
                  name="assistantId"
                  placeholder={t('valueInput')}
                  style={{ marginRight: '10px', flexGrow: 1 }}
                  disabled={allAssistants.some(a => a.id === assistant.id)}
                />
              </div>
            </div>
            <div className="deleteIcon">
              <DeleteIcon onClick={() => onRemoveItem(assistant.id)} />
            </div>
          </div>
        ))}
        <div className="buttonFooter">
          <button
            type="button"
            className="addAssistantButton"
            onClick={handleAddAssistant}
          >
            {t('addAssistant')}
          </button>
          <SubmitButton
            label={t('modify')}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default AssistantsSettings;

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { roundStr } from '~utils/math';
import Tick from '~utils/Tick';
import { serverTime, getDataRangeStartEnd } from '~utils/time';
import ResponsiveScorecard from './ResponsiveScorecard';
import { getAggregatedValue, getComparisonMetricObject } from './utils';
import { useShift } from '~utils/hooks';

const ScorecardVariable = ({
  selectedObject, backgroundColor, isCircle, dimension, loaderPos,
}) => {
  const selectedObjectRef = useRef(selectedObject);
  const socket = getSocket();

  useEffect(() => {
    selectedObjectRef.current = selectedObject;
  });

  const variables = useSelector(state => state.variables);
  const streams = useSelector(state => state.streams);
  const machines = useSelector(state => state.machines);
  const [currentShift] = useShift(null);
  const { t } = useTranslation();

  const getValue = variableId => {
    const properties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const all = [...properties, ...variables, ...kpis];
    return all.find(elem => elem.id === variableId);
  };

  const variable = getValue(selectedObject.valueId);

  const [hasUnmounted, setHasUnmounted] = useState(false);

  const [hasLoaded, setHasLoaded] = useState(false);
  const [displayText, setDisplayText] = useState('');
  const [hasAggregate, setHasAggregate] = useState(variable
    ? variable.isEnabled
    && selectedObject.isAggregated : false);
  const [values, setValues] = useState([]);
  const [comparisonValues, setComparisonValues] = useState([]);
  const [currentDisplayedValue, setCurrentDisplayedValue] = useState(null);
  const [comparisonMetricObject, setComparisonMetricObject] = useState(null);

  // We adjust at the last moment our "first value" when the Aggregate Type is "Average"
  const getAdjustedValues = valuesArg => {
    const { start } = getDataRangeStartEnd(selectedObject.intervalType, currentShift);
    return valuesArg.map(val => {
      if (val < start) {
        return { ...val, timestamp: start };
      }
      return val;
    });
  };

  // Because of the socket props changing, the component renders very often
  // We compute our results for the render only when a significative value changes
  const updateTheRender = () => {
    const adjustedValues = getAdjustedValues(values);
    const newCurrentDisplayedValue = hasAggregate
      ? getAggregatedValue(adjustedValues, selectedObject.aggregateType, selectedObject.decimals)
      : displayText;
    const adjustedComparisonValues = getAdjustedValues(comparisonValues);
    const newComparisonMetricObject = selectedObject.comparisonMetric && selectedObject.comparisonMetric !== 'none'
      ? getComparisonMetricObject(
        newCurrentDisplayedValue,
        adjustedComparisonValues,
        selectedObject.comparisonAggregateType,
        selectedObject.comparisonMetric,
      )
      : null;

    setComparisonMetricObject(newComparisonMetricObject);
    setCurrentDisplayedValue(newCurrentDisplayedValue);
  };

  const filterValues = () => {
    let outOfBoundValues = [...values];

    const intervalType = selectedObject.intervalType || 'shift';
    const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
    const timeDifference = selectedObject.isAggregated ? end - start : 0;
    if (selectedObject.isAggregated) {
      // get all the values out of the date range
      outOfBoundValues = values.filter(value => value.timestamp < start || value.timestamp > end);
    }

    const comparisonIntervalType = selectedObject.comparisonIntervalType || 'shift';
    const newComparisonValues = selectedObject.comparisonMetric !== 'none' ? [...comparisonValues, ...outOfBoundValues] : [];
    const { start: comparisonStart, end: comparisonEnd } = getDataRangeStartEnd(comparisonIntervalType, currentShift);
    const comparisonTimeDifference = comparisonEnd - comparisonStart;

    setValues(prevValues => prevValues.filter((value, index) => {
      // if there is no time period, dont filter
      if (!selectedObject.isAggregated) { return true; }
      if (index + 1 === prevValues.length) { return true; }

      // We need a value between "start" and the first actual value in the date range.
      // Per example if the search is from 'start = 200' to 'end = 300' and you have only one value
      // in that interval at 250, it is valuable to keep a value out of the range at 150 per example
      // to interpolate data between from 200 to 250.
      // An exception is made when the value is too far off the range (double the range)
      // In the previous example, we would not keep values under a ts of 100 s
      // we only keep one value out of bound for the average
      if (selectedObject.aggregateType === 'avg'
          && prevValues[index + 1].timestamp >= start
          && value.timestamp <= end
          && value.timestamp >= start - timeDifference) {
        return true;
      }
      return value.timestamp >= start && value.timestamp <= end;
    }));

    setComparisonValues(newComparisonValues.filter((value, index) => {
      if (selectedObject.comparisonMetric === 'none') { return true; }
      if (index + 1 === newComparisonValues.length) { return true; }
      if (newComparisonValues[index + 1].timestamp >= comparisonStart - timeDifference
          && value.timestamp <= comparisonEnd - timeDifference
          && value.timestamp >= comparisonStart - timeDifference - comparisonTimeDifference) {
        return true;
      }
      return false;
    }));
  };

  const handleSocketValue = socketValue => {
    if (socketValue.id === selectedObjectRef.current.valueId) {
      if (selectedObjectRef.current.isAggregated) {
        values.push({ ...socketValue, timestamp: serverTime() });
        setValues(values);
        filterValues();
      } else {
        if (typeof socketValue.value === 'string' || typeof socketValue.value === 'boolean') {
          setDisplayText(socketValue.value);
        } else {
          setDisplayText(roundStr(socketValue.value, selectedObjectRef.current.decimals));
        }

        if (selectedObjectRef.current.comparisonMetric !== 'none') {
          comparisonValues.push({ ...socketValue, timestamp: serverTime() });
          setComparisonValues(comparisonValues);
          filterValues();
        }
      }
    }
  };

  const fetchValues = async (start, end, setState) => {
    const { values: fetchedValues } = await API
      .getValues(selectedObject.valueId,
        { timestamp: { $gte: start, $lt: end } });
    const firstValue = await API.getValues(selectedObject.valueId, { timestamp: { $lt: start } }, 1);
    firstValue.value = firstValue.values.length ? firstValue.values[0].value : undefined;

    if (setState) {
      if (fetchedValues) {
        const newValues = firstValue && selectedObject.comparisonAggregateType === 'avg' ? [firstValue, ...fetchedValues] : fetchedValues;
        setValues(newValues.sort((v1, v2) => v1.timestamp - v2.timestamp));
      } else {
        setValues([]);
      }
      setHasLoaded(true);
      filterValues();
      return;
    }
    return { values: fetchedValues, firstValue };
  };

  const fetchComparatorValues = async () => {
    if (selectedObject.comparisonMetric !== 'none') {
      const intervalType = selectedObject.intervalType || 'shift';
      const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
      const timeDifference = selectedObject.isAggregated ? end - start : 0;

      const comparisonIntervalType = selectedObject.comparisonIntervalType || 'shift';
      const { start: comparisonStart, end: comparisonEnd } = getDataRangeStartEnd(comparisonIntervalType, currentShift);
      const { firstValue, values: fetchedValues } = await fetchValues(
        comparisonStart - timeDifference,
        comparisonEnd - timeDifference,
        false,
      );

      if (fetchedValues) {
        const newValues = firstValue && selectedObject.comparisonAggregateType === 'avg' ? [firstValue, ...fetchedValues] : fetchedValues;
        setComparisonValues(newValues.sort((v1, v2) => v1.timestamp - v2.timestamp));
        filterValues();
      } else {
        setComparisonValues([]);
        filterValues();
      }
    }
  };

  useEffect(() => {
    if (!getValue(selectedObject.valueId)) {
      setHasLoaded(true);
      return;
    }

    if (selectedObject.isAggregated) {
      const intervalTypeDefault = selectedObject.intervalType || 'shift';
      const { start, end } = getDataRangeStartEnd(intervalTypeDefault, currentShift);
      fetchValues(start, end, true);
    } else {
      API.getValues(selectedObject.valueId, {}, 1).then(response => {
        if (!hasUnmounted) {
          if (typeof response?.values[0]?.value === 'string' || typeof response?.values[0]?.value === 'boolean') {
            setDisplayText(response.values[0].value);
          } else {
            const roundedValue = (response?.values[0]?.value !== null && response?.values[0]?.value !== undefined)
              ? roundStr(response.values[0].value, selectedObject.decimals)
              : '';
            setDisplayText(roundedValue);
          }
          setHasLoaded(true);
        }
      });
    }

    if (selectedObject.comparisonMetric !== 'none') {
      fetchComparatorValues();
    }
  }, []);

  useEffect(() => {
    const newVariable = getValue(selectedObject.valueId);
    if (!newVariable) {
      return;
    }

    if ((newVariable && newVariable.isEnabled && selectedObject.isAggregated) || (newVariable && newVariable.isEnabled && selectedObject.comparisonMetric !== 'none')) {
      // Every 5 seconds, filter out the variables that are not in the time interval anymore
      // We have to unsubscribe/subscribe because if the Tick was not started before, we have to do it now
      Tick.subscribe(filterValues, 5); // 5 seconds
    }

    return () => {
      Tick.unsubscribe(filterValues);
    };
  }, []);

  useEffect(() => {
    if (selectedObject.isAggregated) {
      const intervalTypeDefault = selectedObject.intervalType || 'shift';
      const { start, end } = getDataRangeStartEnd(intervalTypeDefault, currentShift);
      setHasAggregate(true);
      fetchValues(start, end, true);
    } else {
      API.getValues(selectedObject.valueId, {}, 1).then(response => {
        if (!hasUnmounted) {
          if (typeof response?.values[0]?.value === 'string' || typeof response?.values[0]?.value === 'boolean') {
            setDisplayText(response.values[0].value);
          } else {
            const roundedValue = (response?.values[0]?.value !== null)
              ? roundStr(response.values[0].value, selectedObject.decimals)
              : '';
            setDisplayText(roundedValue);
          }
          setHasLoaded(true);
          setHasAggregate(false);
          setValues([]);
        }
      });
    }
    if (selectedObject.comparisonMetric !== 'none') {
      fetchComparatorValues();
    }
  }, [selectedObject, currentShift]);

  useEffect(() => {
    socket?.on('value', data => handleSocketValue(data));

    return () => {
      socket?.removeListener('value', data => handleSocketValue(data));
      setHasUnmounted(true);
    };
  }, [socket]);

  useEffect(() => {
    updateTheRender();
  }, [values, comparisonValues, displayText]);

  const value = getValue(selectedObject.valueId);
  const valueDisplayed = value ? currentDisplayedValue : t('variableDeleted');

  return (
    <ResponsiveScorecard
      selectedObject={selectedObject}
      value={valueDisplayed}
      units={selectedObject.units || (value && value.units)}
      comparisonMetric={comparisonMetricObject}
      backgroundColor={backgroundColor}
      hasLoaded={hasLoaded}
      isCircle={isCircle}
      {...dimension}
      loaderPos={loaderPos}
    />
  );
};

ScorecardVariable.propTypes = {
  selectedObject: PropTypes.shape({
    decimals: PropTypes.number,
    intervalType: PropTypes.string,
    isAggregated: PropTypes.bool,
    scorecardType: PropTypes.string,
    valueId: PropTypes.string,
    units: PropTypes.string,
    comparisonMetric: PropTypes.string,
    aggregateType: PropTypes.string,
    comparisonIntervalType: PropTypes.string,
    comparisonAggregateType: PropTypes.string,
  }),
  backgroundColor: PropTypes.string,
  isCircle: PropTypes.bool,
  dimension: PropTypes.object,
  loaderPos: PropTypes.object,
};
ScorecardVariable.defaultProps = {
  selectedObject: {},
};

export default ScorecardVariable;

/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import Forms from '~components/forms';
import {
  FormStyle,
  shouldDisplayFormatInput,
  shouldDisplayLinksToInput,
  shouldDisplayTitleInput,
} from '~components/forms/FormStyle';
import { reduxOperations } from '~services';
import { CancelButton, DeleteButton, SubmitButton } from '~UI';

const WidgetEdition = ({
  handleSubmit, navSelected, onHide, topviewId, widget, widgetType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function handleDelete() {
    dispatch(reduxOperations.topviews.deleteWidget(topviewId, widget.id));
    onHide();
  }

  const Form = Forms[widgetType] || Forms.empty;
  return (
    <form
      id="WidgetForm"
      onSubmit={e => e.preventDefault()}
    >
      <div
        className={classnames({
          hiddenDiv: navSelected !== 'parameters' && navSelected !== 'advanced',
        })}
      >
        {shouldDisplayTitleInput(widgetType) && (
          <div
            className={classnames({
              hiddenDiv: navSelected !== 'parameters',
            })}
          >
            <div className="inputTitle">{t('title')}</div>
            <Input
              defaultValue={(widget && widget.title) || ''}
              name="title"
              placeholder={t('valueInput')}
            />
          </div>
        )}

        {shouldDisplayFormatInput(widgetType) && (
          <div className={classnames({
            hiddenDiv: navSelected !== 'parameters',
          })}
          >
            <div className="inputTitle">{t('Shape')}</div>
            <Select
              name="shape"
              defaultValue={(widget && widget.format && widget.format.shape) || 'rect'}
              className="fullwidth"
              options={[
                { label: 'Rectangle', value: 'rect' },
                { label: t('Circle'), value: 'circle' },
              ]}
            />
          </div>
        )}

        <Form
          selectedObject={widget}
          selectedType={widgetType}
          navSelected={navSelected}
        />

        {shouldDisplayLinksToInput(widgetType) && (
          <div
            className={classnames({
              hiddenDiv: navSelected !== 'parameters',
            })}
          >
            <div className="inputTitle">{t('LinksTo')}</div>
            <Input
              defaultValue={(widget && widget.linksTo) || ''}
              name="linksTo"
              placeholder={t('valueInput')}
            />
          </div>
        )}
      </div>

      <div
        className={classnames({
          hiddenDiv: navSelected !== 'style',
        })}
      >
        <FormStyle
          selectedObject={widget}
          type={widgetType}
        />
      </div>

      <div className="buttonsHolder flexSpaceBetween">
        {
          widget ? (
            <DeleteButton handleDelete={handleDelete} askConfirmation />
          ) : <div />
        }
        <div>
          <CancelButton onClick={onHide} />
          <SubmitButton onClick={handleSubmit} label={widget ? t('modify') : t('add')} />
        </div>
      </div>
    </form>
  );
};

WidgetEdition.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  navSelected: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  topviewId: PropTypes.string.isRequired,
  widget: PropTypes.shape({
    format: PropTypes.shape({
      shape: PropTypes.string,
    }),
    id: PropTypes.string,
    linksTo: PropTypes.string,
    title: PropTypes.string,
  }),
  widgetType: PropTypes.string.isRequired,
};
WidgetEdition.defaultProps = {
  widget: {},
};

export { WidgetEdition };

import { combineReducers } from 'redux';
// alphabetical order
import actions from './actions';
import audios from './audios';
import clientMetrics from './clientMetrics';
import commands from './commands';
import dashboards from './dashboards';
import featureToggles from './featureToggles';
import events from './events';
import folders from './folders';
import forms from './forms';
import images from './images';
import linkRedirection from './linkRedirection';
import logs from './logs';
import machines from './machines';
import operations from './operations';
import operators from './operators';
import pendingRequests from './pendingRequests';
import redirectHistory from './redirectHistory';
import reports from './reports';
import session from './session';
import settings from './settings';
import shifts from './shifts';
import stopwatches from './stopwatches';
import streams from './streams';
import tags from './tags';
import topviews from './topviews';
import triggers from './triggers';
import users from './users';
import variables from './variables';
import views from './views';

const allReducers = combineReducers({
  actions,
  audios,
  clientMetrics,
  commands,
  dashboards,
  featureToggles,
  events,
  folders,
  forms,
  images,
  linkRedirection,
  logs,
  machines,
  operations,
  operators,
  pendingRequests,
  redirectHistory,
  reports,
  session,
  settings,
  shifts,
  stopwatches,
  streams,
  tags,
  topviews,
  triggers,
  users,
  variables,
  views,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_RESPONSE') {
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;

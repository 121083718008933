import {
  setConfigurationMode,
  setFullscreen,
  setLanguage,
  setTriggersSearchInput,
  setActionsSearchInput,
  setSideBarSearchInput,
} from './actions';

export default {
  setConfigurationMode,
  setFullscreen,
  setLanguage,
  setTriggersSearchInput,
  setActionsSearchInput,
  setSideBarSearchInput,
};

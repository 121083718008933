import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Icon } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { sortArray } from '~utils/sort';
import { CheckboxToggle } from '~components/UI';
import './TriggerForms.scss';

const flattenStopCauses = (causes): { id: string; name: string; }[] => {
  let flatCauses: { id: string; name: string; }[] = [];
  causes.forEach(c => {
    flatCauses.push({ id: c.id, name: c.name });
    if (c.subMenu && c.subMenu.length > 0) {
      flatCauses = flatCauses.concat(flattenStopCauses(c.subMenu));
    }
  });
  return flatCauses;
};

const StopCauseAssignTriggerForm = ({ trigger }) => {
  const { t } = useTranslation();
  const { details } = trigger;

  const machines = useSelector((state: RootState) => state.machines);

  const [stopCause, setStopCause] = useState(details?.stopCauseId);
  const [machineId, setMachineId] = useState(details?.machineId);
  const [addComment, setAddComment] = useState(details?.addComment);

  const machine = machines.find(m => m.id === machineId);

  return (
    <div className="valuesTriggerForm">
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }))}
        value={machineId}
        onChange={setMachineId}
        placeholder={t('select')}
      />
      <div className="inputTitle">{t('stopCause')}</div>
      <Select
        style={!machineId ? { opacity: '0.6' } : null}
        name="stopCauseId"
        className="fullwidth"
        disabled={!machineId}
        value={stopCause}
        onChange={setStopCause}
        options={
          machine && flattenStopCauses(machine.stopCauses)
            .sort((causeA, causeB) => (causeA.name.toLowerCase() < causeB.name.toLowerCase() ? -1 : 1))
            .map(cause => ({ label: cause.name, value: cause.id }))
        }
        placeholder={t('select')}
      />
      <div className="inputTitle">
        {t('addComment')}
        <Icon
          icon="info-circle"
          tooltipLabel={t('addCommentDefinition')}
        />
        &nbsp;
        <CheckboxToggle
          name="addComment"
          controlledCheck={addComment}
          readOnly={!stopCause}
          onChange={setAddComment}
        />
      </div>
    </div>
  );
};

StopCauseAssignTriggerForm.propTypes = {
  trigger: PropTypes.shape({
    details: PropTypes.shape({
      machineId: PropTypes.string,
      stopCauseId: PropTypes.string,
      addComment: PropTypes.bool,
    }),
  }).isRequired,
};

export default StopCauseAssignTriggerForm;

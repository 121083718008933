import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Icon, Checkbox } from '@intelligenceindustrielle/react-ui-components';
import { DateTime } from 'luxon';
import { reducersTypes } from '~services';
import { CheckboxToggle, FontAwesome, ModalHandler } from '~UI';
import { stopPropagation } from '~utils';
import { EditIcon, DeleteIcon, DuplicateIcon } from '../../UI/IconButtons';
import { DeleteConfirmationForm } from '~components/Popups';

import './DefaultTable.scss';

const PopUpButton = ({ className, value, ...props }) => (
  <td
    role="button"
    className={`block ${className || ''}`}
    {...props}
  >
    {value}
  </td>
);

PopUpButton.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const PopUpComponent = ({
  Modal, onClick, value, className, tooltipLabel, tooltipPlace, language,
}) => {
  const id = uuidv4();
  const props = {
    value,
    className,
    'data-tip': tooltipLabel ? tooltipLabel[language] : null,
    'data-place': tooltipPlace,
    'data-for': id,
  };

  if (Modal === null) {
    return (
      <span>
        {tooltipLabel && <Tooltip id={id} className="td-tooltip" />}
        <div onClick={onClick} {...props} />
      </span>
    );
  }

  return (
    <ModalHandler
      Modal={Modal}
      Trigger={{ Component: PopUpButton, props }}
    />
  );
};

PopUpComponent.propTypes = {
  Modal: PropTypes.shape({
    Component: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
    ]),
    props: PropTypes.shape({}),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  tooltipLabel: PropTypes.shape({}).isRequired,
  tooltipPlace: PropTypes.string.isRequired,
  language: reducersTypes.views.language.isRequired,
};

const cellClicked = (e, id, onClickEvent) => {
  stopPropagation(e);
  onClickEvent(id);
};

/**
 * Returns the proper cell <td> format
 * Per example, if the result is true or false,
 * it will be a Check / Cross icon and not "true" / "false"
 */
const getCell = (input, prop, toggleOnChange, onClickEvent, entriesStyle, editForm, editFormProps, editPopUp) => {
  const value = input[prop];

  let customStyle;
  // check for custom style for the current entry (current <td>)
  const entryStyle = entriesStyle && entriesStyle.find(entry => entry.property === prop);
  if (entryStyle) {
    // check if the custom style depends on a conditon, if so, check it
    if (!entryStyle.condition || entryStyle.condition(input)) {
      customStyle = entryStyle.style;
    }
  }

  if (prop === 'toggle') {
    return (
      <td
        key={`toggle_${input.id}`}
        style={{ ...customStyle, cursor: 'default' }}
        className="cellMaxWidth70"
      >
        <CheckboxToggle
          name={input.id}
          onChange={e => toggleOnChange(input.id, e.target.value)}
          defaultChecked={input.isEnabled}
        />
      </td>
    );
  }

  if (prop === 'check') {
    return (
      <td
        key={`check_${input.id}`}
        style={{ ...customStyle, cursor: 'default' }}
        className="cellMaxWidth50"
      >
        <Checkbox
          size="15px"
          onChange={newChecked => toggleOnChange(input.id, newChecked)}
          checked={input.isEnabled}
        />
      </td>
    );
  }

  if (typeof value === 'boolean' && prop !== 'lerp') {
    return (
      <td
        role="button"
        key={`boolean_${input.id}`}
        onClick={onClickEvent && (e => cellClicked(e, input.id, onClickEvent))}
        style={{ ...customStyle }}
        className="standardCell"
      >
        <FontAwesome icon={value ? 'check' : 'times'} />
      </td>
    );
  }
  if (prop === 'createdAt' || prop === 'modifiedAt') {
    return (
      <td
        role="button"
        key={`${prop}_${input.id}`}
        onClick={onClickEvent && (e => cellClicked(e, input.id, onClickEvent))}
        className="standardCell"
        style={{ ...customStyle }}
      >
        {value ? DateTime.fromMillis(value).toRelative() : '-'}
      </td>
    );
  }
  if (prop === 'lerp') {
    const inputIn = `[${input.in_min}, ${input.in_max}] `;
    const inputOut = ` [${input.out_min}, ${input.out_max}]`;
    return (
      <td
        role="button"
        key={`${prop}_${input.id}`}
        onClick={onClickEvent && (e => cellClicked(e, input.id, onClickEvent))}
        className="standardCell"
        style={{ ...customStyle }}
      >
        {
          input.lerp ? (
            <>
              {inputIn}
              <FontAwesome icon="arrow-right" style={{ fontSize: '13px' }} />
              {inputOut}
            </>
          ) : <FontAwesome icon="xmark" height="16px" />
        }
      </td>
    );
  }
  if (prop === 'machines') {
    return (
      <td
        key={`${prop}_${input.id}`}
        style={{ ...customStyle, cursor: 'default' }}
      >
        <div className="cellMaxWidth150" title={value}>
          {value}
        </div>
      </td>
    );
  }
  return (
    editPopUp ? (
      <PopUpComponent
        value={value}
        Modal={{
          Component: editForm,
          props: {
            modifiedItemId: input.id,
            ...editFormProps,
          },
        }}
        tooltipOff
      />
    )
      : (
        <td
          role="button"
          key={`${prop}_${input.id}`}
          onClick={onClickEvent && (e => cellClicked(e, input.id, onClickEvent))}
          className="standardCell"
          style={{ ...customStyle }}
        >
          <div className="name">
            {value}
            {input.type === 'Multiple' && prop === 'name' && (
              <div style={{ position: 'relative', top: '3px' }}>
                <FontAwesome icon="carousel" height="14px" />
              </div>
            )}
          </div>
        </td>
      )
  );
};

const editHandler = (editForm, editFunction, input, editFormProps) => (
  editForm ? (
    <EditIcon
      disabled={input.status && input.status === 'ON'}
      Modal={{
        Component: editForm,
        props: {
          event: input,
          machineId: input.machineId,
          modifiedItemId: input.id,
          ...editFormProps,
        },
      }}
      tooltipOff
    />
  ) : (
    <EditIcon
      onClick={() => editFunction(input.id)}
      tooltipOff
    />
  )
);

const duplicateHandler = (duplicateFunction, toClone) => (
  <DuplicateIcon
    onClick={() => duplicateFunction(toClone)}
  />
);

const deleteHandler = (t, deleteFunction, id, deleteType = { type: 'delete', usedBy: null }) => {
  if (deleteType.type === 'delete') {
    return (
      <DeleteIcon
        Modal={{
          Component: DeleteConfirmationForm,
          props: { handleDelete: () => deleteFunction(id) },
        }}
        tooltipOff
      />
    );
  }
  if (deleteType.type === 'warning') {
    return (
      <DeleteIcon
        Modal={{
          Component: DeleteConfirmationForm,
          props: {
            handleDelete: () => deleteFunction(id),
            body: (
              <>
                {deleteType.usedBy}
                <br />
                {t('beSureDeletion')}
              </>
            ),
          },
        }}
        tooltipOff
      />
    );
  }

  // Final case used when you do not want a confirmation form and let the parent componenent handle the deletion
  return (
    <DeleteIcon
      onClick={() => deleteFunction(id)}
      tooltipOff
    />
  );
};

const DefaultTable = ({
  editPopUp, columnNames, entriesProperties, entriesStyle,
  entries, editForm, editFunction, duplicateFunction, deleteFunction,
  toggleOnChange, editFormProps, onClickEvent,
  deleteType, noNumbering,
}) => {
  const { t } = useTranslation();
  return (
    <table className="DefaultTable">
      <thead>
        <tr>
          {!noNumbering && <th className="itemNumbering standardCell" />}
          {
            columnNames.map(({ name, info }) => (
              <th key={`col_${name}`} className={`${name !== '' && 'standardColumn'}`}>
                {name}
                {
                  info && (
                    <Icon
                      icon="info-circle"
                      tooltipLabel={info}
                    />
                  )
                }
              </th>
            ))
          }
          {(editForm || deleteFunction || editFunction) && <th className="editColumn">{t('edit')}</th>}
        </tr>
      </thead>
      <tbody>
        {
          entries.map((input, index) => (
            <tr
              key={input.id}
              className={onClickEvent ? 'isOnClickEvent' : undefined}
            >
              {!noNumbering && (editPopUp ? (
                <PopUpComponent
                  className="itemNumbering"
                  value={index + 1}
                  Modal={{
                    Component: editForm,
                    props: {
                      modifiedItemId: input.id,
                      ...editFormProps,
                    },
                  }}
                  tooltipOff
                />
              ) : (
                <td
                  role="button"
                  onClick={onClickEvent && (e => cellClicked(e, input.id, onClickEvent))}
                  className="itemNumbering"
                >
                  {index + 1}
                </td>
              )
              )}
              {entriesProperties.map(prop => getCell(
                input,
                prop,
                toggleOnChange,
                onClickEvent,
                entriesStyle,
                editForm,
                editFormProps,
                editPopUp,
              ))}
              {
                (editForm || deleteFunction || editFunction) && (
                  <td className="editColumn" style={{ cursor: 'default' }}>
                    {(editForm || editFunction) ? (editHandler(editForm, editFunction, input, editFormProps)) : null}
                    {duplicateFunction ? duplicateHandler(duplicateFunction, input) : null}
                    {deleteFunction
                      ? deleteHandler(
                        t,
                        deleteFunction,
                        input.id,
                        deleteType ? deleteType(input.id) : { type: 'delete', usedBy: null },
                      )
                      : null}
                  </td>
                )
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

DefaultTable.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  entriesProperties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  entriesStyle: PropTypes.arrayOf(PropTypes.shape({})),
  entries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editFormProps: PropTypes.shape({}),
  editForm: PropTypes.elementType,
  editFunction: PropTypes.func,
  deleteFunction: PropTypes.func,
  duplicateFunction: PropTypes.func,
  deleteType: PropTypes.func,
  toggleOnChange: PropTypes.func,
  onClickEvent: PropTypes.func,
  editPopUp: PropTypes.bool,
  noNumbering: PropTypes.bool,
};

DefaultTable.defaultProps = {
  editFormProps: {},
  editForm: null,
  editFunction: null,
  deleteFunction: null,
  duplicateFunction: null,
  deleteType: null,
  toggleOnChange: null,
  onClickEvent: null,
  editPopUp: false,
  entriesStyle: null,
  noNumbering: false,
};

export default DefaultTable;

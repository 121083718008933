import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import { DeleteConfirmationForm } from '~components/Popups';
import { reducersTypes, reduxOperations } from '~services/index';
import { getSocket } from '~services/socket';
import { evaluate } from '~utils/parser';
import { showSuccess, showError } from '~utils/toast';
import TileComponent from '~components/Pages/Grids/TileComponent';
import TileContents from './TileContents';
import TileCreationForm from '../FormTile/TileCreationForm';
import { RootState } from '~services/store';

const DEFAULT_DARK_BACKGROUND_COLOR = '#444';
const DEFAULT_LIGHT_BACKGROUND_COLOR = '#fff';
const HEADBAND_HEIGHT = 28.56;

const Tile = ({
  dashboardId, height, isConfigurationAllowed, onSuppression, tile, width,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const tileRef = useRef(tile);
  const socket = getSocket();

  useEffect(() => {
    tileRef.current = tile;
  }, [tile]);

  const theme = useSelector((state: RootState) => state.session.theme);

  const defaultTheme = theme === 'Dark'
    ? DEFAULT_DARK_BACKGROUND_COLOR
    : DEFAULT_LIGHT_BACKGROUND_COLOR;
  const [backgroundColor, setBackgroundColor] = useState(defaultTheme);

  const handleSocketData = socketData => {
    const websocketData = JSON.parse(socketData);

    let newbackgroundColor = localStorage.getItem('theme') === 'Dark' ? DEFAULT_DARK_BACKGROUND_COLOR : DEFAULT_LIGHT_BACKGROUND_COLOR;
    if (tileRef.current.rules) {
      const colorRules = tileRef.current.rules.filter(rule => rule.type === 'color');
      for (const rule of colorRules) {
        if (evaluate(rule.condition, websocketData, rule.variableType)) {
          newbackgroundColor = rule.color;
          break;
        }
      }
    }
    setBackgroundColor(newbackgroundColor);
  };

  useEffect(() => {
    socket?.on('data', handleSocketData);

    return () => {
      socket?.removeListener('data', handleSocketData);
    };
  }, [tile.rules]);

  const handleDuplication = () => {
    const tileClone = omit(tile, ['_id']);
    tileClone.rules.forEach(element => {
      delete element.id;
    });

    return dispatch(reduxOperations.dashboards.addDashboardTile(dashboardId, tileClone) as any)
      .then(res => {
        if (!res.error && res.payload) {
          showSuccess(t('operationSucceeded'));
        } else {
          showError(t('operationFailed'));
        }
      });
  };

  const TileContent = TileContents[tile.type] || TileContents.empty;

  return (
    <TileComponent
      config={{
        isConfigurationEnabled: isConfigurationAllowed,
        isSmall: true,
        displayHeadband: true,
        handleDuplication,
        tile,
        dashboardId,
        EditionForm: {
          Component: TileCreationForm,
          props: { selectedTile: tile.id, tile, isNewTile: false },
        },
        DeletionForm: {
          Component: DeleteConfirmationForm,
          props: { handleDelete: () => onSuppression(tile.id) },
        },
      }}
    >
      <TileContent
        tile={tile}
        isConfigurationAllowed={isConfigurationAllowed}
        backgroundColor={backgroundColor}
        height={isConfigurationAllowed ? height - HEADBAND_HEIGHT : height}
        width={width}
      />
    </TileComponent>
  );
};

Tile.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  isConfigurationAllowed: PropTypes.bool.isRequired,
  onSuppression: PropTypes.func.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
  width: PropTypes.number.isRequired,
};

export default Tile;

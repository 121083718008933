import React from 'react';
import { AudioRecorder } from '~UI/AudioRecorder/AudioRecorder';
import './TextArea.scss';

interface TextAreaProps {
  value: string;
  onChange: (text: string) => void;
  placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({ value, onChange, placeholder }) => (
  <div className="text-area">
    <textarea
      className="text-area"
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
    />
    <div className="audio-recorder">
      <AudioRecorder setText={text => onChange(value + text)} />
    </div>
  </div>
);

TextArea.defaultProps = {
  placeholder: '',
};

export { TextArea };

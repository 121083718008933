import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Select } from '@intelligenceindustrielle/react-ui-components';
import { GithubPicker } from 'react-color';
import { CheckboxToggle } from '~UI';
import {
  getStreamsToGroupedOptions,
  getVariablesToGroupedOptions,
} from '../utils';

import ContentForms from './ContentForms';
import './FormGauge.scss';

const colors = [
  '#FFFFFF', '#B3B3B3', '#F44E3B', '#BF3838', '#FE9200', '#FCDC00', '#FB9E00', '#DBDF00', '#A4DD00',
  '#1E871E', '#194D33', '#16A5A5', '#0078FF', '#0062B1', '#AEA1FF', '#7B64FF', '#653294', '#FA28FF',
];

const FormGauge = ({ selectedObject, selectedType }) => {
  const { t } = useTranslation();

  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);

  const variableOptions = [getVariablesToGroupedOptions(variables), ...getStreamsToGroupedOptions(streams)];

  const [dataType, setDataType] = useState(selectedObject.dataType || 'machine');
  const [objectiveType, setObjectiveType] = useState(selectedObject.objectiveType || 'value');
  const [showGoalToggle, setGoalToggle] = useState(typeof selectedObject.showGoalToggle === 'boolean' ? selectedObject.showGoalToggle : typeof selectedObject.goal === 'number');
  const [belowGoalColor, setBelowGoalColor] = useState(selectedObject.belowGoalColor || '#0078FF');
  const [aboveGoalColor, setAboveGoalColor] = useState(selectedObject.aboveGoalColor || '#0078FF');
  const [showBelowColorDropdown, setShowBelowDropdown] = useState(false);
  const [showAboveColorDropdown, setShowAboveColorDropdown] = useState(false);

  const buttonsDataType = [
    { label: t('events'), value: 'events' },
    { label: t('variables'), value: 'variables' },
    { label: t('machine'), value: 'machine' },
  ];

  const buttonsObjectiveType = [
    { label: t('value'), value: 'value' },
    { label: t('variable'), value: 'variable' },
  ];

  const ContentForm = ContentForms[dataType];

  const showDropdown = isAbove => {
    if (isAbove) {
      setShowAboveColorDropdown(!showAboveColorDropdown);
      setShowBelowDropdown(false);
    } else {
      setShowBelowDropdown(!showBelowColorDropdown);
      setShowAboveColorDropdown(false);
    }
  };

  return (
    <div className="Form">
      <div className="inputTitle">{t('DataType')}</div>
      <input type="hidden" name="dataType" value={dataType} />
      <ButtonGroup
        buttons={buttonsDataType}
        onChange={type => setDataType(type)}
        value={dataType}
      />

      <ContentForm
        selectedObject={selectedObject}
        selectedType={selectedType}
      />

      <div className="inputTitle">
        {t('setGoal')}
        &nbsp;
        <CheckboxToggle
          name="showGoalToggle"
          controlledCheck={showGoalToggle}
          onChange={() => setGoalToggle(!showGoalToggle)}
        />
      </div>
      {showGoalToggle && (
        <>
          <input type="hidden" name="objectiveType" value={objectiveType} />
          <ButtonGroup
            buttons={buttonsObjectiveType}
            onChange={type => setObjectiveType(type)}
            value={objectiveType}
          />
        </>
      )}
      {showGoalToggle && objectiveType === 'value' && (
        <input
          type="number"
          name="number:goal"
          min={dataType !== 'variables' ? '0' : ''}
          step={dataType !== 'events' ? '0.01' : '1'}
          max={dataType === 'machine' ? '100' : ''}
          className="fullwidth"
          defaultValue={selectedObject.goal}
        />
      )}
      {showGoalToggle && objectiveType === 'variable' && (
        <Select
          name="string:goal"
          options={variableOptions}
          defaultValue={selectedObject.goal}
          placeholder={t('select')}
        />
      )}
      {showGoalToggle && (
        <div className="gaugeColorInputs">
          <div className="colorInputContainer">
            <div className="inputTitle">{t('belowGoalColor')}</div>
            <div
              onClick={() => showDropdown(false)}
              className="colorInput"
              style={{ backgroundColor: belowGoalColor }}
            />
            <div className="pickerContainer" style={{ display: !showBelowColorDropdown && 'none' }}>
              <input type="hidden" name="belowGoalColor" value={belowGoalColor} />
              <GithubPicker
                colors={colors}
                color={belowGoalColor}
                onChange={color => {
                  setBelowGoalColor(color.hex);
                  setShowBelowDropdown(false);
                }}
                width="244px"
                triangle="hide"
              />
            </div>
          </div>
          <div className="colorInputContainer">
            <div className="inputTitle">{t('aboveGoalColor')}</div>
            <div
              onClick={() => showDropdown(true)}
              className="colorInput"
              style={{ backgroundColor: aboveGoalColor }}
            />
            <div className="pickerContainer" style={{ display: !showAboveColorDropdown && 'none' }}>
              <input type="hidden" name="aboveGoalColor" value={aboveGoalColor} />
              <GithubPicker
                colors={colors}
                color={aboveGoalColor}
                onChange={color => {
                  setAboveGoalColor(color.hex);
                  setShowAboveColorDropdown(false);
                }}
                width="244px"
                triangle="hide"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

FormGauge.propTypes = {
  selectedObject: PropTypes.shape({
    dataType: PropTypes.string,
    goal: PropTypes.number,
    objectiveType: PropTypes.oneOf(['value', 'variable']),
    showGoalToggle: PropTypes.bool,
    belowGoalColor: PropTypes.string,
    aboveGoalColor: PropTypes.string,
    variableGoal: PropTypes.string,
  }),
  selectedType: PropTypes.string.isRequired,
};
FormGauge.defaultProps = {
  selectedObject: {},
};

export { FormGauge };

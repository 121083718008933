import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import mapValues from 'lodash.mapvalues';
import API from '~services/endpoints';
import { roundStr } from '~utils/math';
import { getVariablesDependencies } from '~utils';
import { isExternalLink } from '~utils/isExternalLink';
import InfoPopup from '~components/UI/Popups/InfoPopup/InfoPopup';
import { reduxOperations, reducersTypes } from '~services';
import { getSocket } from '~services/socket';
import {
  PushButton,
  MachineOperatorPopup,
  MachineWorkorderPopup,
  TransitEventPopup,
  JsonFormPopup,
} from '~UI';
import { evaluate, tryJSONParse } from '~utils/parser';
import { getButtonFontSize } from '~utils/responsiveValue';
import { showError } from '~utils/toast';
import { ruleTypes } from '~utils/types';
import TileContents from '../TileContents';
import './ButtonTile.scss';

const variableUsageRegex = '\\$\\{[A-Za-z0-9_-]+\\}';

const ButtonTile = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const socket = getSocket();
  const dispatch = useDispatch();
  const history = useHistory();
  const clientName = useSelector(state => state.settings.settings);
  const connexion = useSelector(state => state.session.connexion);
  const { t } = useTranslation();

  const [showButton, setShowButton] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showWhiteBorder, setShowWhiteBorder] = useState(false);
  const [buttonSize, setButtonSize] = useState(40);
  const [mediaURL, setMediaURL] = useState(tile.mediaURL || '');
  const [buttonText, setButtonText] = useState(tile.buttonText || '');
  const [buttonTrigger, setButtonTrigger] = useState(tile.onClickTrigger || '');
  const [buttonColor, setButtonColor] = useState(tile.buttonColor);

  const updateButtonSize = () => {
    const btnSize = getButtonFontSize(height, width, tile.zoom || 'medium');
    setButtonSize(btnSize);
  };

  let textAndRules = buttonText;
  textAndRules += ` ${tile.mediaURL || ''}`;
  tile.rules.forEach(rule => {
    if (rule.type === 'text') {
      textAndRules += ` ${rule.text}`;
    }
    if (rule.type === 'media') {
      textAndRules += ` ${rule.media}`;
    }
  });

  const fetchValues = async () => {
    const valuesToDisplay = getVariablesDependencies(textAndRules).reduce((ac, a) => ({ ...ac, [a]: '' }), {});
    for (const valueId in valuesToDisplay) {
      if (Object.hasOwnProperty.call(valuesToDisplay, valueId)) {
        const value = await API.getValues(valueId, {}, 1).then(response => {
          if (typeof response?.values[0]?.value === 'string' || typeof response?.values[0]?.value === 'boolean') {
            return response.values[0].value;
          }
          return (response && response.values && response.values[0] && response.values[0].value !== null)
            ? roundStr(response.values[0].value, response.values[0].value % 1 !== 0 ? 1 : 0)
            : '';
        });
        valuesToDisplay[valueId] = value;
      }
    }
    return valuesToDisplay;
  };

  const replaceVariables = (str, newValuesToDisplay) => str.replace(
    new RegExp(`(${variableUsageRegex})`, 'g'),
    p => {
      const valueUsageRegex = new RegExp(`(${variableUsageRegex})`, 'g');
      return p
        .toString()
        .replace(valueUsageRegex, valueIdWithBrackets => {
          const valueIdWithoutBrackets = valueIdWithBrackets.substr(2).slice(0, -1);
          const value = newValuesToDisplay[valueIdWithoutBrackets];
          if (value === undefined || value === null) {
            return null;
          }
          return value;
        });
    },
  );

  const handleSocketData = async socketData => {
    const websocketData = JSON.parse(socketData);
    const newValuesToDisplay = await fetchValues();

    let shouldHideButton = false;
    let colorRuleApplied = false;
    let hideRuleApplied = false;
    let mediaRuleApplied = false;
    let triggerRuleApplied = false;
    let textRuleApplied = false;

    if (tile.rules) {
      const colorRules = tile.rules.filter(rule => rule.type === ruleTypes.COLOR);
      for (const colorRule of colorRules) {
        if (!colorRuleApplied && evaluate(colorRule.condition, websocketData, colorRule.variableType)) {
          const newButtonColor = replaceVariables(colorRule.contentColor, newValuesToDisplay);
          setButtonColor(newButtonColor);
          colorRuleApplied = true;
        }
      }
      if (!colorRuleApplied) {
        setButtonColor(tile.buttonColor);
      }

      const hideRules = tile.rules.filter(rule => rule.type === ruleTypes.HIDE);
      for (const rule of hideRules) {
        if (!hideRuleApplied && evaluate(rule.condition, websocketData, rule.variableType)) {
          shouldHideButton = true;
          hideRuleApplied = true;
          break;
        }
      }
      setShowButton(!shouldHideButton);

      const mediaRules = tile.rules.filter(rule => rule.type === 'media');
      for (const mediaRule of mediaRules) {
        if (!mediaRuleApplied && evaluate(mediaRule.condition, websocketData, mediaRule.variableType)) {
          const newMediaURL = replaceVariables(mediaRule.media, newValuesToDisplay);
          setMediaURL(newMediaURL);
          mediaRuleApplied = true;
        }
      }
      if (!mediaRuleApplied) {
        setMediaURL(tile.mediaURL);
      }

      const onClickTriggerRules = tile.rules.filter(rule => rule.type === ruleTypes.TRIGGER);
      for (const onClickTriggerRule of onClickTriggerRules) {
        if (!triggerRuleApplied
          && evaluate(onClickTriggerRule.condition, websocketData, onClickTriggerRule.variableType)) {
          const newOnClickTrigger = replaceVariables(onClickTriggerRule.trigger, newValuesToDisplay);
          setButtonTrigger(newOnClickTrigger);
          triggerRuleApplied = true;
        }
      }
      if (!triggerRuleApplied) {
        setButtonTrigger(tile.onClickTrigger);
      }

      const textRules = tile.rules.filter(rule => rule.type === 'text');
      for (const textRule of textRules) {
        if (!textRuleApplied && evaluate(textRule.condition, websocketData, textRule.variableType)) {
          const newButtonText = replaceVariables(textRule.text, newValuesToDisplay);
          setButtonText(newButtonText);
          textRuleApplied = true;
        }
      }
      if (!textRuleApplied) {
        setButtonText(tile.buttonText);
      }
    }
  };

  useEffect(() => {
    socket?.on('data', handleSocketData);

    return () => {
      socket?.removeListener('data', handleSocketData);
    };
  }, [tile.rules]);

  useEffect(() => {
    updateButtonSize();
  }, [height, width, tile.zoom]);

  function handleURL() {
    if (tile.openOption === 'newTab') {
      window.open(mediaURL, '_blank');
    } else if (tile.openOption === 'redirection') {
      if (mediaURL) {
        if ((isExternalLink(mediaURL) && !mediaURL.startsWith('/')) || mediaURL.includes('redirect')) {
          window.location.href = mediaURL;
        } else {
          const hostPath = mediaURL.replace('http://', '').replace('https://', '').split('/')[0];
          const inAppURL = mediaURL.replace(hostPath, '').replace('http://', '').replace('https://', '');
          dispatch(reduxOperations.redirectHistory.redirectHistoryPush(window.location.pathname));
          history.push(inAppURL);
        }
      }
    } else if (tile.openOption === 'popup') {
      setShowInfoPopup(true);
    }
  }

  function trigger() {
    const triggerId = buttonTrigger;
    const params = tryJSONParse(tile.buttonParams) || {};
    if (triggerId) {
      // Sent to the global GA view for global KPI analysis
      ReactGA.event({
        category: clientName,
        action: 'TRIGGER_BUTTON_CLICKED',
        label: connexion.infos.email,
      });

      // Each client has its own GA property defined and the data will be more precise on each client property
      ReactGA.event({
        category: clientName,
        action: 'TRIGGER_BUTTON_CLICKED',
        label: JSON.stringify({
          user: connexion.infos.email,
          payload: {
            triggerId,
            params,
          },
        }),
      });
      return dispatch(reduxOperations.triggers.executeTrigger(triggerId, params)).then(res => {
        if (res.payload.response === 'Rule is not active') {
          showError(t('ruleNotActive'));
        }
      });
    }
    return Promise.resolve();
  }

  const handleOnClickButton = async () => {
    setShowWhiteBorder(true);
    setTimeout(() => setShowWhiteBorder(false), 750);
    if (tile.buttonType === 'triggerButton') {
      await trigger();
      if (tile.openOption !== '') {
        handleURL();
      }
    } else if (tile.buttonType === 'popupButton') {
      setShowPopup(true);
    }
  };

  const handleCloseInfoPopup = () => {
    setShowInfoPopup(false);
  };

  const onPopupHide = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (tile.buttonText !== buttonText) {
      setButtonText(tile.buttonText);
    }
    if (tile.buttonColor !== buttonColor) {
      setButtonColor(tile.buttonColor);
    }
    if (tile.mediaURL !== mediaURL) {
      setMediaURL(tile.mediaURL);
    }
  }, [tile]);

  function getPopup() {
    const popupParams = tryJSONParse(tile.buttonParams) || {};
    switch (tile.popup) {
      case 'MachineOperatorPopup':
        return (
          <MachineOperatorPopup
            show={showPopup}
            onHide={onPopupHide}
            params={popupParams}
            onSubmitTrigger={tile.onClickTrigger}
          />
        );
      case 'MachineWorkorderPopup':
        return (
          <MachineWorkorderPopup
            show={showPopup}
            onHide={onPopupHide}
            params={popupParams}
            onSubmitTrigger={tile.onClickTrigger}
          />
        );
      case 'TransitEventPopup':
        return (
          <TransitEventPopup
            show={showPopup}
            onHide={onPopupHide}
            params={popupParams}
            onSubmitTrigger={tile.onClickTrigger}
          />
        );
      default:
        // Params need to be key / value pairs
        return tile.popup && (
          <JsonFormPopup
            formId={tile.popup}
            show={showPopup}
            onHide={onPopupHide}
            params={mapValues(popupParams, e => e.value)}
            onSubmitTrigger={tile.onClickTrigger}
          />
        );
    }
  }

  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      whiteBorder={showWhiteBorder}
      showTitle={showButton}
      height={height}
      width={width}
    >
      {showButton && (
        <div className="ButtonTile framed flex">
          <PushButton
            buttonSize={buttonSize}
            buttonColor={buttonColor}
            buttonText={buttonText}
            fontSize={tile.contentSize}
            onClick={handleOnClickButton}
          />
        </div>
      )}
      {getPopup()}
      <InfoPopup
        showInfoPopup={showInfoPopup}
        handleClosePopup={handleCloseInfoPopup}
        mediaURL={mediaURL}
      />
    </TileContents>
  );
};

ButtonTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
  width: PropTypes.number.isRequired,
};

export { ButtonTile };

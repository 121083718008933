import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showSuccess } from '~utils/toast';
import { reduxOperations, reducersTypes } from '~services';
import { PageTemplate } from '~components/Pages';
import { DeleteConfirmationForm } from '~components/Popups';
import {
  ResourcesHandler, Cards, CreateCard, ImageCard, DefaultModal,
} from '~UI';
import ImagePopUpForm from './ImagePopUpForm';

const ImagesPage = ({
  images, fetchImages, deleteImage,
}) => {
  const { t } = useTranslation();
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const [showImagePopup, setShowImagePopup] = useState(false);

  const handleClosePopup = () => {
    setShowImagePopup(false);
  };

  const getContents = () => (
    <PageTemplate
      sidebar
    >
      <Cards>
        <CreateCard
          title={t('addImage')}
          modal={{
            Component: ImagePopUpForm,
            props: { isCreating: true },
          }}
        />
        {images?.toReversed()
          .map(image => (
            <ImageCard
              key={image.id}
              card={{ ...image, backgroundURL: image.data }}
              deleteModal={{
                Component: DeleteConfirmationForm,
                props: { handleDelete: () => deleteImage(image.id) },
              }}
              optionalAction={{
                optText: t('url'),
                optIcon: 'copy',
                optOnClick: () => {
                  navigator.clipboard.writeText(image.data);
                  showSuccess(t('linkCopied'));
                },
              }}
              redirectRessource={() => {
                setModalImageUrl(image.data);
                setShowImagePopup(true);
              }}
            />
          ))}
        <DefaultModal
          show={showImagePopup}
          title="Image"
          closePopup={handleClosePopup}
          fullSize
        >
          <div style={{ height: '80vh' }}>
            <div className="framed flex V">
              <img
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                src={modalImageUrl}
                alt="Failed to load"
              />
            </div>
          </div>
        </DefaultModal>
      </Cards>
    </PageTemplate>
  );

  return (
    <ResourcesHandler
      resources={[images]}
      resourceFetchers={[fetchImages]}
      getContents={getContents}
    />
  );
};

ImagesPage.propTypes = {
  images: reducersTypes.images.isRequired,
  fetchImages: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    images: state.images.images,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchImages: reduxOperations.images.fetchImages,
    deleteImage: reduxOperations.images.deleteImage,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ImagesPage);

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GoTopHoverRoundButton, NPSPopupHoverRoundButton, ChatPopupHoverRoundButton } from '~UI';
import NPSPopup from '../../Popups/NPSPopup/NPSPopup';
import ChatPopup from '../../Popups/ChatPopup/ChatPopup';
import TopMenu from '../TopMenu/TopMenu';
import PageTemplateComponent from './PageTemplateComponent';

const NPS_POPUP_DAYS_INTERVAL = 90; // 3 months
const NPS_POPUP_DISPLAY_ODDS = 0.005;

function PageTemplate(props) {
  const {
    footer,
    header,
    sidebar,
    noNPSPopup,
    NPSPopups,
    chat,
    noScrollBar,
    pageTemplateClass,
    modifyIcon,
    children,
    noHorizontalPadding,
  } = props;

  const { t } = useTranslation();

  const [showNPSPopup, setShowNPSPopup] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const sidebarRef = useRef(null);

  const handleScroll = event => {
    // there is no scrollTopMax property in Chrome, just in firefox
    if (sidebarRef.current && event.target !== sidebarRef.current.children[0]) {
      if (event.srcElement.scrollTop === 0) {
        setIsScrolledDown(false);
      } else {
        setIsScrolledDown(true);
      }
    }
  };

  useEffect(() => {
    const today = new Date();
    const lastShownDate = localStorage.getItem('NPSPopupLastShown');

    // Show NPSPopup if it was last shown X days ago with Y probability
    if ((!lastShownDate
      || Math.floor((Date.parse(today) - Date.parse(lastShownDate)) / 86400000) > NPS_POPUP_DAYS_INTERVAL)
      && Math.random() < NPS_POPUP_DISPLAY_ODDS) {
      localStorage.setItem('NPSPopupLastShown', today);
      setShowNPSPopup(true);
    }

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const topmenu = header ? <TopMenu modifyIcon={modifyIcon} /> : null;

  return (
    <PageTemplateComponent
      {...props}
      footer={footer}
      pageTemplateClass={pageTemplateClass}
      header={topmenu}
      sidebar={sidebar}
      sidebarRef={sidebarRef}
      noScrollBar={noScrollBar}
      noHorizontalPadding={noHorizontalPadding}
    >
      {
        (!noNPSPopup || NPSPopups) && (
          <NPSPopup
            stayOnOutsideClick={!NPSPopups}
            closePopup={() => setShowNPSPopup(false)}
            show={showNPSPopup}
          />
        )
      }
      {
        NPSPopups && (
          <NPSPopupHoverRoundButton
            position={2}
            onClick={() => setShowNPSPopup(true)}
            toolTipLabel={t('submitNPS')}
          />
        )
      }
      {
        showChat && chat && (
          <ChatPopup
            setShow={setShowChat}
            show={showChat}
            closePopup={() => setShowChat(false)}
          />
        )
      }
      {
        chat && (
          <ChatPopupHoverRoundButton
            position={1}
            onClick={() => setShowChat(true)}
            toolTipLabel={t('chat')}
          />
        )
      }
      {children}
      {
        isScrolledDown && !noScrollBar && (
          <GoTopHoverRoundButton
            position={1}
          />
        )
      }
    </PageTemplateComponent>
  );
}

PageTemplate.propTypes = {
  footer: PropTypes.bool,
  header: PropTypes.bool,
  goBackButton: PropTypes.bool,
  left: PropTypes.arrayOf(PropTypes.node),
  pageTemplateClass: PropTypes.string,
  sidebar: PropTypes.bool,
  noNPSPopup: PropTypes.bool,
  NPSPopups: PropTypes.bool,
  noScrollBar: PropTypes.bool,
  chat: PropTypes.bool,
  modifyIcon: PropTypes.element,
  children: PropTypes.node,
  noHorizontalPadding: PropTypes.bool,
  title: PropTypes.string,
  right: PropTypes.arrayOf(PropTypes.node),
};

PageTemplate.defaultProps = {
  footer: undefined,
  header: true,
  goBackButton: false,
  left: [],
  pageTemplateClass: undefined,
  sidebar: false,
  noNPSPopup: false,
  chat: false,
  NPSPopups: false,
  noScrollBar: false,
  modifyIcon: null,
  children: null,
  noHorizontalPadding: false,
  title: undefined,
  right: [],
};

export default PageTemplate;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { reduxOperations, reducersTypes } from '~services';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { FontAwesome } from '~UI';
import { evaluate } from '~utils/parser';
import { audioTypes } from '~utils/types';

const AudioFile = ({ tile }) => {
  const socket = getSocket();
  const dispatch = useDispatch();
  let isSocketInitialized = false;

  const [currentValue, setCurrentValue] = useState();
  const [currentUrl, setCurrentUrl] = useState(tile.url);

  // Keep the current triggerValue as soon as the component mounts
  useEffect(() => {
    API.getValues(tile.valueId, {}, 1)
      .then(response => {
        let realValue = response.values.length ? response.values[0].value : undefined;
        if (/^\d+$/.test(realValue)) {
          realValue = Number(realValue);
        } else if (realValue === 'true' || realValue === 'false') {
          realValue = realValue === 'true';
        }
        setCurrentValue(realValue);
      })
      .catch(() => {
        setCurrentValue(null);
      });
  }, []);

  function speak() {
    if (currentUrl) {
      dispatch(reduxOperations.audios.addAudio({
        id: uuidv4(),
        type: audioTypes.FILE,
        url: currentUrl,
      }));
    }
  }

  function handleSocketValue(socketData) {
    if (socketData.id === tile.valueId) {
      let realSocketValue = socketData.value;
      if (/^\d+$/.test(realSocketValue)) {
        realSocketValue = Number(realSocketValue);
      } else if (realSocketValue === 'true' || realSocketValue === 'false') {
        realSocketValue = realSocketValue === 'true';
      }
      if (tile.triggerType === 'ValueChangeTrigger') {
        if (tile.triggerValue === '' || tile.triggerValue == null) {
          if (currentValue !== realSocketValue) {
            speak();
          }
        } else if (tile.triggerValue === realSocketValue && currentValue !== realSocketValue) {
          speak();
        }
      } else if (tile.triggerType === 'ValueSetTrigger') {
        if (tile.triggerValue === '' || tile.triggerValue == null) {
          speak();
        } else if (tile.triggerValue === realSocketValue) {
          speak();
        }
      }
      setCurrentValue(realSocketValue);
    }
  }

  function handleSocketData(socketData) {
    const websocketData = JSON.parse(socketData);
    let newMedia = tile.url;
    if (tile.rules) {
      const rules = tile.rules.filter(rule => rule.type === 'media');
      for (const rule of rules) {
        if (evaluate(rule.condition, websocketData, rule.variableType)) {
          newMedia = rule.media;
          break;
        }
      }
    }
    setCurrentUrl(newMedia);
  }

  useEffect(() => {
    if (socket && tile.url && !isSocketInitialized) {
      socket.on('value', handleSocketValue);
      socket.on('data', handleSocketData);
      isSocketInitialized = true;
    }
    return () => {
      if (socket) {
        socket.removeListener('value', handleSocketValue);
        socket.removeListener('data', handleSocketData);
      }
    };
  }, [tile, socket, currentValue]);

  return (
    <div
      role="button"
      onClick={speak}
    >
      <FontAwesome
        icon="volume-up"
        size="5x"
      />
    </div>
  );
};

AudioFile.propTypes = {
  tile: reducersTypes.dashboards.tile,
};
AudioFile.defaultProps = {
  tile: {},
};

export { AudioFile };

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import { reduxOperations, reducersTypes } from '~services';
import API from '~services/endpoints';
import { getAlives } from '~services/alives/endpoints';
import { getSocket } from '~services/socket';
import { PageTemplate, DefaultTable } from '~components/Pages';
import { DeleteConfirmationForm } from '~components/Popups';
import {
  ModalHandler, ResourcesHandler, Cards,
  BasicCard, CreateCard, FontAwesome,
  SquaredAddButton,
} from '~UI';
import { configurationFeature, streamsFeature } from '~utils/featureToggles';
import { getLocalStorageObject, getLocalStorageSetter } from '~utils/localStorage';
import { getTagListFromUsedTags } from '~utils/tags';
import { round } from '~utils/math';
import { filterItems, sortItems } from '~components/SelectionGrid/utils';
import { StreamPopUpForm } from './StreamPopUpForm/StreamPopUpForm';
import { Page401Unauthorized } from '../../ErrorPages';
import directLinks from '~utils/directLinks';

const displayOptions = {
  CARD: 'card',
  LIST: 'list',
  ALL: 'all',
};

const propTypes = {
  storageType: PropTypes.shape({}).isRequired,
};

const StreamsSelectionGrid = (
  {
    streams, storageType, lastAlives,
  },
) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const socket = getSocket();
  const [filterText, setFilterText] = useState('');
  const updateLocalStorage = getLocalStorageSetter(storageType);
  const storage = getLocalStorageObject(storageType);
  const [display, setDisplay] = useState(storage.display || displayOptions.CARD);
  const [sortInput, setSortInput] = useState(storage.sortParameter || '-');
  const [selectedTags] = useState(storage.tags || ['all']);
  const [selectedStream, setSelectedStream] = useState('');
  const [showPopupForm, setShowPopupForm] = useState(false);
  const sortedItems = sortItems(streams, sortInput);
  let usedSelectedTags = getTagListFromUsedTags(sortedItems, selectedTags);
  usedSelectedTags = usedSelectedTags.length ? usedSelectedTags : ['all'];
  const filteredItems = filterItems(filterText, usedSelectedTags, sortedItems);
  const [properties, setProperties] = useState([]);
  const propertiesRef = useRef(properties);
  const pendingUpdates = useRef({});
  const isUpdating = useRef(false);

  function onSortChange(newSortInput) {
    updateLocalStorage('sortParameter', newSortInput);
    setSortInput(newSortInput);
  }

  function handleDisplay(newValue) {
    updateLocalStorage('display', newValue);
    setDisplay(newValue);
  }

  function handleOnClick(id) {
    setSelectedStream(streams.find(u => u.id === id));
    setShowPopupForm(true);
  }

  const handleSocketData = socketData => {
    if (!propertiesRef.current.find(property => property.id === socketData.id)) {
      return;
    }

    if (typeof socketData.value === 'boolean') {
      pendingUpdates.current[socketData.id] = socketData.value ? 'true' : 'false';
    } else if (typeof socketData.value === 'string') {
      pendingUpdates.current[socketData.id] = `"${socketData.value}"`;
    } else {
      pendingUpdates.current[socketData.id] = socketData.value !== null ? (round(socketData.value, 4) || 0) : 'NaN';
    }

    if (isUpdating.current) {
      return;
    }

    isUpdating.current = true;
    setTimeout(() => {
      setProperties(prevState => {
        const updatedProperties = prevState.map(property => {
          const update = pendingUpdates.current[property.id];
          return update !== undefined ? { ...property, value: update } : property;
        });
        pendingUpdates.current = {};
        isUpdating.current = false;

        return updatedProperties;
      });
    }, 500);
  };

  const getRSSIColor = RSSI => {
    if (!RSSI) return {};
    if (RSSI < -70) {
      return { color: 'red' };
    }
    if (RSSI < -55) {
      return { color: 'yellow' };
    }
    return { color: 'green' };
  };

  const fetchPropertiesValues = async propertiesToFetch => {
    const { values } = await API.getMultiplesValues(propertiesToFetch.map(property => property.id), {}, 1) || {};
    return propertiesToFetch.map(property => {
      const value = values?.find(val => val.valueId === property.id)?.value;
      if (typeof value === 'boolean') {
        return {
          ...property,
          value: value ? 'true' : 'false',
        };
      }
      if (typeof value === 'string') {
        return {
          ...property,
          value: `"${value}"`,
        };
      }
      return {
        ...property,
        value: value !== null ? (round(value, 4) || 0) : 'NaN',
      };
    });
  };

  useEffect(() => {
    async function fetchProperties() {
      const fetchedProperties = await fetchPropertiesValues(filteredItems.flatMap(stream => stream.properties.map(
        property => ({ ...property, stream: stream.name }),
      )));
      setProperties(fetchedProperties);
    }
    fetchProperties();

    socket?.on('value', handleSocketData);
    return () => {
      socket?.removeListener('value', handleSocketData);
    };
  }, [socket, selectedStream, streams]);

  useEffect(() => {
    propertiesRef.current = properties;
  }, [properties]);

  const renderContent = () => {
    if (display === displayOptions.CARD) {
      return (
        <Cards>
          {streamsFeature.isUserAllowedToConfigure() && (
            <CreateCard
              title={t('addStream')}
              modal={{
                Component: StreamPopUpForm,
                props: { isCreating: true },
              }}
            />
          )}
          {filteredItems
            .filter(stream => stream.name.toLowerCase().includes(filterText.toLowerCase()))
            .map(stream => (
              <BasicCard
                key={stream.id}
                text={lastAlives[stream.id] ? (
                  <>
                    <div>{DateTime.fromMillis(lastAlives[stream.id].timestamp).toRelative()}</div>
                    <div style={getRSSIColor(lastAlives[stream.id].RSSI)}>
                      {lastAlives[stream.id].RSSI && `${Math.round(lastAlives[stream.id].RSSI)} dBm`}
                    </div>
                  </>
                ) : '-'}
                title={stream.name}
                icon={<FontAwesome icon="microchip" size="4x" />}
                editModal={streamsFeature.isUserAllowedToConfigure() && {
                  Component: StreamPopUpForm,
                  props: { stream, streams },
                }}
                deleteModal={streamsFeature.isUserAllowedToConfigure() && {
                  Component: DeleteConfirmationForm,
                  props: { handleDelete: () => dispatch(reduxOperations.streams.deleteStream(stream.id)) },
                }}
                onClick={() => history.push(directLinks.inputs(stream.id, location.pathname))}
                style={{ cursor: 'pointer' }}
              />
            ))}
        </Cards>
      );
    }
    if (display === displayOptions.LIST) {
      return (
        <div>
          {
            showPopupForm && streamsFeature.isUserAllowedToConfigure() && (
              <StreamPopUpForm
                show={showPopupForm}
                stream={selectedStream}
                onHide={() => setShowPopupForm(false)}
              />
            )
          }
          <DefaultTable
            columnNames={[
              { name: t('name') },
            ]}
            entriesProperties={['name']}
            entries={filteredItems}
            {...(streamsFeature.isUserAllowedToConfigure() && {
              editFunction: id => handleOnClick(id),
              deleteFunction: stream => dispatch(reduxOperations.streams.deleteStream(stream)),
            })}
            onClickEvent={id => history.push(directLinks.inputs(id, location.pathname))}
          />
        </div>
      );
    }
    if (display === displayOptions.ALL) {
      const shownPropertiesIds = filteredItems.flatMap(stream => stream.properties.map(property => property.id));
      const entries = properties.filter(property => shownPropertiesIds.includes(property.id));
      return (
        <div className="InputsPageList">
          <DefaultTable
            columnNames={[
              { name: t('stream') },
              { name: t('name') },
              { name: t('variable') },
              { name: t('normalize') },
              { name: t('realTimeValue') },
              { name: t('units') },
            ]}
            const
            entriesProperties={[
              'stream',
              'name',
              'variable',
              'lerp',
              'value',
              'units',
            ]}
            entriesStyle={[
              { property: 'stream' },
              { property: 'name' },
              { property: 'variable' },
              { property: 'lerp' },
              { property: 'value' },
              { property: 'units' },
            ]}
            entries={entries}
          />
        </div>
      );
    }
  };

  return (
    <div>
      <div className="SearchBar">
        <div>
          {`${t('Search')}:`}
          <Input
            clearable
            onChange={value => setFilterText(value)}
            onClear={() => setFilterText('')}
            placeholder={t('streamName')}
            style={{
              display: 'inline-block',
              margin: '0 5px',
              width: 250,
            }}
            value={filterText}
          />
          {`${t('sortBy')}:`}
          <Select
            style={{ display: 'inline-block', width: '300px' }}
            className="sortInput inputFilter"
            value={sortInput}
            onChange={e => onSortChange(e)}
            options={[
              { label: '-', value: '-' },
              { label: t('name'), value: 'byName' },
            ]}
          />
        </div>
        <div className="displayIconContainer">
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.CARD)}
          >
            <FontAwesome icon="th" />
          </div>
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.LIST)}
          >
            <FontAwesome icon="list-ul" />
          </div>
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.ALL)}
          >
            {t('all')}
          </div>
          {
            display === displayOptions.LIST && streamsFeature.isUserAllowedToConfigure() && (
              <ModalHandler
                Trigger={{
                  Component: SquaredAddButton,
                  props: {},
                }}
                Modal={{
                  Component: StreamPopUpForm,
                  props: { isCreating: true },
                }}
              />
            )
          }
        </div>
      </div>
      <div>
        {renderContent()}
      </div>
    </div>
  );
};

StreamsSelectionGrid.propTypes = {
  lastAlives: PropTypes.shape({ id: PropTypes.number }).isRequired,
  storageType: PropTypes.shape({}).isRequired,
  streams: reducersTypes.streams.isRequired,
};

const StreamSelectionPage = ({ storageType }) => {
  const socket = getSocket();
  const dispatch = useDispatch();

  const actions = useSelector(state => state.actions);
  const language = useSelector(state => state.views.language);
  const machines = useSelector(state => state.machines);
  const streams = useSelector(state => state.streams);
  const triggers = useSelector(state => state.triggers);
  const variables = useSelector(state => state.variables);

  const [lastAlives, setLastAlives] = useState({});

  const fetchAlives = async streamOptions => {
    if (streamOptions) {
      const lastAlivePerStream = {};
      for (let i = 0; i < streamOptions.length; i += 1) {
        const alives = await getAlives(streamOptions[i].id, {}, { timestamp: -1 }, 1);
        lastAlivePerStream[streamOptions[i].id] = alives.alives.length ? alives.alives[0] : null;
      }
      setLastAlives(lastAlivePerStream);
    }
  };

  const handleSocketAlive = newAlive => {
    setLastAlives(prevAlives => ({ ...prevAlives, [newAlive.streamId]: newAlive }));
  };

  useEffect(() => {
    if (socket) {
      socket.on('alive', handleSocketAlive);
    }
    if (streams) {
      fetchAlives(streams);
    }

    return () => {
      if (socket) {
        socket.removeListener('alive', handleSocketAlive);
      }
    };
  }, [socket, streams]);

  const getContents = () => (
    configurationFeature.isUserAllowedAccessStreams() ? (
      <PageTemplate
        sidebar
      >
        <StreamsSelectionGrid
          language={language}
          streams={streams}
          storageType={storageType}
          lastAlives={lastAlives}
        />
      </PageTemplate>
    ) : (
      <Page401Unauthorized />
    )
  );

  return (
    <ResourcesHandler
      resources={[streams, triggers, actions, variables, machines]}
      resourceFetchers={[
        () => dispatch(reduxOperations.actions.fetchActions()),
        () => dispatch(reduxOperations.machines.fetchMachines()),
        () => dispatch(reduxOperations.streams.fetchStreams()),
        () => dispatch(reduxOperations.triggers.fetchTriggers()),
        () => dispatch(reduxOperations.variables.fetchVariables()),
      ]}
      getContents={getContents}
      pageId="StreamSelectionPage"
    />
  );
};

StreamSelectionPage.propTypes = propTypes;

export default withRouter(StreamSelectionPage);

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { useShift } from '~utils/hooks';
import { getDataRangeStartEnd } from '~utils/time';
import { round } from '~utils/math';
import CircularChart from './CircularChartSVG';

const CircularChartEvents = ({ selectedObject, isTile, dimension, textColor }) => {
  const socket = getSocket();

  const [events, setEvents] = useState([]);
  const [currentValue, setCurrentValue] = useState(null);
  const [currentShift] = useShift(selectedObject.machineId);
  const [goal, setGoal] = useState(selectedObject.goal);
  const idleTimerRef = useRef(null);

  const fetchEvents = async () => {
    const intervalType = selectedObject.intervalType || 'shift';
    const { start, end } = getDataRangeStartEnd(intervalType, currentShift);

    const filter = {
      name: selectedObject.currentEvent,
      timestamp: {
        $gte: start,
        $lt: end,
      },
    };
    const { events: newEvents } = await API.getEvents(filter);

    setEvents(newEvents);
  };

  const handleSocketEvent = socketEvent => {
    if (socketEvent.name === selectedObject.currentEvent) {
      setEvents(prevEvents => [...prevEvents, socketEvent]);
    }
    if (selectedObject.objectiveType === 'variable' && socketEvent.id === selectedObject.goal) {
      const goalToSet = round(socketEvent.value, 2);
      if (goalToSet >= selectedObject.intervalMin && goalToSet <= selectedObject.intervalMax) {
        setGoal(goalToSet);
        return;
      }
      setGoal(-1);
    }
  };

  useEffect(() => {
    setCurrentValue(events.length);
  }, [events]);

  useEffect(() => {
    const listener = data => handleSocketEvent(data);
    socket.on('event', listener);
    socket.on('value', listener);
    fetchEvents();

    if (selectedObject.objectiveType === 'variable' && selectedObject.goal) {
      API.getValues(selectedObject.goal, {}, 1).then(response => {
        const goalToSet = response?.values[0] && round(response.values[0].value, 2);
        if (goalToSet >= selectedObject.intervalMin && goalToSet <= selectedObject.intervalMax) {
          setGoal(goalToSet);
          return;
        }
        setGoal(-1);
      });
    } else {
      setGoal(selectedObject.goal);
    }

    return () => {
      if (socket) {
        socket.removeListener('event', listener);
        socket.removeListener('value', listener);
      }
    };
  }, [socket, selectedObject, currentShift]);

  const handleOnActive = () => {
    fetchEvents();
  };

  const handleOnIdle = () => {
    fetchEvents();
    idleTimerRef.current.reset();
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <CircularChart
        currentValue={currentValue}
        dimension={dimension}
        textColor={textColor}
        interval={{ min: selectedObject.intervalMin, max: selectedObject.intervalMax }}
        metric="unit"
        showGoalToggle={
          typeof selectedObject.showGoalToggle === 'boolean'
            ? selectedObject.showGoalToggle
            : typeof selectedObject.goal === 'number'
        } // for backward compatibility with no toggle
        goal={goal}
        isTile={isTile}
        title={selectedObject.title}
        aboveGoalColor={selectedObject.aboveGoalColor}
        belowGoalColor={selectedObject.belowGoalColor}
      />
    </>
  );
};

CircularChartEvents.propTypes = {
  selectedObject: PropTypes.shape({
    showGoalToggle: PropTypes.bool,
    objectiveType: PropTypes.string,
    goal: PropTypes.number,
    id: PropTypes.string,
    machineId: PropTypes.string,
    title: PropTypes.string,
    valueType: PropTypes.string,
    currentEvent: PropTypes.string,
    intervalType: PropTypes.string,
    intervalMin: PropTypes.number,
    intervalMax: PropTypes.number,
    aboveGoalColor: PropTypes.string,
    belowGoalColor: PropTypes.string,
  }).isRequired,
  isTile: PropTypes.bool,
  dimension: PropTypes.object.isRequired,
  textColor: PropTypes.string.isRequired,
};
CircularChartEvents.defaultProps = {
  isTile: false,
};

export default CircularChartEvents;

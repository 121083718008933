/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { CheckboxToggle, FontAwesome } from '~components/UI';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { sortArray } from '~utils/sort';
import IntervalTimePicker from '~UI/IntervalTimePicker/IntervalTimePicker';
import { RootState } from '~services/store';
import { StopCause, ParentStopCause } from '../../pages/Config/Machines/stopCauses/StopCause.interface';

const FormParetoChart = ({ selectedTile }) => {
  const { t } = useTranslation();
  const settings = useSelector((state: RootState) => state.settings.settings);
  const language = useSelector((state: RootState) => state.views.language);

  const machines = useSelector((state: RootState) => state.machines);
  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));
  const [intervalType, setIntervalType] = useState(selectedTile.intervalType);

  const [showArrows, setShowArrows] = useState(selectedTile.showArrows);
  const [nbDisplayedRows, setNbDisplayedRows] = useState(selectedTile.nbDisplayedRows);

  function handleShowArrows() {
    setShowArrows(!showArrows);
  }
  const [machineId, setMachineId] = useState(selectedTile.machineId);
  const [stopCausesState, setStopCausesState] = useState<StopCause[]>([]);
  const [hiddenStopCausesState, setHiddenStopCausesState] = useState<string[]>([]);
  const [isSubMenuState, setIsSubMenuState] = useState(false);
  const [menuStack, setMenuStack] = useState<any>([]);

  useEffect(() => {
    const selectedMachine = machines.find(m => m.id === machineId);
    setHiddenStopCausesState(selectedTile.machineId === machineId ? selectedTile.hiddenStopCauses : []);
    setStopCausesState(selectedMachine && selectedMachine.stopCauses);
    setIsSubMenuState(false);
  }, [machineId]);

  const handleStopCauseSelection = stopCause => {
    const selectedMachine = machines.find(m => m.id === machineId);
    if (!stopCause) {
      setIsSubMenuState(false);
      setStopCausesState(selectedMachine?.stopCauses);
      setMenuStack([]);
    } else if (stopCause?.subMenu?.length) {
      setIsSubMenuState(true);
      setMenuStack([...menuStack, stopCausesState]);
      setStopCausesState(stopCause.subMenu);
    } else if (hiddenStopCausesState?.includes(stopCause.id)) {
      setHiddenStopCausesState(hiddenStopCausesState.filter(id => id !== stopCause.id));
    } else {
      setHiddenStopCausesState([...hiddenStopCausesState, stopCause.id]);
    }
  };

  const handleBackButton = () => {
    if (menuStack.length > 0) {
      const newMenuStack = [...menuStack];
      const previousMenu = newMenuStack.pop();
      setMenuStack(newMenuStack);
      setStopCausesState(previousMenu);
      setIsSubMenuState(newMenuStack.length > 0);
    }
  };

  return (
    <div>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        defaultValue={machineId}
        onChange={e => setMachineId(e)}
        placeholder={t('select')}
      />
      <div className="inputTitle">{t('dataCollectionPeriod')}</div>
      <IntervalTimePicker
        intervalType={intervalType}
        intervalTypeInput
        changeIntervalType={value => setIntervalType(value)}
      />
      <div className="inputTitle">
        {t('nbDisplayedRows')}
      </div>
      <input
        type="number"
        name="nbDisplayedRows"
        min="1"
        step="1"
        defaultValue={nbDisplayedRows || 1}
        onChange={e => setNbDisplayedRows(e.target.value)}
      />
      <div className="inputTitle">
        {t('showTimeNavigationArrows')}
        &nbsp;
        <CheckboxToggle
          name="showArrows"
          controlledCheck={showArrows}
          onChange={handleShowArrows}
        />
      </div>
      { machineId && (
        <div className="inputTitle">
          {t('stopCausesShown')}
        </div>
      )}
      <input type="hidden" name="array:hiddenStopCauses" value={hiddenStopCausesState} />
      {
        isSubMenuState && (
          <FontAwesome
            icon="arrow-left"
            className="backButtonArrow"
            style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '8px', fontSize: '17px' }}
            onClick={handleBackButton}
          />
        )
      }
      {
        !isSubMenuState && machineId && (
          <StopCauseIcon
            key="unknown"
            option={{
              name: t('unknown'),
              icon: 'Interrogation',
              color: settings.defaultUnfilledStopCauseColor || '#444',
            }}
            small
            className={(hiddenStopCausesState?.includes('unknown')) ? 'Unselected' : 'Selected'}
            onClick={() => handleStopCauseSelection({ id: 'unknown' })}
          />
        )
      }
      {
        stopCausesState && stopCausesState.map(stopcause => {
          let stopCauseName;
          switch (language) {
            case 'en':
              stopCauseName = stopcause.nameEN || stopcause.name;
              break;
            case 'fr':
              stopCauseName = stopcause.nameFR || stopcause.name;
              break;
            case 'es':
              stopCauseName = stopcause.nameES || stopcause.name;
              break;
            default:
              stopCauseName = stopcause.name;
          }

          return (
            <StopCauseIcon
              key={stopcause.id}
              option={{
                ...stopcause,
                name: stopCauseName,
                badgeCount: (stopcause as ParentStopCause).subMenu?.length,
              }}
              small
              className={(hiddenStopCausesState?.includes(stopcause.id)) ? 'Unselected' : 'Selected'}
              onClick={() => handleStopCauseSelection(stopcause)}
            />
          );
        })
      }
    </div>
  );
};

FormParetoChart.propTypes = {
  selectedTile: PropTypes.object,
};
FormParetoChart.defaultProps = {
  selectedTile: {},
};

export { FormParetoChart };

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import {
  BLUE,
  DARK_GRAY,
  PALE_GRAY,
} from '../constants';

import '../Charts.scss';

const ANGLE_RANGE = Math.PI * 0.5;
const LINE_SIZE = 0.01;
const HEIGHT_CHART_PADDING = 45;
const OBJECTIVE_LABEL_PADDING = 0.05;
const PERCENTAGE_HEIGHT_OUTER_RADIUS = 0.85;
const PERCENTAGE_HEIGHT_INNER_RADIUS = 0.45;

const Gauge = ({
  currentValue,
  showGoalToggle,
  goal,
  aboveGoalColor,
  belowGoalColor,
  dimension,
  textColor,
  interval,
  metric,
  units,
}) => {
  const node = useRef(null);

  const getFormattedValue = value => {
    if (value === null) {
      return '-';
    }

    switch (metric) {
      case 'percentage':
        return d3.format('.1%')(value);
      case 'unit':
        return units ? `${value} ${units}` : value;
      default:
        return '-';
    }
  };

  const createGauge = () => {
    const { height, width } = dimension;
    const halfWidth = width / 2;
    const heightChart = Math.min(height * 0.7, width / 2);

    // Remove everything inside the div
    d3.select(node.current).selectAll('*').remove();

    // SVG for the graph
    const svg = d3.select(node.current)
      .classed('nocursorpointer', true)
      .attr('width', width)
      .attr('height', heightChart + HEIGHT_CHART_PADDING);

    const innerRadius = heightChart * PERCENTAGE_HEIGHT_INNER_RADIUS;
    const outerRadius = heightChart * PERCENTAGE_HEIGHT_OUTER_RADIUS;
    const pie = d3.pie()
      .sort(null)
      .value(d => d.value)
      .startAngle(ANGLE_RANGE)
      .endAngle(-ANGLE_RANGE);

    const currentPercentage = interval ? (currentValue - interval.min) / (interval.max - interval.min) : 0;
    let color = BLUE;
    if (showGoalToggle) {
      color = currentValue >= goal ? aboveGoalColor : belowGoalColor;
    }
    const currentData = [
      { value: interval ? 1 - currentPercentage : 1 - currentValue, color: PALE_GRAY },
      { value: interval ? currentPercentage : currentValue, color },
    ];
    const current = pie(currentData);
    const arc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);
    svg.append('g')
      .attr('transform', `translate(${halfWidth},${heightChart})`)
      .selectAll('path')
      .data(current)
      .join('path')
      .attr('fill', d => d.data.color)
      .attr('d', arc);

    // Goal line
    if (showGoalToggle && goal >= 0) {
      const goalPercentage = interval ? ((goal - interval.min) / (interval.max - interval.min)) : goal;
      const goalLineMiddle = goalPercentage - (LINE_SIZE / 2);
      const goalData = [
        { value: 1 - goalLineMiddle - LINE_SIZE, color: 'transparent' },
        { value: LINE_SIZE, color: DARK_GRAY },
        { value: goalLineMiddle, color: 'transparent' },
      ];
      const goalLine = pie(goalData);
      const goalArc = d3.arc()
        .innerRadius(innerRadius + 3)
        .outerRadius(outerRadius - 3)
        .cornerRadius(10);
      svg.append('g')
        .attr('transform', `translate(${halfWidth},${heightChart})`)
        .selectAll('path')
        .data(goalLine)
        .join('path')
        .attr('fill', d => d.data.color)
        .attr('d', goalArc);

      // Goal value
      const goalLineRadAngle = goalPercentage * Math.PI;
      const labelPosX = halfWidth - ((outerRadius * (1 + OBJECTIVE_LABEL_PADDING)) * Math.cos(goalLineRadAngle));
      const labelPosY = heightChart - ((outerRadius * (1 + OBJECTIVE_LABEL_PADDING)) * Math.sin(goalLineRadAngle));

      svg.append('text')
        .attr('transform', `translate(${labelPosX},${labelPosY}) rotate(${((goalLineRadAngle * 180) / Math.PI) - 90})`)
        .attr('font-size', `${innerRadius / 6}px`)
        .attr('fill', textColor)
        .text(getFormattedValue(goal));
    }

    // Current value
    svg.append('text')
      .attr('transform', `translate(${halfWidth},${heightChart - innerRadius / 3})`)
      .attr('font-size', units ? `${innerRadius / 2.2}px` : `${innerRadius / 2}px`)
      .attr('fill', textColor)
      .text(getFormattedValue(currentValue));

    // Axis Numbers
    const diffSpace = innerRadius + ((outerRadius - innerRadius) / 2);
    const textPaddingBot = heightChart - heightChart * 0.02;
    svg.append('text')
      .attr('transform', `translate(${halfWidth - diffSpace},${textPaddingBot})`)
      .attr('font-size', `${innerRadius / 8}px`)
      .attr('fill', textColor)
      .text(interval ? interval.min : '0%');
    svg.append('text')
      .attr('transform', `translate(${halfWidth + diffSpace},${textPaddingBot})`)
      .attr('font-size', `${innerRadius / 8}px`)
      .attr('fill', textColor)
      .text(interval ? interval.max : '100%');
  };

  useEffect(() => {
    createGauge();
  }, []);

  useEffect(() => {
    createGauge();
  }, [dimension, textColor, currentValue, units, showGoalToggle, goal, interval]);

  return (
    <svg
      className="centerText"
      ref={node}
      height={dimension.height}
      width={dimension.width}
      x={dimension.x}
      y={dimension.y}
    />
  );
};

Gauge.propTypes = {
  currentValue: PropTypes.number,
  showGoalToggle: PropTypes.bool.isRequired,
  aboveGoalColor: PropTypes.string,
  belowGoalColor: PropTypes.string,
  goal: PropTypes.number,
  dimension: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  textColor: PropTypes.string.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  interval: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  metric: PropTypes.string,
  units: PropTypes.string,
};
Gauge.defaultProps = {
  currentValue: 0,
  goal: 0,
  aboveGoalColor: BLUE,
  belowGoalColor: BLUE,
  metric: '',
  units: '',
  interval: null,
  x: null,
  y: null,
};

export {
  Gauge,
  PERCENTAGE_HEIGHT_OUTER_RADIUS,
};

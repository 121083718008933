import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Loader, useTheme } from '@intelligenceindustrielle/react-ui-components';
import i18n from './i18n';
import { initGoogleAnalytics } from '~utils/googleAnalytics';
import { showError } from '~utils/toast';
import { reduxOperations, reducersTypes } from './services';
import { subscribeUser } from './subscription';
import { initializeSocket } from './services/socket';
import { buildLinkRedirectionTargetURL, isRedirected } from './utils/LinkRedirection';
import useLoginRefresh from './utils/useLoginRefresh';
import Tick from './utils/Tick';
import Routes from './Routes';
import LoginPage from './pages/Login/LoginPage';
import SignupPage from './pages/Login/SignUpPage';
import VerifyAccount from './pages/Login/VerifyAccount';
import PageTemplate from './components/Pages/PageTemplate/PageTemplate';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

const LOGIN_REFRESH_PERIOD = 5 * 60 * 1000; // 5 minutes

const history = createBrowserHistory();

const propTypes = {
  connexion: reducersTypes.session.connexion.isRequired,
  targetURL: reducersTypes.linkRedirection.targetURL,
  featureToggles: reducersTypes.featureToggles.featureToggles,
  login: PropTypes.func.isRequired,
  fetchFeatureToggles: PropTypes.func.isRequired,
  fetchSettings: PropTypes.func.isRequired,
  fetchUserPermissions: PropTypes.func.isRequired,
  fetchRedirectedDashboards: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  getLinkRedirection: PropTypes.func.isRequired,
  setTheme: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  commands: reducersTypes.commands.commandsPropTypes.isRequired,
  commandsUndo: PropTypes.func.isRequired,
  commandsUndoFail: PropTypes.func.isRequired,
  commandsRedo: PropTypes.func.isRequired,
  commandsRedoFail: PropTypes.func.isRequired,
  isSignup: PropTypes.bool.isRequired,
  isVerification: PropTypes.bool.isRequired,
  selectedUser: reducersTypes.users.selectedUser.isRequired,
  updateIsVerification: PropTypes.func.isRequired,
};

const defaultProps = {
  featureToggles: null,
  targetURL: null,
};

function App(props: any): any {
  const mounted: any = useRef();
  const {
    commands,
    commandsRedo,
    commandsRedoFail,
    commandsUndo,
    commandsUndoFail,
    connexion,
    featureToggles,
    fetchFeatureToggles,
    fetchSettings,
    fetchRedirectedDashboards,
    fetchUserPermissions,
    getLinkRedirection,
    isSignup,
    isVerification,
    login,
    selectedUser,
    setLanguage,
    setTheme,
    targetURL,
    theme,
    updateIsVerification,
  } = props;

  useTheme('dark');
  useLoginRefresh(LOGIN_REFRESH_PERIOD);
  const { t } = useTranslation();

  const handleCTRL = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key.toLowerCase() === 'z' && !e.shiftKey) {
      if (commands.pastActions.length) {
        const lastCommand = commands.pastActions[commands.pastActions.length - 1];
        const undoCommand = lastCommand.undoAction;
        undoCommand.action(...undoCommand.params).then(() => {
          commandsUndo();
        }).catch(() => {
          commandsUndoFail();
          showError(t('cannotUndo'));
        });
      } else {
        // showError(t('nothingUndo'));
      }
    } else if ((e.ctrlKey && e.key.toLowerCase() === 'y') || (e.ctrlKey && e.key.toLowerCase() === 'z' && e.shiftKey)) {
      if (commands.futureActions.length) {
        const lastCommand = commands.futureActions[commands.futureActions.length - 1];
        const redoCommand = lastCommand.redoAction;
        redoCommand.action(...redoCommand.params).then(() => {
          commandsRedo();
        }).catch(() => {
          commandsRedoFail();
          showError(t('cannotRedo'));
        });
      } else {
        // showError(t('nothingRedo'));
      }
    }
  };

  useEffect(() => {
    if (!featureToggles) {
      fetchFeatureToggles();
    }
    if (!mounted.current) {
      mounted.current = true;
      if (window.location.pathname.startsWith('/redirect/')) {
        getLinkRedirection(window.location.pathname.split('/').pop());
      } else {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        login(null, null, false, null, code)
          .then((res: any) => {
            const { infos, loggedin } = res.payload;
            const { id, language: lang } = infos;
            if (loggedin) {
              fetchSettings();
              initGoogleAnalytics();
              let storedLanguage = localStorage.getItem('i18nextLng') || lang || 'fr';
              if (storedLanguage === 'es' && !isRedirected(id)) {
                i18n.changeLanguage('fr');
                storedLanguage = 'fr';
              }
              setLanguage(storedLanguage);
              // TODO:THEME put back the commented code when light theme is finished
              // setTheme(localStorage.getItem('theme'));
              setTheme('Dark');
              fetchUserPermissions(id);
              initializeSocket();
            }
          });
      }
      Tick.start();
      document.addEventListener('keydown', handleCTRL);
    }
    return function cleanup() {
      Tick.stop();
      document.removeEventListener('keydown', handleCTRL);
    };
  }, []);

  const userNotConnectedRouter = () => {
    if (isSignup && !connexion.loggedin) {
      return (
        <Switch>
          <Route path="/signup" component={SignupPage} />
        </Switch>
      );
    }
    if (isVerification && !connexion.loggedin) {
      return (
        <Switch>
          <Route path="/users/:id/verify" component={VerifyAccount} />
        </Switch>
      );
    }
    return (
      <Switch>
        <Route path="*" component={LoginPage} />
      </Switch>
    );
  };

  const manageRedirection = () => {
    if (targetURL !== null && window.location.pathname.startsWith('/redirect/')) {
      history.replace(targetURL);
      const elementId = targetURL.split('/').pop();
      login(null, null, true, elementId)
        .then((res: any) => {
          if (res.payload.loggedin) {
            const { contentType, contentId } = res.payload;
            fetchSettings();
            initGoogleAnalytics();
            fetchUserPermissions(`Redirected.${buildLinkRedirectionTargetURL(contentType, contentId)}`);
            setLanguage(localStorage.getItem('i18nextLng') || 'fr');
            initializeSocket();
            fetchRedirectedDashboards();
          }
        });
    }
    return (
      <Switch>
        <Route path="*" component={Loader} />
      </Switch>
    );
  };

  const permissionsNotLoadedRouter = () => (
    <Switch>
      <Route path="*" component={PageTemplate} />
    </Switch>
  );

  const fullRouter = () => <Routes />;

  const pathIsAllowedForRedirect = (pathName: string) => (pathName.startsWith('/dashboard/') || pathName.startsWith('/topview/'));

  let router: any = null;
  let currentTheme = theme;
  const pathIsRedirected = window.location.pathname.startsWith('/redirect/');
  if (window.location.pathname.split('/').pop() === 'verify') {
    updateIsVerification(true);
  }
  if (pathIsRedirected) {
    router = manageRedirection();
  } else if (!connexion.loggedin) {
    currentTheme = localStorage.getItem('theme');
    router = userNotConnectedRouter();
  } else if (!selectedUser) {
    router = permissionsNotLoadedRouter();
  } else if ((connexion.infos && connexion.infos.id === 'Redirected') && !pathIsAllowedForRedirect(window.location.pathname)) {
    router = userNotConnectedRouter();
  } else {
    if (connexion.infos.id !== 'Redirected') {
      subscribeUser(connexion.infos.id);
    }
    router = fullRouter();
  }

  return (
    <div className={`theme-${currentTheme}`}>
      <div id="App">
        <ToastContainer
          closeOnClick
          hideProgressBar
          autoClose={2000}
          position="top-center"
        />
        <Router history={history}>
          {router}
        </Router>
      </div>
    </div>
  );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

function mapStateToProps(state: any) {
  return {
    connexion: state.session.connexion,
    featureToggles: state.featureToggles.featureToggles,
    selectedUser: state.users.selectedUser,
    theme: state.session.theme,
    isSignup: state.session.isSignup,
    isVerification: state.session.isVerification,
    targetURL: state.linkRedirection.targetURL,
    commands: state.commands,
    language: state.views.language,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    login: reduxOperations.session.login,
    logout: reduxOperations.session.logout,
    fetchSettings: reduxOperations.settings.fetchSettings,
    getLinkRedirection: reduxOperations.linkRedirection.getLinkRedirection,
    setLanguage: reduxOperations.views.setLanguage,
    setTheme: reduxOperations.session.setTheme,
    fetchFeatureToggles: reduxOperations.featureToggles.fetchFeatureToggles,
    fetchUserPermissions: reduxOperations.users.fetchUserPermissions,
    updateIsVerification: reduxOperations.session.updateIsVerification,
    fetchRedirectedDashboards: reduxOperations.dashboards.fetchRedirectedDashboards,
    commandsSave: reduxOperations.commands.commandsSave,
    commandsUndo: reduxOperations.commands.commandsUndo,
    commandsUndoFail: reduxOperations.commands.commandsUndoFail,
    commandsRedo: reduxOperations.commands.commandsRedo,
    commandsRedoFail: reduxOperations.commands.commandsRedoFail,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { AlphaPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { ColorPicker, Select } from '@intelligenceindustrielle/react-ui-components';
import {
  DeleteButton, SubmitButton, TextInput, FileUpload,
} from '~components/UI';
import { reducersTypes, reduxOperations } from '~services/index';
import API from '~services/endpoints';
import { rgbaToHex } from '~utils/index';
import { splitCondition, idToReadableExpr, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import { ruleTypes } from '~utils/types';
import { showUpload } from '~utils/toast';
import { RootState } from '~services/store';

import './RuleForm.scss';

const RuleForm = ({
  stopwatches, type, currentRule, dropDownList, deleteRule, updateOrCreateRule, duplicateRule, tileWidgetType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const streams = useSelector((state: RootState) => state.streams);
  const variables = useSelector((state: RootState) => state.variables);
  const triggers = useSelector((state: RootState) => state.triggers);
  const machines = useSelector((state: RootState) => state.machines);
  const images = useSelector((state: RootState) => state.images.images);

  const ruleCondition = currentRule ? splitCondition(currentRule.condition) : null;
  const variableOptions = dropDownList.map(ivs => ({ label: ivs.name, value: `\${${ivs.id}}` }));

  const [lastRuleId, setLastRuleId] = useState(currentRule && currentRule.id);
  const [selectedColor, setSelectedColor] = useState(currentRule ? currentRule.color : '#4C4C4C');
  const [selectedContentColor, setSelectedContentColor] = useState(currentRule?.contentColor);
  const [selectedTrigger, setSelectedTrigger] = useState(currentRule && currentRule[type] ? currentRule[type] : '');
  const [textInput, setTextInput] = useState(currentRule && currentRule[type] ? currentRule[type] : '');
  const [isBool, setIsBool] = useState(false);
  const [isString, setIsString] = useState(false);
  const [variable, setVariable] = useState(ruleCondition ? ruleCondition.variable : null);
  const [valueToCompare, setValueToCompare] = useState<number | boolean | string | undefined>(
    ruleCondition ? ruleCondition.valueToCompare : undefined,
  );
  const [comparator, setComparator] = useState(ruleCondition ? ruleCondition.comparator : '>');

  useEffect(() => {
    if (variable) {
      const stopwatch = stopwatches.find(sw => sw.id === variable);
      if (!stopwatch) {
        API.getValues(variable, {}, 1).then(res => {
          const value = res.values.length ? res.values[0].value : undefined;
          setIsBool(typeof value === 'boolean');
          setIsString(typeof value === 'string');
        });
      }
    }
  }, []);

  const handleColorSelection = hex => {
    setSelectedColor(hex);
  };

  const handleContentColorSelection = hex => {
    setSelectedContentColor(hex);
  };

  useEffect(() => {
    const newRuleCondition = currentRule ? splitCondition(currentRule.condition) : null;
    setLastRuleId((currentRule && currentRule.id) || null);
    setSelectedColor(currentRule ? currentRule.color : '#fff');
    setSelectedContentColor(currentRule?.contentColor);
    setSelectedTrigger(currentRule && currentRule[type] ? currentRule[type] : '');
    setTextInput(currentRule && currentRule[type] ? currentRule[type] : '');
    setVariable(newRuleCondition ? newRuleCondition.variable : null);
    setValueToCompare(newRuleCondition ? newRuleCondition.valueToCompare : undefined);
    setComparator(newRuleCondition ? newRuleCondition.comparator : '>');

    if (newRuleCondition && newRuleCondition.variable) {
      const stopwatch = stopwatches.find(sw => sw.id === newRuleCondition.variable);
      if (!stopwatch) {
        API.getValues(newRuleCondition.variable, {}, 1).then(res => {
          const value = res.values.length ? res.values[0].value : undefined;
          setIsBool(typeof value === 'boolean');
          setIsString(typeof value === 'string');
        });
      }
    }
  }, [currentRule]);

  useEffect(() => {
    if (isString && (comparator === '>' || comparator === '>=' || comparator === '<=' || comparator === '<')) {
      setComparator('==');
    }
  }, [isString, comparator]);

  const onTextInputChange = text => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];
    let id = text;
    try {
      id = variableToId(text, inputsAndVariables);
    } catch (error) {
      // TODO: Error handling of non existent variable is not made and should be done
    }
    setTextInput(id);
  };

  const handleAlphaChange = e => {
    setSelectedColor(rgbaToHex(e.rgb));
  };

  const handleAlphaChangeContent = e => {
    setSelectedContentColor(rgbaToHex(e.rgb));
  };

  const handleVariableChange = e => {
    const newVariable = e
      .replace('${', '')
      .replace('}', '');

    if (newVariable) {
      const stopwatch = stopwatches.find(sw => sw.id === newVariable);
      if (!stopwatch) {
        API.getValues(newVariable, {}, 1).then(res => {
          const value = res.values.length ? res.values[0].value : undefined;
          setIsBool(typeof value === 'boolean');
          setIsString(typeof value === 'string');
        });
      }
    }
    setVariable(newVariable);
  };

  const valueToCompareInput = () => ((isBool && (
    <Select
      name="bool:valueToCompare"
      className="boolInput"
      value={valueToCompare?.toString() || 'true'}
      onChange={setValueToCompare}
      options={[{ label: 'True', value: 'true' }, { label: 'False', value: 'false' }]}
    />
  )) || (isString && (
    <TextInput
      options={[]}
      className="textInput"
      name="string:valueToCompare"
      value={valueToCompare as string}
      onChange={setValueToCompare}
    />
  ))
    || (
      <input
        type="number"
        step="0.01"
        name="number:valueToCompare"
        value={valueToCompare as number}
        onChange={e => setValueToCompare(parseFloat(e.target.value))}
        key={`valueToCompare:${variable}`}
        className="numberInput"
      />
    ));

  const idToReadable = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];
    return idToReadableExpr(value, inputsAndVariables);
  };

  const handleFileSelect = selectedFile => {
    showUpload(
      reduxOperations.images.addImage(selectedFile)(dispatch),
      t('uploadingImage'),
      t('showSuccessAdded'),
    )
      .then(res => {
        if (typeof res.payload.data === 'string') {
          setTextInput(res.payload.data);
        }
      });
  };

  const getContentType = () => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable').map(x => x.variable);
    inputsAndVariables.splice(0, 0, 'NOW');

    switch (type) {
      case ruleTypes.COLOR:
        return (
          <>
            <h4>{t('backgroundColor')}</h4>
            <ColorPicker
              onChange={handleColorSelection}
              value={selectedColor}
            />
            <div style={{ marginTop: '10px' }}>
              <h5>{t('opacity')}</h5>
              <AlphaPicker
                width="230"
                color={selectedColor}
                onChange={handleAlphaChange}
              />
            </div>
            {tileWidgetType === 'button' && (
              <>
                <br />
                <h4>{t('buttonColor')}</h4>
                <ColorPicker
                  onChange={handleContentColorSelection}
                  value={selectedContentColor}
                />
                <div style={{ marginTop: '10px' }}>
                  <h5>{t('opacity')}</h5>
                  <AlphaPicker
                    width="230"
                    color={selectedContentColor}
                    onChange={handleAlphaChangeContent}
                  />
                </div>
              </>
            )}
          </>
        );
      case ruleTypes.TEXT:
        return (
          <>
            <TextInput
              options={inputsAndVariables}
              trigger="$"
              value={textInput ? idToReadable(textInput) : null}
              className="fullwidth"
              onChange={onTextInputChange}
              placeholder={t('triggerVariableList')}
              Component="textarea"
            />
            <input
              type="hidden"
              value={textInput}
              name="text"
            />
          </>
        );
      case ruleTypes.MEDIA:
        return (
          <div className="mediaFileContainer">
            <TextInput
              options={inputsAndVariables}
              trigger="$"
              className="fullwidth"
              value={textInput ? idToReadable(textInput) : null}
              onChange={onTextInputChange}
              placeholder={t('triggerVariableList')}
              style={{ marginRight: '10px', flexGrow: 1 }}
            />
            <FileUpload
              onFileSelect={handleFileSelect}
              images={images}
              image={textInput}
              setImage={setTextInput}
            />
            <input
              type="hidden"
              value={textInput}
              name="media"
            />
          </div>
        );
      case ruleTypes.TRIGGER:
        return (
          <Select
            name="trigger"
            value={selectedTrigger}
            onChange={setSelectedTrigger}
            options={triggers.map(tr => ({ label: tr.name, value: tr.id }))}
            placeholder={t('select')}
          />
        );
      case ruleTypes.HIDE:
        return (
          <div>{t('hideRulesMessage')}</div>
        );
      default:
        return null;
    }
  };

  const getComparator = () => {
    if (isBool) {
      return (
        <>
          <input
            name="comparator"
            value="=="
            type="hidden"
            readOnly
          />
          <div className="equalInput">=</div>
        </>
      );
    }
    if (isString) {
      return (
        <Select
          name="comparator"
          value={comparator}
          onChange={setComparator}
          className="comparator"
          options={[
            { label: '=', value: '==' },
            { label: '!=', value: '!=' },
          ]}
          placeholder=" "
        />
      );
    }

    return (
      <Select
        name="comparator"
        value={comparator}
        className="comparator"
        onChange={setComparator}
        options={[
          { label: '\u003E', value: '>' },
          { label: '\u2265', value: '>=' },
          { label: '=', value: '==' },
          { label: '!=', value: '!=' },
          { label: '\u2264', value: '<=' },
          { label: '\u003C', value: '<' },
        ]}
        placeholder=" "
      />
    );
  };

  if (
    (currentRule
    && ((selectedColor && currentRule.color !== selectedColor && lastRuleId !== currentRule.id)
    || (selectedContentColor && currentRule.contentColor !== selectedContentColor && lastRuleId !== currentRule.id)))
  ) {
    setLastRuleId(currentRule.id);
    if (currentRule.color !== selectedColor) {
      handleColorSelection(currentRule.color);
    }
    if (currentRule.contentColor !== selectedContentColor) {
      handleContentColorSelection(currentRule.contentColor);
    }
  }
  return (
    <form
      id="RuleForm"
      className="right"
    >
      <input type="hidden" value={currentRule ? currentRule.id : ''} name="id" />
      <h4>{t('if')}</h4>
      <div className="ifContainer">
        <Select
          style={{ display: 'inline-block', width: '200px' }}
          name="variable"
          value={`\${${variable}}`}
          onChange={handleVariableChange}
          options={variableOptions}
          placeholder={t('select')}
        />
        {getComparator()}
        {valueToCompareInput()}
      </div>

      <h4>{t('then')}</h4>
      {getContentType()}
      <br />
      <div className="buttonHolder">
        <div>
          <SubmitButton label={currentRule ? t('modify') : t('create')} onClick={e => updateOrCreateRule(e, selectedColor, selectedContentColor)} />
          {currentRule && <DeleteButton handleDelete={() => deleteRule(currentRule.id)} />}
        </div>
        {currentRule && (
          <SubmitButton label={t('duplicate')} onClick={duplicateRule} style={{ backgroundColor: '#1E871E' }} />
        )}
      </div>
    </form>
  );
};

RuleForm.propTypes = {
  stopwatches: reducersTypes.stopwatches.stopwatches.isRequired,
  type: PropTypes.string.isRequired,
  currentRule: PropTypes.shape({
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    contentColor: PropTypes.string,
    condition: PropTypes.string.isRequired,
    trigger: PropTypes.string,
  }),
  dropDownList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteRule: PropTypes.func.isRequired,
  updateOrCreateRule: PropTypes.func.isRequired,
  duplicateRule: PropTypes.func.isRequired,
  tileWidgetType: PropTypes.string.isRequired,
};
RuleForm.defaultProps = {
  currentRule: null,
};

export default RuleForm;

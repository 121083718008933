import types from './types';

const setConfigurationMode = bool => ({
  type: types.SET_CONFIGURATION_MODE,
  payload: bool,
});

const setFullscreen = bool => ({
  type: types.SET_FULLSCREEN,
  payload: bool,
});

const setLanguage = language => ({
  type: types.SET_LANGUAGE,
  payload: language,
});

const setTriggersSearchInput = searchInput => ({
  type: types.SET_TRIGGERS_SEARCH_INPUT,
  payload: searchInput,
});

const setActionsSearchInput = searchInput => ({
  type: types.SET_ACTIONS_SEARCH_INPUT,
  payload: searchInput,
});

const setSideBarSearchInput = searchInput => ({
  type: types.SET_SIDEBAR_SEARCH_INPUT,
  payload: searchInput,
});

export {
  setConfigurationMode,
  setFullscreen,
  setLanguage,
  setTriggersSearchInput,
  setActionsSearchInput,
  setSideBarSearchInput,
};

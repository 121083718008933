import React from 'react';
import PropTypes from 'prop-types';
import { reducersTypes } from '~services';
import TileContents from '../TileContents';
import PromptTileMachine from './PromptTileMachine';
import PromptTileVariables from './PromptTileVariables';

function PromptTile({
  backgroundColor,
  height,
  width,
  tile,
}) {
  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
    >
      { tile.dataType === 'machine' && (
        <PromptTileMachine
          height={height}
          width={width}
          tile={tile}
        />
      )}
      { tile.dataType === 'variables' && (
        <PromptTileVariables
          height={height}
          width={width}
          tile={tile}
        />
      )}
    </TileContents>
  );
}

PromptTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
};

export default PromptTile;

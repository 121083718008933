import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Duration } from 'luxon';
import PropTypes from 'prop-types';
import useInterval from 'use-interval';
import { reduxOperations, reducersTypes } from '~services';
import { getSocket } from '~services/socket';
import { serverTime } from '~utils/time';
import ShapeWidgets from './ShapeWidgets';

const StopwatchWidget = ({
  stopwatches, widget, backgroundColor, h, w, x, y, socketUpdateStopwatch,
}) => {
  const socket = getSocket();
  const myStopwatch = stopwatches.find(elem => widget.stopwatch === elem.id);
  const [isSocketInitialized, setIsSocketInitialized] = useState(false);
  const channel = 'stopwatches';
  const [currentStopwatch, setCurrentStopwatch] = useState(myStopwatch);
  const [currentTime, setCurrentTime] = useState(0);
  const [timerStart, setTimerStart] = useState(0);
  const intervalRef = React.useRef(null);

  const startTimerLocal = watch => {
    setTimerStart(serverTime() - watch.currentTime);
  };
  const stopTimerLocal = watch => {
    setCurrentTime(watch.currentTime);
  };

  const resetTimerLocal = watch => {
    setTimerStart(0);
    setCurrentTime(0);
    if (watch.isActive) {
      startTimerLocal(watch);
    } else {
      stopTimerLocal(watch);
    }
  };

  const handleSocketData = socketData => {
    if (socketData.id === currentStopwatch.id) {
      socketUpdateStopwatch(socketData);
      switch (socketData.action) {
        case 'start':
          startTimerLocal(socketData.notification);
          break;
        case 'stop':
          stopTimerLocal(socketData.notification);
          break;
        case 'reset':
          resetTimerLocal(socketData.notification);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    const Stopwatch = stopwatches.find(elem => widget.stopwatch === elem.id);
    setCurrentStopwatch(Stopwatch);
  }, [widget]);

  useEffect(() => {
    const Stopwatch = stopwatches.find(elem => widget.stopwatch === elem.id);
    setCurrentStopwatch(Stopwatch);
    if (currentStopwatch.isActive) {
      setCurrentTime((serverTime() - currentStopwatch.savedAtEpoch)
      + currentStopwatch.currentTime);
    } else {
      setCurrentTime(currentStopwatch.currentTime);
    }
    if (currentStopwatch.isActive) {
      setTimerStart(serverTime() - currentTime);
    } else {
      setTimerStart(0);
    }
  }, [currentStopwatch, currentTime]);

  useEffect(() => {
    if (socket && !isSocketInitialized) {
      socket.on(channel, handleSocketData);
      setIsSocketInitialized(true);
    }
    setInterval(serverTime());
    return function cleanup() {
      socket.removeListener(channel, handleSocketData);
      clearInterval(intervalRef.current);
    };
  }, []);

  useInterval(
    () => setCurrentTime(serverTime() - timerStart),
    currentStopwatch.isActive ? 10 : null,
  );

  let displayTime = currentTime;
  if (currentStopwatch && currentStopwatch.type === 'CountDown') {
    displayTime = currentStopwatch.startTime - currentTime;
  }
  const formatedTime = (displayTime >= 0 ? '' : '-') + Duration.fromMillis(Math.abs(displayTime)).toFormat(widget.timeFormat);
  const ShapeWidget = ShapeWidgets[widget.format.shape];
  return (
    <ShapeWidget
      widget={widget}
      backgroundColor={backgroundColor}
      content={formatedTime}
      x={x}
      y={y}
      w={w}
      h={h}
    />
  );
};

StopwatchWidget.propTypes = {
  stopwatches: reducersTypes.stopwatches.stopwatches.isRequired,
  socketUpdateStopwatch: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    format: PropTypes.shape({
      shape: PropTypes.string,
    }),
    timeFormat: PropTypes.string,
    stopwatch: PropTypes.shape({}),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    stopwatches: state.stopwatches.stopwatches,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    socketUpdateStopwatch: reduxOperations.stopwatches.socketUpdateStopwatch,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StopwatchWidget);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import API from '~services/endpoints';
import { CancelButton } from '~UI/Buttons';
import { FontAwesome } from '~UI/index';
import { DefaultModal } from '~UI/DefaultModal';
import { showSuccess } from '~utils/toast';
import './LinkRedirectionPopup.scss';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  contentType: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
};

const defaultProps = {
  onHide: () => undefined,
  show: false,
};

const LinkRedirectionPopup = ({ show, onHide, contentType, contentId }) => {
  const { t } = useTranslation();

  const [linkId, setLinkId] = useState<string | null>(null);

  const fetchLink = () => {
    API.addLinkRedirection({ contentType, contentId }).then(res => {
      setLinkId(`${window.location.protocol}//${window.location.host}/redirect/${res.linkRedirection}`);
    });
  };

  useEffect(() => {
    if (show && !linkId) {
      fetchLink();
    }
  }, [show, linkId]);

  const copyAndClose = () => {
    if (linkId) {
      navigator.clipboard.writeText(linkId);
      onHide();
      showSuccess(t('linkCopied'));
    }
  };

  return (
    <DefaultModal
      title={t('shareLink')}
      show={show}
      closePopup={onHide}
    >
      <div className="LinkRedirectionPopup">
        {linkId || t('loading')}
        <FontAwesome
          icon="clone"
          className="copyIcon"
          onClick={copyAndClose}
        />
      </div>

      <div className="LinkRedirectionFooter">
        <CancelButton onClick={onHide} />
      </div>
    </DefaultModal>
  );
};

LinkRedirectionPopup.propTypes = propTypes;
LinkRedirectionPopup.defaultProps = defaultProps;

export default LinkRedirectionPopup;

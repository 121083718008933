import React from 'react';
import PropTypes from 'prop-types';
import { invertColor } from '~utils';
import { fitText } from '~utils/responsiveValue';

const WIDTH_RATIO = 0.95;
const METRIC_WIDTH_RATIO = 0.75;

const Text = ({
  value, units, comparisonMetric: metric, backgroundColor, relativeWidth, selectedObject,
}) => {
  const { contentSize, zoom } = selectedObject;
  const baseProps = {
    x: relativeWidth / 2,
    textAnchor: 'middle',
    dominantBaseline: 'middle',
    fill: invertColor(backgroundColor),
  };
  let metricText = '';
  if (metric) {
    if (metric.isUp) {
      metricText = `▲ ${metric.value}`;
    } else {
      metricText = `▼ ${metric.value}`;
    }
  }

  function getValueFontSize(height) {
    return contentSize || fitText(value, relativeWidth * WIDTH_RATIO, 0.85 * height, zoom);
  }

  function getMetricFontSize(height) {
    return contentSize || fitText(metricText, relativeWidth * METRIC_WIDTH_RATIO, 0.85 * height, zoom);
  }

  function getUnitsFontSize(height) {
    return contentSize || fitText(units, relativeWidth * WIDTH_RATIO, 0.85 * height, zoom);
  }

  if (metric && units) {
    const valuePositionY = 35;
    const valueHeight = 65;
    const metricPositionY = 60;
    const metricHeight = 15;
    const unitPositionY = 75;
    const unitHeight = 15;
    return (
      <>
        <tspan
          {...baseProps}
          y={valuePositionY}
          fontSize={getValueFontSize(valueHeight)}
        >
          {value}
        </tspan>
        <tspan
          {...baseProps}
          y={metricPositionY}
          fontSize={getMetricFontSize(metricHeight)}
          fill={metric.color}
        >
          {metricText}
        </tspan>
        <tspan
          {...baseProps}
          y={unitPositionY}
          fontSize={getUnitsFontSize(unitHeight)}
        >
          {units}
        </tspan>
      </>
    );
  }

  if (metric) {
    const valuePositionY = 45;
    const valueHeight = 65;
    const metricPositionY = 75;
    const metricHeight = 15;
    return (
      <>
        <tspan
          {...baseProps}
          y={valuePositionY}
          fontSize={getValueFontSize(valueHeight)}
        >
          {value}
        </tspan>
        <tspan
          {...baseProps}
          y={metricPositionY}
          fontSize={getMetricFontSize(metricHeight)}
          fill={metric.color}
        >
          {metricText}
        </tspan>
      </>
    );
  }

  if (units) {
    const valuePositionY = 45;
    const valueHeight = 65;
    const unitPositionY = 75;
    const unitHeight = 15;
    return (
      <>
        <tspan
          {...baseProps}
          y={valuePositionY}
          fontSize={getValueFontSize(valueHeight)}
        >
          {value}
        </tspan>
        <tspan
          {...baseProps}
          y={unitPositionY}
          fontSize={getUnitsFontSize(unitHeight)}
        >
          {units}
        </tspan>
      </>
    );
  }

  const valuePositionY = 45;
  const valueHeight = 70;
  return (
    <tspan
      {...baseProps}
      y={valuePositionY}
      fontSize={getValueFontSize(valueHeight)}
    >
      {value}
    </tspan>
  );
};

Text.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  selectedObject: PropTypes.object.isRequired,
  value: PropTypes.string,
  units: PropTypes.string,
  comparisonMetric: PropTypes.string,
  relativeWidth: PropTypes.number.isRequired,
};
Text.defaultProps = {
  value: '',
  units: '',
  comparisonMetric: '',
};

export { Text };

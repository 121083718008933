import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Checkbox } from '@intelligenceindustrielle/react-ui-components';
import { getStatisticsFrom } from '~components/utils';
import { FontAwesome } from '~UI';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import IntervalTimePicker from '~UI/IntervalTimePicker/IntervalTimePicker';
import { sortArray } from '~utils/sort';

import machineProperties from '../machineProperties.json';

const chooseDecimals = [
  'availability',
  'useRate',
  'overallEffectiveness',
  'totalEffectivePerformance',
  'operatingRate',
  'performanceRatio',
  'qualityRatio',
  'filledInStopCauseRatio',
  'plannedOutputCount',
  'hourlyPlannedOutputCount',
  'hourlyInputCount',
  'hourlyOutputCount',
  'hourlyAcceptableOutputCount',
  'hourlyScrapCount',
  'minutelyPlannedOutputCount',
  'minutelyInputCount',
  'minutelyOutputCount',
  'minutelyAcceptableOutputCount',
  'minutelyScrapCount',
  'productionRate',
  'minutelyProductionRate',
  'productionRatio',
  'minutelyProductionRatio',
  'plannedProductionRatio',
  'minutelyPlannedProductionRatio',
  'inputCount',
  'outputCount',
  'acceptableOutputCount',
  'scrapCount',
  'quantityLeft',
  'requiredQuantity',
];

const MachineForm = ({ selectedObject }) => {
  const { t } = useTranslation();

  const machines = useSelector(state => state.machines);
  const language = useSelector(state => state.views.language);
  const machinePropertiesOptions = machineProperties.map(property => (
    {
      label: t(property.name),
      value: property.property,
    }
  ));

  const findStopCauseInSubMenu = (stopCauses, selectedStopCauseId) => {
    for (let i = 0; i < stopCauses.length; i += 1) {
      const stopCause = stopCauses[i];
      if (stopCause.id === selectedStopCauseId) {
        return [stopCauses];
      }
      if (stopCause.subMenu) {
        const result = findStopCauseInSubMenu(stopCause.subMenu, selectedStopCauseId);
        if (result) {
          return [stopCauses, ...result];
        }
      }
    }
    return null;
  };

  const preSelectStopCause = machineIdArg => {
    const selectedMachine = machines.find(m => m.id === machineIdArg);
    let stopCauses = selectedMachine && selectedMachine.stopCauses;
    let menuStack = [];

    if (selectedMachine && stopCauses.length) {
      const selectedStopCauseId = selectedObject.machinePropertyStopCauseId;
      menuStack = findStopCauseInSubMenu(stopCauses, selectedStopCauseId);
      if (menuStack) {
        stopCauses = menuStack[menuStack.length - 1];
      }
      return { selectedStopCauseId, stopCauses, menuStack };
    }
    return { selectedStopCauseId: '', stopCauses: null, menuStack: [] };
  };

  const [machineId, setMachineId] = useState(selectedObject.machineId);
  const [machineProperty, setMachineProperty] = useState(selectedObject.machineProperty || 'availability');
  const [stopCausesState, setStopCauses] = useState(null);
  const [selectedStopCauseIdState, setSelectedStopCauseId] = useState(selectedObject.machinePropertyStopCauseId);
  const [isSubMenuState, setIsSubMenu] = useState(null);
  const [currentOperation, setCurrentOperation] = useState(selectedObject.currentOperation || false);
  const [currentSKUNumber, setCurrentSKUNumber] = useState(selectedObject.currentSKUNumber || false);
  const [currentWorkOrder, setCurrentWorkOrder] = useState(selectedObject.currentWorkOrder || false);
  const [intervalType, setIntervalType] = useState(selectedObject.intervalType);
  const [menuStack, setMenuStack] = useState([]);

  useEffect(() => {
    const preSelectStopCauseObj = preSelectStopCause(machineId);
    setSelectedStopCauseId(preSelectStopCauseObj.selectedStopCauseId);
    setStopCauses(preSelectStopCauseObj.stopCauses);
    setIsSubMenu(preSelectStopCauseObj?.menuStack?.length > 1);
    setMenuStack(preSelectStopCauseObj.menuStack || []);
  }, [machineId]);

  const handleStopCauseSelection = stopCause => {
    if (stopCause === null && menuStack.length > 0) {
      const lastMenu = menuStack[menuStack.length - 1];
      const newMenuStack = menuStack.slice(0, -1);
      setMenuStack(newMenuStack);
      setIsSubMenu(newMenuStack.length > 0);
      setStopCauses(lastMenu);
    } else if (stopCause?.subMenu?.length) {
      const newMenuStack = [...menuStack, stopCausesState];
      setMenuStack(newMenuStack);
      setStopCauses(stopCause.subMenu);
      setIsSubMenu(true);
    } else if (stopCause) {
      setSelectedStopCauseId(stopCause.id);
    }
  };
  const handleChangeMachineProperty = e => {
    if (getStatisticsFrom(e) === 'operation' && currentOperation) {
      setMachineProperty(e);
      setCurrentSKUNumber(false);
      setCurrentWorkOrder(false);
    } else {
      setIntervalType(prevIntervalType => {
        if (prevIntervalType === 'always') {
          setMachineProperty(e);
          return 'shift';
        }
        setMachineProperty(e);
      });
    }
  };

  const handleMachineIdChange = newMachineId => {
    const { selectedStopCauseId, stopCauses } = preSelectStopCause(newMachineId);
    setMachineId(newMachineId);
    setIsSubMenu(menuStack?.length > 1);
    setSelectedStopCauseId(selectedStopCauseId);
    setStopCauses(stopCauses);
    setMenuStack(menuStack || []);
  };

  const handleToggleCurrentOperation = () => {
    setCurrentOperation(prevCurrentOperation => {
      if (prevCurrentOperation) {
        setIntervalType(prevIntervalType => {
          if (prevIntervalType === 'always') {
            return 'shift';
          }
          setIntervalType(prevIntervalType);
        });
        return false;
      }
      if (getStatisticsFrom(machineProperty) === 'operation') {
        setCurrentSKUNumber(false);
        setCurrentWorkOrder(false);
        return true;
      }
      return true;
    });
  };

  const handleToggleCurrentSKUNumber = () => {
    setCurrentSKUNumber(prevCurrentSKUNumber => !prevCurrentSKUNumber);
  };

  const handleToggleCurrentWorkOrder = () => {
    setCurrentWorkOrder(prevCurrentWorkOrder => !prevCurrentWorkOrder);
  };

  const { description } = machineProperties.filter(prop => machineProperty === prop.property)[0];
  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));

  return (
    <div>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        value={machineId}
        onChange={handleMachineIdChange}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('machineKPI')}</div>
      <Select
        name="machineProperty"
        className="fullwidth"
        value={machineProperty}
        onChange={handleChangeMachineProperty}
        options={machinePropertiesOptions}
        placeholder={t('select')}
      />
      <div style={{ marginBottom: '8px' }}>
        <FontAwesome icon="info-circle" />
        &nbsp;&nbsp;
        {t(description)}
      </div>

      <div>
        <div className="multipleSelectionInput">
          <Checkbox
            controlledCheck={currentOperation}
            checked={currentOperation}
            onChange={handleToggleCurrentOperation}
            label={t('currentOperation')}
          />
        </div>
        <div className="multipleSelectionInput">
          <Checkbox
            controlledCheck={currentSKUNumber}
            checked={currentSKUNumber}
            onChange={handleToggleCurrentSKUNumber}
            label={t('currentSKUNumber')}
            disabled={currentOperation && getStatisticsFrom(machineProperty) === 'operation'}
          />
        </div>
        <div className="multipleSelectionInput">
          <Checkbox
            controlledCheck={currentWorkOrder}
            checked={currentWorkOrder}
            onChange={handleToggleCurrentWorkOrder}
            label={t('currentWorkOrder')}
            disabled={currentOperation && getStatisticsFrom(machineProperty) === 'operation'}
          />
        </div>
      </div>

      <div>
        <input
          type="hidden"
          name="currentOperation"
          value={currentOperation}
        />
        <input
          type="hidden"
          name="currentSKUNumber"
          value={currentSKUNumber}
        />
        <input
          type="hidden"
          name="currentWorkOrder"
          value={currentWorkOrder}
        />
      </div>

      {
        machineProperty === 'stopCauseDowntime' && (
          <>
            <input type="hidden" name="machinePropertyStopCauseId" value={selectedStopCauseIdState} />
            {
              isSubMenuState && (
                <FontAwesome
                  icon="arrow-left"
                  className="backButtonArrow"
                  style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '8px', fontSize: '17px' }}
                  onClick={() => handleStopCauseSelection(null)}
                />
              )
            }
            {
              stopCausesState && stopCausesState.map(stopcause => {
                let stopCauseName;
                switch (language) {
                  case 'en':
                    stopCauseName = stopcause.nameEN || stopcause.name;
                    break;
                  case 'fr':
                    stopCauseName = stopcause.nameFR || stopcause.name;
                    break;
                  case 'es':
                    stopCauseName = stopcause.nameES || stopcause.name;
                    break;
                  default:
                    stopCauseName = stopcause.name;
                }

                return (
                  <StopCauseIcon
                    key={stopcause.id}
                    option={{
                      ...stopcause,
                      name: stopCauseName,
                      badgeCount: stopcause.subMenu?.length,
                    }}
                    small
                    className={(stopcause.id === selectedStopCauseIdState) ? 'Selected' : 'Unselected'}
                    onClick={() => handleStopCauseSelection(stopcause)}
                    machineId={machineId}
                  />
                );
              })
            }
          </>
        )
      }
      <div className="inputTitle">{t('dataCollectionPeriod')}</div>
      <IntervalTimePicker
        intervalType={intervalType}
        intervalTypeInput
        alwaysOption={currentOperation && getStatisticsFrom(machineProperty) === 'operation'}
        changeIntervalType={value => setIntervalType(value)}
      />
      {chooseDecimals.includes(machineProperty) && (
        <>
          <div className="inputTitle">{t('numberOfDecimals')}</div>
          <input
            type="number"
            name="decimals"
            className="fullwidth"
            min={0}
            max={4}
            defaultValue={Number.isInteger(selectedObject.decimals) ? selectedObject.decimals : 0}
          />
        </>
      )}
    </div>
  );
};

MachineForm.propTypes = {
  selectedObject: PropTypes.shape({
    currentOperation: PropTypes.bool,
    currentSKUNumber: PropTypes.bool,
    currentWorkOrder: PropTypes.bool,
    decimals: PropTypes.number,
    intervalType: PropTypes.string,
    machineId: PropTypes.string,
    machineProperty: PropTypes.string,
    machinePropertyStopCauseId: PropTypes.string,
  }),
};
MachineForm.defaultProps = {
  selectedObject: {},
};

export default MachineForm;

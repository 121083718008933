import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import imagesLibrary from '~images/imagesLibrary';
import directLinks from '~utils/directLinks';
import FavoritesHandler from '~components/UI/Wrappers/FavoritesHandler';

import CardActions from '../CardActions';
import FontAwesome from '~components/UI/FontAwesome';

function ImageCard({
  card,
  usedTags,
  redirectRessource,
  duplicateAction, editModal,
  deleteModal, supportFavorites,
  optionalAction,
  configureLink,
}) {
  const history = useHistory();
  const location = useLocation();
  const tags = useSelector(state => state.tags.tags);
  const cardRef = useRef(null);
  const titleRef = useRef(null);
  const subTitleRef = useRef(null);
  const [dynamicHeight, setDynamicHeight] = useState(null);

  const parsedTags = tags ? tags.filter(tag => usedTags.includes(tag.id)) : [];

  const updateDimensions = () => {
    const newDynamicHeight = cardRef.current ? cardRef.current.clientWidth * 0.75 : null;
    setDynamicHeight(newDynamicHeight);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    updateDimensions();
  });

  const titleOverflows = titleRef.current && (titleRef.current.scrollWidth > titleRef.current.clientWidth);
  const subTitleOverflows = subTitleRef.current && (subTitleRef.current.scrollWidth > subTitleRef.current.clientWidth);

  return (
    <div className="cardContainer" ref={cardRef} style={dynamicHeight ? { height: `${dynamicHeight}px` } : {}}>
      <div
        className="imageCardBackground"
        role="link"
        onClick={() => {
          if (typeof redirectRessource === 'function') {
            redirectRessource();
          } else if (typeof redirectRessource === 'string') {
            history.push(directLinks[redirectRessource](card.id, location.pathname));
          }
        }}
        style={{ backgroundImage: card.backgroundURL ? `url("${card.backgroundURL}")` : `url(${imagesLibrary.LogoIIDark})`, backgroundSize: !card.backgroundURL && '25% 44.5%' }}
      />
      <div className="imageCardFooter">
        <div className="imageCardFooterText">
          <div className="imageCardTitle" ref={titleRef}>
            {card.name}
            {card.type === 'Multiple' && (
              <>
                &nbsp;
                <FontAwesome icon="carousel" height="25px" color="#555555" />
              </>
            )}
            {(parsedTags && parsedTags.length > 0) && (
              <div className="cardTagsContainer">
                {
                  parsedTags.map(tag => tag.color && (
                    <span key={tag.id}>
                      <div data-tip={tag.name} className="cardTag" key={tag.id} style={{ background: tag.color }} />
                    </span>
                  ))
                }
              </div>
            )}
          </div>
          {titleOverflows && (
            <div data-tip={card.name} className="imageCardTooltipTitle">
              {card.name}
              {(parsedTags && parsedTags.length > 0) && (
                <div className="cardTagsContainer">
                  {parsedTags.map(tag => tag.color && (
                    <span>
                      <div data-tip={tag.name} className="cardTag" key={tag.id} style={{ background: tag.color }} />
                    </span>
                  ))}
                </div>
              )}
            </div>
          )}
          <span className="imageCardSubtitle" ref={subTitleRef}>{card.description}</span>
          {subTitleOverflows && <div className="imageCardTooltipSubtitle">{card.description}</div>}
        </div>
        {(!!duplicateAction || !!editModal || !!deleteModal) ? (
          <CardActions
            card={card}
            cardType={`${redirectRessource}s`}
            duplicateAction={duplicateAction}
            editModal={editModal}
            deleteModal={deleteModal}
            supportFavorites={supportFavorites}
            optionalAction={optionalAction}
            configureLink={configureLink}
          />
        ) : (
          supportFavorites && (
            <FavoritesHandler
              favoriteType={`${redirectRessource}s`}
              favoriteId={card.id}
            />
          )
        )}
      </div>
    </div>
  );
}

const ModalPropTypes = PropTypes.shape({
  Component: PropTypes.elementType.isRequired,
  props: PropTypes.object.isRequired,
});

ImageCard.propTypes = {
  card: PropTypes.object.isRequired,
  usedTags: PropTypes.arrayOf(PropTypes.string),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]).isRequired,
  editModal: ModalPropTypes.isRequired,
  deleteModal: ModalPropTypes.isRequired,
  redirectRessource: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  supportFavorites: PropTypes.bool,
  optionalAction: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]).isRequired,
  configureLink: PropTypes.string,
};

ImageCard.defaultProps = {
  supportFavorites: false,
  configureLink: null,
  usedTags: [],
};

export default ImageCard;

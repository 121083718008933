import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import * as d3 from 'd3';
import { round, getNumberRange } from '~utils/math';
import getBarChartLapse from '~utils/getBarChartLapse';
import {
  PADDING_LEFT,
  PADDING_RIGHT,
  PADDING_TOP,
  PADDING_BOTTOM,
  FONT_SIZE_AXISX,
  PADDING_RIGHT_WITH_ARROWS,
  BLUE,
  DARK_GRAY,
} from './constants';

import './Charts.scss';

const callTooltip = (g, value) => {
  if (!value) {
    g.style('display', 'none');
  } else {
    g.style('display', null)
      .style('pointer-events', 'none')
      .style('font', '15px sans-serif');

    const path = g.selectAll('path')
      .data([null])
      .join('path')
      .attr('class', `background_${localStorage.theme === 'Dark' ? 'dark' : 'light'}`);

    const { tooltip } = value;
    const text = g.selectAll('text')
      .data([null])
      .join('text')
      .call(t => t
        .selectAll('tspan')
        .data((`
          ${`${i18n.t('quantity')}: ${tooltip.count || value.y}`}
          ${(tooltip.operation) ? `${i18n.t('operation')}: ${tooltip.operation}` : ''}
          ${(tooltip.skuNumber) ? `${i18n.t('skuNumber')}: ${tooltip.skuNumber}` : ''}
          ${(tooltip.workOrder) ? `${i18n.t('workOrder')}: ${tooltip.workOrder}` : ''}
          `).split(/\n/).filter(line => line.trim() !== ''))
        .join('tspan')
        .attr('x', 0)
        .attr('y', (d, i) => `${i * 1.1}em`)
        .attr('class', `centerText text_${localStorage.theme === 'Dark' ? 'dark' : 'light'}`)
        .text(d => d));

    const { y, width: w, height: h } = text.node().getBBox();
    text.attr('transform', `translate(0,${y - h + 5})`);
    path.attr('d', `M${-w / 2 - 10},-5H-5l5,5l5,-5H${w / 2 + 10}v${-1.45 * h}h-${w + 20}z`);
  }
};

const BAR_LABEL_PADDING = 10;

const BarChart = ({
  timePeriod,
  height,
  width,
  data,
  showGoalToggle,
  goal,
  showBarsValues,
  showArrows,
}) => {
  const node = useRef(null);

  const calculateXAxis = () => {
    const { start, end } = timePeriod;
    const domainX = [];
    const lapse = getBarChartLapse({ start, end });

    for (let i = start; i < end; i += lapse) {
      domainX.push(i);
    }
    return domainX;
  };

  const calculateYAxis = dataToShow => {
    const [minData, maxData] = d3.extent(dataToShow.map(d => Number(d.y)));
    if (!goal && (minData === undefined || (minData === 0 && maxData === 0))) {
      return [0, 10];
    }

    const min = minData || 0;
    let max = 0;
    if (showGoalToggle && goal && !maxData) {
      max = goal;
    } else if (!showGoalToggle && maxData) {
      max = maxData;
    } else {
      max = Math.max(goal, maxData);
    }

    let minShowed = 0;
    let maxShowed = 0;

    if (min < 0) {
      minShowed = -10;
      if (min < minShowed) {
        const minPow = getNumberRange(min);
        minShowed = (Math.ceil(min / minPow)) * minPow - (minPow / 2);
        if (min < minShowed || !Number.isInteger(minShowed)) {
          minShowed -= (minPow / 2);
        }
      }
    }

    if (max > 0) {
      maxShowed = 10;
      if (max > maxShowed) {
        const maxPow = getNumberRange(max);
        maxShowed = (Math.floor(max / maxPow)) * maxPow + (maxPow / 2);
        if (max > maxShowed || !Number.isInteger(maxShowed)) {
          maxShowed += (maxPow / 2);
        }
      }
    }

    return [minShowed, maxShowed];
  };

  const createBarChart = () => {
    const heightChart = 0.85 * height - PADDING_BOTTOM;

    const lapse = getBarChartLapse(timePeriod);

    // Remove everything inside the div
    d3.select(node.current).selectAll('*').remove();

    const domainX = calculateXAxis();
    const [minShowed, maxShowed] = calculateYAxis(data);

    // SVG for the graph
    const svg = d3.select(node.current)
      .append('svg')
      .classed('nocursorpointer', true)
      .attr('width', width)
      .attr('height', height);

    const x = d3.scaleBand()
      .domain(domainX)
      .range([PADDING_LEFT, width - (showArrows ? PADDING_RIGHT_WITH_ARROWS : PADDING_RIGHT)])
      .padding(0.1);
    const y = d3.scaleLinear()
      .domain([minShowed, maxShowed])
      .range([heightChart,
        showBarsValues ? PADDING_TOP + (BAR_LABEL_PADDING * 2) : PADDING_TOP,
      ]);

    // Gridlines
    const xGridlines = d3.axisBottom(x)
      .tickValues(domainX)
      .tickSize(-height)
      .tickSizeOuter(0)
      .tickFormat('');
    svg.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,${heightChart})`)
      .call(xGridlines);
    const yGridlines = d3.axisLeft(y)
      .ticks(height / 100)
      .tickSize(-width + (PADDING_RIGHT * 2))
      .tickSizeOuter(0)
      .tickFormat('');
    svg.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(${PADDING_LEFT},0)`)
      .call(yGridlines);

    // Bars
    const bars = svg.append('g')
      .selectAll('rect')
      .data(data)
      .join('rect')
      .attr('stroke', DARK_GRAY)
      .attr('stroke-dasharray', d => `${d.color === '#777' ? x.bandwidth() : 0} 1000`)
      .attr('x', d => x(d.x))
      .attr('y', d => (d.y < 0 ? y(0) : y(d.y)))
      .attr('width', x.bandwidth())
      .attr('height', d => Math.abs(y(0) - y(d.y)))
      .attr('fill', d => d.color || BLUE);

    // Goal line
    if (showGoalToggle && goal !== null) {
      const goalLine = d3.axisLeft(y)
        .tickValues([goal])
        .tickSize(-width + (PADDING_RIGHT * 2))
        .tickSizeOuter(0)
        .tickFormat('');
      svg.append('g')
        .classed('greenLine', true)
        .attr('transform', `translate(${PADDING_LEFT},0)`)
        .call(goalLine);
    }
    // X Axis
    const xAxis = d3.axisBottom(x)
      .tickValues(domainX)
      .tickSizeOuter(0)
      .tickFormat(d => {
        if (width > (domainX.length * 90)) {
          return `${d3.timeFormat('%H:%M')(d)}-${d3.timeFormat('%H:%M')(d + lapse)}`;
        }
        if (width > (domainX.length * 50)) {
          return `${d3.timeFormat('%H:%M')(d)}`;
        }
        return `${d3.timeFormat('%H')(d)}`;
      });
    svg.append('g')
      .attr('transform', `translate(0,${heightChart})`)
      .call(xAxis)
      .selectAll('text')
      .style('font-size', FONT_SIZE_AXISX);

    // Y Axis
    const yAxis = d3.axisLeft(y)
      .ticks(height / 100)
      .tickFormat(d3.format('.0f'));
    svg.append('g')
      .attr('transform', `translate(${PADDING_LEFT},0)`)
      .call(yAxis);

    // Bar value
    if (showBarsValues) {
      svg.selectAll('text.bar')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'bar')
        .attr('text-anchor', 'middle')
        .attr('x', d => (x(d.x) + (x.bandwidth() / 2)))
        .attr('y', d => y(d.y) - BAR_LABEL_PADDING)
        .text(d => d.barValue || (d.y !== undefined ? round(d.y, 1) : ''))
        .style('fill', 'white')
        .style('font', `${Math.min(x.bandwidth() / 3, 25)}px sans-serif`)
        .style('font-weight', 'bold');
    }

    // Tooltip
    const tooltip = svg.append('g');
    bars.on('mousemove touchmove', d => {
      const [mouseX, mouseY] = d3.mouse(node.current);
      tooltip.attr('transform', `translate(${mouseX},${mouseY})`)
        .call(callTooltip, d, goal);
    })
      .on('mouseleave touchend', () => {
        tooltip.call(callTooltip, null);
      });
  };

  useEffect(() => {
    // data and timePeriod can update at different times and still trigger createBarChart.
    // We need to check that both data and timePeriod are synched. Otherwise the charts glitches.
    if (!data.length || (data.length && data[0].x === timePeriod.start)) {
      createBarChart();
    }
  }, [height, width, data, showGoalToggle, goal, showBarsValues, timePeriod, showArrows]);

  return <div ref={node} />;
};

BarChart.propTypes = {
  timePeriod: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  showGoalToggle: PropTypes.bool.isRequired,
  goal: PropTypes.number,
  showBarsValues: PropTypes.bool.isRequired,
  showArrows: PropTypes.bool,
};
BarChart.defaultProps = {
  data: [],
  showArrows: false,
};

export default BarChart;

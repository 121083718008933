import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { useShift } from '~utils/hooks';
import { getDataRangeStartEnd } from '~utils/time';
import { round } from '~utils/math';
import { Gauge } from './GaugeSVG';

const GaugeEvents = ({ selectedObject, dimension, textColor }) => {
  const socket = getSocket();
  const selectedObjectRef = useRef(selectedObject);

  useEffect(() => {
    selectedObjectRef.current = selectedObject;
  });

  const [events, setEvents] = useState([]);
  const [isSocketInitialized, setIsSocketInitialized] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [currentShift] = useShift(selectedObject.machineId);
  const [goal, setGoal] = useState(selectedObject.goal);

  const idleTimerRef = useRef(null);

  const handleSocketEvent = socketEvent => {
    if (socketEvent.name === selectedObjectRef.current.currentEvent) {
      setEvents(prevEvents => [...prevEvents, socketEvent]);
    }

    if (selectedObjectRef.current.objectiveType === 'variable' && socketEvent.id === selectedObjectRef.current.goal) {
      const goalToSet = round(socketEvent.value, 2);
      if (goalToSet >= selectedObjectRef.current.intervalMin && goalToSet <= selectedObjectRef.current.intervalMax) {
        setGoal(goalToSet);
        return;
      }
      setGoal(-1);
    }
  };

  const fetchEvents = async () => {
    const intervalType = selectedObject.intervalType || 'shift';
    const { start, end } = getDataRangeStartEnd(intervalType, currentShift);

    const filter = {
      name: selectedObject.currentEvent,
      timestamp: {
        $gte: start,
        $lt: end,
      },
    };
    const { events: newEvents } = await API.getEvents(filter);
    setEvents(newEvents);
  };

  useEffect(() => {
    setCurrentValue(events.length);
  }, [events]);

  const handleOnActive = () => {
    fetchEvents();
  };

  const handleOnIdle = () => {
    fetchEvents();
    idleTimerRef.current.reset();
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    fetchEvents();

    if (selectedObject.objectiveType === 'variable' && selectedObject.goal) {
      API.getValues(selectedObject.goal, {}, 1).then(response => {
        const goalToSet = response?.values[0] && round(response.values[0].value, 2);
        if (goalToSet >= selectedObject.intervalMin && goalToSet <= selectedObject.intervalMax) {
          setGoal(goalToSet);
          return;
        }
        setGoal(-1);
      });
    } else {
      setGoal(selectedObject.goal);
    }
  }, [selectedObject, currentShift]);

  useEffect(() => {
    if (socket && !isSocketInitialized) {
      socket.on('event', handleSocketEvent);
      socket.on('value', handleSocketEvent);
      setIsSocketInitialized(true);
    }

    return () => {
      if (socket) {
        socket.removeListener('event', handleSocketEvent);
        socket.removeListener('value', handleSocketEvent);
      }
    };
  }, []);

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <Gauge
        currentValue={currentValue}
        interval={{ min: selectedObject.intervalMin, max: selectedObject.intervalMax }}
        metric="unit"
        dimension={dimension}
        textColor={textColor}
        showGoalToggle={
          typeof selectedObject.showGoalToggle === 'boolean'
            ? selectedObject.showGoalToggle
            : typeof selectedObject.goal === 'number'
        } // for backward compatibility with no toggle
        goal={goal}
        aboveGoalColor={selectedObject.aboveGoalColor}
        belowGoalColor={selectedObject.belowGoalColor}
      />
    </>
  );
};

GaugeEvents.propTypes = {
  selectedObject: PropTypes.shape({
    showGoalToggle: PropTypes.bool,
    objectiveType: PropTypes.string,
    goal: PropTypes.number,
    id: PropTypes.string,
    machineId: PropTypes.string,
    title: PropTypes.string,
    valueType: PropTypes.string,
    currentEvent: PropTypes.string,
    intervalMin: PropTypes.number,
    intervalMax: PropTypes.number,
    intervalType: PropTypes.string,
    aboveGoalColor: PropTypes.string,
    belowGoalColor: PropTypes.string,
  }).isRequired,
  dimension: PropTypes.object.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default GaugeEvents;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { useShift } from '~utils/hooks';
import Tick from '~utils/Tick';
import { serverTime, getDataRangeStartEnd } from '~utils/time';
import getBarChartLapse from '~utils/getBarChartLapse';
import BarChartComponent from './BarChartComponent';
import { canNavigateTime, navigateTimeUtil } from './utils';

const BarChartVariables = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const socket = getSocket();

  const [data, setData] = useState(null);
  const [isSocketInitialized, setIsSocketInitialized] = useState(false);
  const [values, setValues] = useState([]);

  const [currentShift, shifts] = useShift(tile.machineId);

  const [timePeriod, setTimePeriod] = useState(getDataRangeStartEnd(tile.intervalType || 'shift', currentShift));
  const [hasUsedArrows, setHasUsedArrows] = useState(false);

  const updateTimePeriod = () => {
    if (!hasUsedArrows) {
      const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
      setTimePeriod(newTimePeriod);
    }
  };

  const handleSocketData = socketValue => {
    if (socketValue.id === tile.axisY && !hasUsedArrows) {
      const value = {
        timestamp: serverTime(),
        valueId: tile.axisY,
        value: socketValue.value,
      };
      setValues([...values, value].sort((v1, v2) => v1.timestamp - v2.timestamp));
    }
  };

  const evaluateVariables = valuesArg => {
    const newData = [];
    const lapse = getBarChartLapse(timePeriod);

    if (valuesArg.length) {
      for (let i = timePeriod.start; i < timePeriod.end; i += lapse) {
        const valuesIn = valuesArg.filter(v => v.timestamp >= i && v.timestamp <= i + lapse);
        let aggregatedValue = 0;
        // TODO: Handle the min and max when there is no value in the lapse. E.g.: take the previous bar value
        switch (tile.aggregateType) {
          case 'sum':
            aggregatedValue = (valuesIn.reduce((prev, curr) => (prev + curr.value), 0)).toFixed(2);
            break;
          case 'min':
            aggregatedValue = valuesIn.length ? Math.min(...valuesIn.map(v => v.value)).toFixed(2) : null;
            break;
          case 'max':
            aggregatedValue = valuesIn.length ? Math.max(...valuesIn.map(v => v.value)).toFixed(2) : null;
            break;
          default:
            aggregatedValue = (valuesIn.reduce((prev, curr) => (prev + curr.value), 0)).toFixed(2);
            break;
        }
        newData.push({
          x: i,
          ...(valuesIn.length && { y: aggregatedValue }),
        });
      }
    }

    setData(newData);
  };

  const fetchVariables = async () => {
    const { start, end } = timePeriod;
    const valueId = tile.axisY;

    const { values: newValues } = await API.getValues(valueId, { timestamp: { $gte: start, $lt: end } });

    setValues(newValues.sort((v1, v2) => v1.timestamp - v2.timestamp));
    evaluateVariables(newValues);
  };

  const navigateTime = goBackward => {
    const { newTimePeriod, hasUsedArrows: newHasUsedArrows } = navigateTimeUtil(goBackward, timePeriod, shifts, tile.intervalType || 'shift');
    setValues([]);
    setTimePeriod(newTimePeriod);
    setHasUsedArrows(newHasUsedArrows);
  };

  useEffect(() => {
    fetchVariables();
  }, [timePeriod, hasUsedArrows]);

  useEffect(() => {
    fetchVariables();
  }, []);

  useEffect(() => {
    Tick.subscribe(updateTimePeriod, 10); // 10 secondes

    return () => {
      Tick.unsubscribe(updateTimePeriod);
    };
  }, [currentShift, hasUsedArrows]);

  useEffect(() => {
    const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
    setTimePeriod(newTimePeriod);
  }, [tile, tile.intervalType, currentShift]);

  useEffect(() => {
    if (socket && !isSocketInitialized) {
      socket.on('value', handleSocketData);
      setIsSocketInitialized(true);
    }

    return () => {
      if (socket) {
        socket.removeListener('value', handleSocketData);
      }
    };
  }, [socket]);

  useEffect(() => {
    setHasUsedArrows(false);
  }, [tile.intervalType]);

  return (
    <BarChartComponent
      backgroundColor={backgroundColor}
      data={data}
      height={height}
      tile={tile}
      timePeriod={timePeriod}
      canNavigateTime={goBackward => canNavigateTime(goBackward, timePeriod, shifts, tile.intervalType || 'shift')}
      navigateTime={navigateTime}
      hasUsedArrows={hasUsedArrows}
      width={width}
    />
  );
};

BarChartVariables.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number,
  tile: PropTypes.shape({
    axisY: PropTypes.string,
    goal: PropTypes.number,
    aggregateType: PropTypes.string,
    intervalType: PropTypes.string,
    machineId: PropTypes.string,
  }).isRequired,
  width: PropTypes.number,
};

export default BarChartVariables;

import serverTime from './serverTime';

function getDataRangeStartEnd(intervalType, currentShift) {
  let start: number | null = null;
  let end: number | null = null;

  switch (intervalType) {
    case 'lastHour':
      start = serverTime() - 3600000;
      end = serverTime();
      break;
    case 'last24Hours':
      start = serverTime() - 86400000;
      end = serverTime();
      break;
    case 'lastWeek':
      start = serverTime() - 604800000;
      end = serverTime();
      break;
    case 'shift':
      start = currentShift.start.getTime();
      end = currentShift.end.getTime();
      break;
    default:
      break;
  }

  return { start, end };
}

export default getDataRangeStartEnd;

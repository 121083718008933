function getStatisticsFrom(property) {
  switch (property) {
    case 'inputCount':
    case 'outputCount':
    case 'scrapCount':
    case 'acceptableOutputCount':
    case 'qualityRatio':
    case 'requiredQuantity':
    case 'quantityLeft':
    case 'timeLeft':
    case 'operationProgress':
      return 'operation';
    default:
      return 'machine';
  }
}

export { getStatisticsFrom };

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import { getApproximateFontSize } from '~utils/responsiveValue';

import {
  BLUE,
  DARK_GRAY,
  PALE_GRAY,
} from '../constants';
import '../Charts.scss';

const LINE_SIZE = 0.005;
const GOAL_LABEL_PADDING_WIDGET = 2;
const GOAL_LABEL_PADDING_TILE = 1;

const CircularChart = ({
  currentValue,
  showGoalToggle,
  goal,
  aboveGoalColor,
  belowGoalColor,
  textColor,
  title,
  style,
  isTile,
  interval,
  metric,
  units,
  dimension,
}) => {
  const node = useRef(null);

  const calculateGoalLabelPos = (goalPercentage, innerRadius, outerRadius, labelSize) => {
    if (!isTile) {
      return innerRadius + ((outerRadius - innerRadius) / 2);
    }

    let margin = (outerRadius - innerRadius - labelSize);
    // If goal is a number with more than 2 digit (float or 100%), then need less margin since take more space
    margin /= String(goal * 100).replace('.', '').length > 2 ? 8 : 4;

    return goalPercentage < 0.5 ? innerRadius + margin : outerRadius - margin;
  };

  const getFormattedValue = currentValueArg => {
    if (currentValueArg === null) {
      return '-';
    }

    switch (metric) {
      case 'percentage':
        return d3.format('.1%')(currentValueArg);
      case 'unit':
        return units ? `${currentValueArg} ${units}` : currentValueArg;
      default:
        return '-';
    }
  };

  const createCircularChart = () => {
    const r = 50;

    // Remove everything inside the div
    d3.select(node.current).selectAll('*').remove();

    // SVG for the graph
    const svg = d3.select(node.current).classed('nocursorpointer', true);

    const innerRadius = r * 0.65;
    const outerRadius = r;
    const pie = d3.pie()
      .sort(null)
      .value(d => d.value);

    // Donut
    const currentPercentage = interval ? (currentValue - interval.min) / (interval.max - interval.min) : 0;
    let color = BLUE;
    if (showGoalToggle) {
      color = currentValue >= goal ? aboveGoalColor : belowGoalColor;
    }
    const currentData = [
      { value: interval ? currentPercentage : currentValue, color },
      { value: interval ? 1 - currentPercentage : 1 - currentValue, color: PALE_GRAY },
    ];

    const arc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);
    svg.append('g')
      .attr('transform', `translate(${r},${r})`)
      .selectAll('path')
      .data(pie(currentData))
      .join('path')
      .attr('fill', d => d.data.color)
      .attr('d', arc);

    // Goal line
    if (showGoalToggle && goal >= 0) {
      const goalPercentage = interval ? ((goal - interval.min) / (interval.max - interval.min)) : goal;
      const goalLineMiddle = (goalPercentage === 0 ? 1 : goalPercentage) - (LINE_SIZE / 2);
      const goalData = [
        { value: goalLineMiddle, color: 'transparent' },
        { value: LINE_SIZE, color: DARK_GRAY },
        { value: 1 - goalLineMiddle - LINE_SIZE, color: 'transparent' },
      ];
      const goalArc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);
      svg.append('g')
        .attr('transform', `translate(${r},${r})`)
        .selectAll('path')
        .data(pie(goalData))
        .join('path')
        .attr('fill', d => d.data.color)
        .attr('d', goalArc);

      let goalLineRadAngle = -(goalPercentage * (2 * Math.PI)) + (Math.PI);

      let labelRotationAngle = -((goalLineRadAngle * 180) / Math.PI);
      labelRotationAngle += goalPercentage < 0.5 ? 90 : -90;

      const labelSize = innerRadius / 6;

      const labelPosRadius = calculateGoalLabelPos(goalPercentage, innerRadius, outerRadius, labelSize);

      const goalLabelPadding = isTile ? GOAL_LABEL_PADDING_TILE : GOAL_LABEL_PADDING_WIDGET;

      // adjustment to the label's position for padding between the goal line & the goal value's label
      goalLineRadAngle += goalPercentage < 0.5 ? Math.asin(goalLabelPadding / labelPosRadius)
        : -Math.asin(goalLabelPadding / labelPosRadius);

      const labelPosX = outerRadius + (labelPosRadius * Math.sin(goalLineRadAngle));
      const labelPosY = outerRadius + (labelPosRadius * Math.cos(goalLineRadAngle));

      svg.append('text')
        .attr('transform', `translate(${labelPosX},${labelPosY}) rotate(${labelRotationAngle})`)
        .attr('font-size', `${labelSize}px`)
        .attr('fill', textColor)
        .text(getFormattedValue(goal));
    }

    // Number
    svg.append('text')
      .attr('x', outerRadius)
      .attr('y', outerRadius)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .attr('font-size', '20px')
      .attr('fill', textColor)
      .text(getFormattedValue(currentValue));

    // Title
    if (!isTile) {
      svg.append('text')
        .attr('transform', `translate(${r},${r - innerRadius * 0.6})`)
        .attr('font-size', `${getApproximateFontSize(title, innerRadius * 1.8, innerRadius * 0.8)}px`)
        .attr('fill', textColor)
        .text(title);
    }
  };

  useEffect(() => {
    createCircularChart();
  }, []);

  useEffect(() => {
    createCircularChart();
  }, [textColor, goal, showGoalToggle, currentValue, units, title, interval]);

  return (
    <svg
      viewBox="0 0 100 100"
      ref={node}
      height={dimension.height}
      width={dimension.width}
      x={dimension.x}
      y={dimension.y}
      style={style}
    />
  );
};

CircularChart.propTypes = {
  currentValue: PropTypes.number,
  showGoalToggle: PropTypes.bool.isRequired,
  goal: PropTypes.number,
  aboveGoalColor: PropTypes.string,
  belowGoalColor: PropTypes.string,
  height: PropTypes.number,
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  style: PropTypes.shape(),
  isTile: PropTypes.bool,
  interval: PropTypes.object,
  metric: PropTypes.string,
  units: PropTypes.string,
  dimension: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
};
CircularChart.defaultProps = {
  currentValue: 0,
  title: '',
  goal: 0,
  aboveGoalColor: BLUE,
  belowGoalColor: BLUE,
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  style: {},
  isTile: false,
};

export default CircularChart;

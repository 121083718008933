/**
 * Use a LocalStorage item as an object
 * @param {string} keyName the name of the item in LocalStorage we want to use as an object
 * @returns {Object}
 */
export const getLocalStorageObject = keyName => {
  try {
    return JSON.parse(localStorage.getItem(keyName) || '');
  } catch (error) {
    return {};
  }
};

/**
 * A function generator to help with using LocalStorage item as an object
 * @param {string} keyName the name of the item in LocalStorage we want to use as an object
 * @returns {(key: string, value: any) => void}
 */
export const getLocalStorageSetter = keyName => (key, value) => {
  const storage = getLocalStorageObject(keyName);
  storage[key] = value;
  localStorage.setItem(keyName, JSON.stringify(storage));
};

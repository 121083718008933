import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import { initializeSelectedUsers } from '~components/SelectionGrid';
import { reduxOperations } from '~services/index';
import {
  CancelButton, DefaultModal, MultiSelect, SubmitButton, TagsInput,
} from '~components/UI';
import { getFormData } from '~utils/getFormData';
import { configurationFeature } from '~utils/featureToggles';
import { idsToTags } from '~utils/tags';
import { showError, showSuccess } from '~utils/toast';
import { sortArray } from '~utils/sort';
import { RootState } from '~services/store';
import { Folder } from '~services/folders/types';

const ReportPopupForm = ({ modifiedItemId, onHide, show }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reports = useSelector((state: RootState) => state.reports.reports);
  const folders = useSelector((state: RootState) => state.folders);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const userId = useSelector((state: RootState) => state.session.connexion.infos.id);
  const users = useSelector((state: RootState) => state.users.users);

  const report = reports?.find(r => r.id === modifiedItemId);

  const [folder, setFolder] = useState(report?.folderId);
  const [reportTags, setReportTags] = useState(report && tags ? idsToTags(report.tags, tags) : []);
  const [selectedUsers, setSelectedUsers] = useState<{ value: string }[]>([]);

  const updateUsersPermissions = id => {
    const usersWithPermission = [{ value: userId }, ...selectedUsers];

    const permissionsData = {
      users: usersWithPermission.map(user => user.value),
      content: {
        type: 'Report',
        id,
      },
    };

    if (configurationFeature.isUserAllowedAccessUsers()) {
      reduxOperations.users.updateUsersContentsPermissions(permissionsData)(dispatch).then(() => {
        dispatch(reduxOperations.users.fetchAllUsersPermissions() as any);
      });
    }
  };

  const handleOnHide = () => {
    onHide();
    setFolder(report?.folderId);
    setReportTags(report && tags ? idsToTags(report.tags, tags) : []);
    setSelectedUsers([]);
  };

  const handleSubmit = e => {
    if (e) { e.preventDefault(); }

    const formData = getFormData('reportForm');
    formData.tags = reportTags;

    if (formData.folderId === '-') {
      formData.folderId = '';
    }

    if (!formData.name) {
      showError(t('missingName'));
    } else if (!formData.url) {
      showError(t('missingURL'));
    } else if (modifiedItemId) {
      reduxOperations.reports.updateReport(modifiedItemId, formData)(dispatch)
        .then(() => {
          showSuccess(t('operationSucceeded'));
          dispatch(reduxOperations.tags.forceFetchTags() as any);
          updateUsersPermissions(modifiedItemId);
          handleOnHide();
        })
        .catch(() => {
          showError(t('error'));
        });
    } else {
      reduxOperations.reports.addReport(formData)(dispatch)
        .then(res => {
          showSuccess(t('operationSucceeded'));
          dispatch(reduxOperations.tags.forceFetchTags() as any);
          updateUsersPermissions(res.payload.report.id);
          handleOnHide();
        })
        .catch(() => {
          showError(t('error'));
        });
    }
  };

  useEffect(() => {
    if (modifiedItemId) {
      setSelectedUsers(initializeSelectedUsers('Report', modifiedItemId, users, userId));
    }
  }, [modifiedItemId, users]);

  const usersToDisplay = users ? users.filter(user => user.display && user.id !== userId) : [];
  const usersOptions = usersToDisplay.map(user => ({
    label: user.name,
    value: user.id,
  }));

  return (
    <DefaultModal
      title={modifiedItemId ? t('editReport') : t('createNewReport')}
      show={show}
      closePopup={handleOnHide}
    >
      <form
        id="reportForm"
        onSubmit={handleSubmit}
      >
        <div className="inputTitle">{t('name')}</div>
        <input
          type="text"
          name="name"
          className="fullwidth"
          defaultValue={report?.name}
        />

        <div className="inputTitle">{t('description')}</div>
        <input
          type="text"
          name="description"
          className="fullwidth"
          defaultValue={report?.description}
        />

        <div className="inputTitle">URL</div>
        <input
          type="text"
          name="url"
          className="fullwidth"
          defaultValue={report?.url}
        />

        <div className="inputTitle">{`${t('image')} URL`}</div>
        <input
          name="backgroundURL"
          type="text"
          defaultValue={report?.backgroundURL}
          className="fullwidth"
        />

        {folders && (
          <>
            <div className="inputTitle">{t('folder')}</div>
            <Select
              className="fullwidth"
              name="folderId"
              value={folder || '-'}
              onChange={setFolder}
              options={[{ label: '-', value: '-' },
                ...sortArray('alphabetically', folders, 'name')?.map((f: Folder) => ({ label: f.name, value: f.id }))]}
            />
          </>
        )}

        {
          configurationFeature.isUserAllowedAccessUsers() && (
            <>
              <div className="inputTitle">{t('UserPermissions')}</div>
              <MultiSelect
                options={usersOptions}
                value={selectedUsers}
                onChange={options => setSelectedUsers(options)}
              />
            </>
          )
        }

        <div className="inputTitle">{t('tags')}</div>
        <TagsInput
          tags={tags}
          currentTags={reportTags}
          modifyTags={newTags => setReportTags(newTags)}
        />
        {modifiedItemId && (
          <div style={{ margin: '1rem 0' }}>
            <div style={{ fontSize: '17px' }}>
              <div
                style={{ margin: '0.7rem 0' }}
                title={report?.createdAt && DateTime.fromMillis(report?.createdAt).toISO()}
              >
                {t('createdBy')}
                &nbsp;
                <b>{users?.find(user => user.id === report?.createdBy)?.name}</b>
                &nbsp;
                {report?.createdAt && DateTime.fromMillis(report.createdAt).toRelative()}
              </div>
              <div
                style={{ margin: '0.7rem 0' }}
                title={report.modifiedAt && DateTime.fromMillis(report.modifiedAt).toISO()}
              >
                {t('modifiedBy')}
                &nbsp;
                <b>{users?.find(user => user.id === report.createdBy)?.name}</b>
                &nbsp;
                {report.modifiedAt && DateTime.fromMillis(report.modifiedAt).toRelative()}
              </div>
            </div>
          </div>
        )}
        <div
          className="buttonsHolder"
          style={{ textAlign: 'right' }}
        >
          <CancelButton onClick={handleOnHide} />
          <SubmitButton
            label={t('save')}
          />
        </div>
      </form>
    </DefaultModal>
  );
};

ReportPopupForm.propTypes = {
  modifiedItemId: PropTypes.string,
  onHide: PropTypes.func,
  show: PropTypes.bool,
};
ReportPopupForm.defaultProps = {
  modifiedItemId: '',
  onHide: () => null,
  show: false,
};

export { ReportPopupForm };

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IdleTimer from 'react-idle-timer';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import { getMachineStateInformations } from '~components/MachineState';
import { FontAwesome, MachineEventPopup, PerformanceEventPopup, DefectEventPopup } from '~UI';
import { getAlives } from '~services/alives/endpoints';
import { getSocket } from '~services/socket';
import API from '~services/endpoints';
import { reduxOperations, reducersTypes } from '~services';
import { formatDuration, serverTime } from '~utils/time';
import directLinks from '~utils/directLinks';
import { getMachineStateSize } from '~utils/responsiveValue';
import Tick from '~utils/Tick';
import { MAX_WIDTH_TABLET, DEFAULT_ALIVE_DELAY } from '~utils/constants';
import { MachineParameter } from './MachineParameter';
import { useShift } from '~utils/hooks';
import { MachineOperatorPopup } from '~components/UI';
import TileContents from '../TileContents';

const MachineStateTile = ({ tile, height, width }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const machines = useSelector(state => state.machines);
  const settings = useSelector(state => state.settings.settings);
  const isInConfigurationMode = useSelector(state => state.views.isInConfigurationMode);
  const language = useSelector(state => state.views.language);
  const showAlive = useSelector(state => state.featureToggles.featureToggles.buttons.showAlive);

  const [currentShift] = useShift(tile.machineId);

  const machine = machines.find(m => m.id === tile.machineId);

  const [cancelAlives, setCancelAlives] = useState(null);
  const [cancelEvents, setCancelEvents] = useState(null);
  const [cancelPerformanceEvents, setCancelPerformanceEvents] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [downtimePopupEvent, setDowntimePopupEvent] = useState(null);
  const [showDowntimePopup, setShowDowntimePopup] = useState(false);
  const [lastAlive, setLastAlive] = useState(null);
  const [currentTS, setCurrentTS] = useState(serverTime());
  const [machineParams, setMachineParams] = useState(machine?.params);
  const [performancePopupEvent, setPerformancePopupEvent] = useState(null);
  const [showPerformancePopup, setShowPerformancePopup] = useState(false);
  const [defectPopupEvent, setDefectPopupEvent] = useState(null);
  const [showDefectPopup, setShowDefectPopup] = useState(false);
  const [hasUnmounted, setHasUnmounted] = useState(false);
  const [currentMachine, setCurrentMachine] = useState(machine);
  const [showMachineOperatorPopup, setShowMachineOperatorPopup] = useState(false);
  const [operatorPopupEvent, setOperatorPopupEvent] = useState(null);

  const idleTimerRef = useRef(null);
  const currentEventRef = useRef(currentEvent);
  const downtimePopupEventRef = useRef(downtimePopupEvent);

  const handleRedirect = () => {
    dispatch(reduxOperations.redirectHistory.redirectHistoryPush(window.location.pathname));
  };

  const fetchCurrentEvent = async () => {
    const filter = {
      type: 'MachineStatus',
      machineId: tile.machineId,
      timestamp: {
        $lte: serverTime(),
      },
    };
    const sort = { timestamp: -1 };
    const { events: newEvents } = await API.getEvents(filter, sort, 1);
    if (!hasUnmounted) {
      const curEvent = newEvents[0];
      setCurrentEvent(curEvent);
      setCancelEvents(undefined);
      setMachineParams(prevParams => ({
        ...prevParams,
        operation: curEvent?.operation,
        operator: curEvent?.operator,
        workOrder: curEvent?.workOrder,
        skuNumber: curEvent?.skuNumber,
      }));
    }
    setCancelEvents(true);
  };

  const fetchCurrentPerformanceEvent = async () => {
    const filter = {
      type: 'PerformanceEvent',
      machineId: tile.machineId,
      timestamp: {
        $lte: currentTS,
      },
    };
    const sort = { timestamp: -1 };
    const { events: newEvents } = await API.getEvents(filter, sort, 1);
    if (!hasUnmounted) {
      if (newEvents.length) {
        setPerformancePopupEvent(newEvents[0]);
        setCancelPerformanceEvents(undefined);
      }
    }
    setCancelPerformanceEvents(true);
  };

  const fetchLastAlive = async () => {
    if (showAlive && machine) {
      const filter = {};
      const sort = { timestamp: -1 };
      const { alives: newAlives } = await getAlives(machine.streamId, filter, sort, 1);
      if (!hasUnmounted) {
        setLastAlive(newAlives[0]);
        setCancelAlives(undefined);
      }
      setCancelAlives(true);
    }
  };

  const handleSocketAlive = alive => {
    if (currentMachine && alive.streamId === currentMachine.streamId) {
      setLastAlive(alive);
    }
  };

  const handleSocketEvent = event => {
    if (event.machineId === tile.machineId) {
      if (event.type === 'MachineStatus') {
        if (!currentEventRef.current
          || event.id === currentEventRef.current.id
          || event.timestamp > (currentEventRef.current.timestamp)) {
          setCurrentEvent(event);

          if (event.timestamp > (lastAlive)) {
            setLastAlive(event.timestamp);
          }

          if (event.status === 'OFF' && tile.checkboxPopup) {
            setDowntimePopupEvent(event);
            setShowDowntimePopup(false);
          }

          if (tile.checkboxPopup
            && (event.status === (tile.showPopupAtEventStatus || 'ON') || tile.showPopupAtEventStatus === 'BOTH')
            && !event.stopCauseId
            && (tile.showPopupAtEventStatus === 'ON' ? downtimePopupEventRef.current && !downtimePopupEventRef.current.stopCauseId : true)) {
            setShowDowntimePopup(true);
          }
        }
      }

      if (event.type === 'PerformanceEvent' && !event.performanceCauseId) {
        if (event.status === 'START') {
          if (tile.showPerformanceCausePopupAtEventStatus === 'START') {
            setPerformancePopupEvent(event);
            setShowPerformancePopup(true);
          } else {
            setPerformancePopupEvent(event);
            setShowPerformancePopup(false);
          }
        } else if (event.status === 'END' && tile.showPerformanceCausePopupAtEventStatus === 'END') {
          setShowPerformancePopup(true);
        }
      }

      if (event.type === 'PartEvent' && event.eventType === 'SCRAP' && !event.defectCauseId) {
        if (tile.showDefectCausePopupAtEventStatus === 'SCRAP') {
          setDefectPopupEvent(event);
          setShowDefectPopup(true);
        } else {
          setDefectPopupEvent(event);
          setShowDefectPopup(false);
        }
      }
    }
  };

  const updateTime = () => {
    setCurrentTS(serverTime());
  };

  const handleSocketEventDeleted = event => {
    if (event.type === 'MachineStatus' && event.machineId === tile.machineId && event.id === currentEventRef.current.id) {
      fetchCurrentEvent();
    }
  };

  const handleSocketMachines = message => {
    if (message.type === 'MACHINE_PARAM_UPDATE' && message.machineId === tile.machineId) {
      setMachineParams(message.params);
    }
  };

  const handleClickDashboard = () => {
    handleRedirect(location.pathname);
    history.push(directLinks.dashboard(tile.dashboard, location.pathname));
  };

  const onDowntimePopupHide = () => {
    if (tile.showPopupAtEventStatus === 'BOTH') {
      setShowDowntimePopup(false);
      return;
    }
    setDowntimePopupEvent(null);
    setShowDowntimePopup(false);
  };

  const onPerformancePopupHide = () => setPerformancePopupEvent(null);

  const onDefectPopupHide = () => setDefectPopupEvent(null);

  const onMachineOperatorPopupHide = () => setShowMachineOperatorPopup(false);

  const attachEventListeners = socket => {
    socket?.on('alive', handleSocketAlive);
    socket?.on('event', handleSocketEvent);
    socket?.on('eventDeleted', handleSocketEventDeleted);
    socket?.on('machines', handleSocketMachines);
  };

  const removeEventListeners = socket => {
    socket?.removeListener('alive', handleSocketAlive);
    socket?.removeListener('event', handleSocketEvent);
    socket?.removeListener('eventDeleted', handleSocketEventDeleted);
    socket?.removeListener('machines', handleSocketMachines);
  };

  const handleOnActive = () => {
    fetchCurrentEvent();
    fetchCurrentPerformanceEvent();
    fetchLastAlive();
  };

  const handleOnIdle = () => {
    fetchCurrentEvent();
    fetchCurrentPerformanceEvent();
    fetchLastAlive();
    idleTimerRef.current.reset();
  };

  useEffect(() => {
    const socket = getSocket();
    fetchCurrentEvent();
    fetchCurrentPerformanceEvent();
    fetchLastAlive();
    Tick.subscribe(updateTime, 1);
    attachEventListeners(socket);

    return () => {
      removeEventListeners(socket);
      if (cancelAlives) {
        setCancelAlives();
      }
      if (cancelEvents) {
        setCancelEvents();
      }
      if (cancelPerformanceEvents) {
        setCancelPerformanceEvents();
      }
      Tick.unsubscribe(updateTime);
      setHasUnmounted(true);
    };
  }, []);

  useEffect(() => {
    const newMachine = machines.find(m => m.id === tile.machineId);
    setCurrentEvent(null);
    setLastAlive(null);
    setCurrentMachine(newMachine);
    setMachineParams(newMachine && newMachine.params);

    fetchCurrentEvent();
    fetchCurrentPerformanceEvent();
    fetchLastAlive();
  }, [tile.machineId]);

  const previousShiftRef = useRef();

  useEffect(() => {
    if (previousShiftRef.current && JSON.stringify(previousShiftRef.current) !== JSON.stringify(currentShift)) {
      setOperatorPopupEvent(currentShift);
      setShowMachineOperatorPopup(true);
    }
    previousShiftRef.current = currentShift;
  }, [currentShift]);

  useEffect(() => {
    currentEventRef.current = currentEvent;
  }, [currentEvent]);

  useEffect(() => {
    downtimePopupEventRef.current = downtimePopupEvent;
  }, [downtimePopupEvent]);

  const { color, icon, timeInMS, text } = getMachineStateInformations(
    tile.aliveDelay || DEFAULT_ALIVE_DELAY,
    currentMachine?.stopCauses,
    currentEvent,
    lastAlive,
    settings.defaultUnfilledStopCauseColor,
    showAlive,
    language,
  );

  const timer = formatDuration(timeInMS, {
    year: true,
    month: true,
    day: true,
    hourSeparator: ':',
    minSeparator: ':',
    sec: true,
  }, false, language, true);

  const orderedParams = ['operator', 'workOrder', 'operation', 'skuNumber'];
  const sortedParams = orderedParams.filter(param => tile.shownMachineParams.includes(param) && (machineParams[param] || machineParams[param] === '')).map(p => ({
    name: p,
    readOnly: (p === 'skuNumber' && machineParams.operation) || tile.readOnlyMachineParams.includes(p),
    value: machineParams[p],
  }));

  if (!machine) {
    return (
      <h3>{t('machineIsNotConfiguredOrDeleted')}</h3>
    );
  }

  const {
    iconSize,
    timeSize,
    rearranged,
  } = getMachineStateSize(height, width, timer.length - 2, tile.shownMachineParams);
  const marginTop = window.innerWidth <= MAX_WIDTH_TABLET ? 18 : 30;
  const iconFontSize = tile.contentSize || (rearranged && timeSize) || iconSize;
  const timeFontSize = tile.contentSize || timeSize;
  const stopCauseFontSize = tile.contentSize || timeSize * 0.9;

  const now = DateTime.now();
  const isStPatricksDay = now.month === 3 && now.day === 17;

  return (
    <TileContents
      tile={tile}
      backgroundColor={color}
      extraHeaders={[
        tile.checkboxDashboard && (
          <button
            className="btn btn_dashboard"
            onClick={() => handleClickDashboard(history, location, tile)}
            style={{ zIndex: 5 }}
            type="button"
          >
            <FontAwesome icon="sign-out-alt" size="lg" />
          </button>
        ),
      ]}
      height={height}
      width={width}
    >
      <MachineOperatorPopup
        show={!!operatorPopupEvent && !isInConfigurationMode && showMachineOperatorPopup
          && tile.showOperatorPopupAtShiftChange}
        onHide={onMachineOperatorPopupHide}
        machineParams={machineParams}
        machineId={tile.machineId}
      />
      <MachineEventPopup
        show={!!downtimePopupEvent && !isInConfigurationMode && showDowntimePopup}
        onHide={onDowntimePopupHide}
        event={downtimePopupEvent}
        machineId={tile.machineId}
        mandatoryStopCause={tile.mandatoryStopCause}
      />
      <PerformanceEventPopup
        show={!!performancePopupEvent && !isInConfigurationMode && showPerformancePopup}
        onHide={onPerformancePopupHide}
        event={performancePopupEvent}
        machineId={tile.machineId}
      />
      <DefectEventPopup
        show={!!defectPopupEvent && !isInConfigurationMode && showDefectPopup}
        onHide={onDefectPopupHide}
        event={defectPopupEvent}
        machineId={tile.machineId}
      />
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <div className="framed flex V MachineState">
        {tile.showTimer && (
          <div>
            {iconSize > 8 && (
              <div
                className={
                  classnames('MachineState__Icon', {
                    inlineBlock: rearranged,
                    rearranged,
                  })
                }
                style={{
                  ...(isStPatricksDay && { color: '#30f966' }), // Easter egg: St-Patrick
                  fontSize: `${iconFontSize}px`,
                }}
              >
                <FontAwesome icon={icon} />
              </div>
            )}
            <div
              className={
                classnames({
                  inlineBlock: rearranged,
                })
              }
              style={{
                fontSize: `${timeFontSize}px`,
                marginTop: iconSize > 8 && tile.shownMachineParams.length ? 0 : marginTop,
              }}
            >
              {timer}
            </div>
          </div>
        )}
        {tile.showStopCause && (
          <div
            style={{ fontSize: `${stopCauseFontSize}px` }}
            data-testid="machineStateTile-stopCause"
          >
            {text}
          </div>
        )}
        {tile.shownMachineParams.length > 0 && (
          <div
            className={
              classnames('MachineState__Params', {
                flex: !rearranged,
                H: !rearranged,
              })
            }
            data-testid="machineStateTile-paramsList"
          >
            {
              sortedParams
                .map(p => (
                  <MachineParameter
                    key={p.name}
                    parameter={p}
                    machineId={tile.machineId}
                    machineParams={machineParams}
                    rearranged={rearranged}
                    height={height}
                    width={width}
                  />
                ))
            }
          </div>
        )}
      </div>
    </TileContents>
  );
};

MachineStateTile.propTypes = {
  height: PropTypes.number.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
  width: PropTypes.number.isRequired,
};

export default MachineStateTile;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import { PageTemplate } from '~components/Pages';
import { ResourcesHandler } from '~UI/index';
import './ReportPage.scss';

const ReportPage = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state: RootState) => state.reports.reports);

  const { selectedReport } = useParams<{ selectedReport: string; }>();
  const [iframeLoading, setLoading] = useState(true);
  const [report, setReport] = useState<any>();

  useEffect(() => {
    if (reports) {
      const currentReport = reports.find(r => r.id === selectedReport);
      setReport(currentReport);
    }
  }, [reports, selectedReport]);

  const getContents = () => (
    <PageTemplate
      footer
      pageTemplateClass="flex V justifyStart"
      noNPSPopup
      sidebar
    >
      <div className="ReportPage">
        {(!report || iframeLoading) && (<Loader />)}
        <div className="IFrame framed flex H">
          <iframe
            title={report?.name || 'Report'}
            className="report"
            src={report?.url}
            style={{
              border: 0,
              width: '100vw',
            }}
            onLoad={() => setLoading(false)}
          />
        </div>
      </div>
    </PageTemplate>
  );

  return (
    <ResourcesHandler
      resources={[reports]}
      resourceFetchers={[
        () => dispatch(reduxOperations.reports.fetchReports() as any),
      ]}
      getContents={getContents}
    />
  );
};

export { ReportPage };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Select, Loader } from '@intelligenceindustrielle/react-ui-components';
import { Image } from '@mantine/core';
import { RootState } from '~services/store';
import { SubmitButton, FontAwesome, TextArea } from '~UI/index';
import API from '~services/endpoints';
import './ImageGenerationForm.scss';
import { AudioRecorder } from '~components/UI/AudioRecorder/AudioRecorder';

interface ImageInfos {
  data: string;
  contentType: string;
  name: string;
}

const ImageGenerationForm: React.FC<{ setFile: (file: object) => void }> = ({ setFile }) => {
  const { t } = useTranslation();

  const settings = useSelector((state: RootState) => state.settings.settings);
  const modelOptions = settings?.imagePrompts?.map(assistant => ({ value: assistant.name, label: t(assistant.name) }));
  const [prompt, setPrompt] = useState('');
  const [model, setModel] = useState('');
  const [images, setImages] = useState<ImageInfos[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const onImageSelect = (image: ImageInfos) => {
    setCurrentIndex(images.indexOf(image));
    setSelectedIndex(images.indexOf(image));
    setFile(image);
  };

  const fetchImageFromDalle = async () => {
    if (!prompt || isLoading) {
      return;
    }
    setIsLoading(true);
    const response = await API.sendImagePrompt(model, prompt);
    if (!response.image) {
      return;
    }
    const imageInfo: ImageInfos = {
      data: response.image,
      contentType: 'image/png;base64',
      name: `generated-${new Date().toISOString()}.png`,
    };
    setImages([imageInfo, ...images]);
    setCurrentIndex(0);
    setSelectedIndex(0);
    setFile(imageInfo);
    setIsLoading(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    fetchImageFromDalle();
  };

  return (
    <div
      id="ImageGeneration__form"
    >
      <div className="ImageGenerationContainer">
        <div className="model">
          <div className="modelTitle">{t('assistantAI')}</div>
          <Select
            options={modelOptions}
            value={model}
            onChange={setModel}
            placeholder={t('selectAssistant')}
          />
        </div>
        <div className="prompt">
          <TextArea
            value={prompt}
            onChange={setPrompt}
            placeholder={t('generateImageDescription')}
          />
          <div className="promptFooter">
            <div className="buttonContainer">
              {prompt && model && !isLoading && (<SubmitButton onClick={handleSubmit} />)}
              {isLoading && <Loader width={40} height={40} />}
            </div>
          </div>
        </div>
        <div className="body">
          {
            !images.length
            && (
              <>
                <FontAwesome icon="sparklesGradient" height="50px" />
                <h1>{t('generateImageTitle')}</h1>
              </>
            )
          }
          <div className="image-gallery">
            {images.length > 1 && (
              <>
                <button type="button" onClick={handlePrev}>
                  <FontAwesome icon="arrow-left" />
                </button>
                <div
                  className="image-container"
                  onClick={() => onImageSelect(images[(currentIndex - 1 + images.length) % images.length])}
                  style={{
                    transform: 'scale(0.65)',
                    zIndex: 0,
                  }}
                >
                  <div className={selectedIndex === (currentIndex - 1 + images.length) % images.length ? 'selected' : ''}>
                    <Image
                      src={`data:image/png;base64,${images[(currentIndex - 1 + images.length) % images.length].data}`}
                      alt="Generated"
                    />
                  </div>
                  {selectedIndex === (currentIndex - 1 + images.length) % images.length && (
                    <div className="selectedText">
                      {t('selected')}
                    &nbsp;
                      <FontAwesome icon="check" />
                    </div>
                  )}
                </div>
              </>
            )}
            {images.length > 0 && (
              <div
                className="image-container"
                onClick={() => onImageSelect(images[currentIndex])}
                style={{
                  zIndex: 1,
                }}
              >
                <div className={selectedIndex === currentIndex ? 'selected' : ''}>
                  <Image
                    src={`data:image/png;base64,${images[currentIndex].data}`}
                    alt="Generated"
                    width={500}
                    height={500}
                  />
                </div>
                {selectedIndex === currentIndex && (
                  <div className="selectedText">
                    {t('selected')}
                    &nbsp;
                    <FontAwesome icon="check" />
                  </div>
                )}
              </div>
            )}
            {images.length > 1 && (
              <>
                <div
                  className="image-container"
                  onClick={() => onImageSelect(images[(currentIndex + 1) % images.length])}
                  style={{
                    transform: 'scale(0.65)',
                    zIndex: 0,
                  }}
                >
                  <div className={selectedIndex === (currentIndex + 1) % images.length ? 'selected' : ''}>
                    <Image
                      src={`data:image/png;base64,${images[(currentIndex + 1) % images.length].data}`}
                      alt="Generated"
                    />
                  </div>
                  {selectedIndex === (currentIndex + 1 + images.length) % images.length && (
                    <div className="selectedText">
                      {t('selected')}
                    &nbsp;
                      <FontAwesome icon="check" />
                    </div>
                  )}
                </div>
                <button type="button" onClick={handleNext}>
                  <FontAwesome icon="arrow-right" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGenerationForm;

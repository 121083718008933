import React from 'react';
import PropTypes from 'prop-types';
import { CircularChart } from '~UI/svg';
import { invertColor } from '~utils/index';

const CircularChartWidget = ({
  backgroundColor, h, w, x, y, widget, fetchDelay,
}) => (
  <>
    <svg x={x} y={y} height={h} width={w} viewBox="0 0 100 100">
      <circle cx={50} cy={50} r={50} fill={backgroundColor} stroke="none" />
    </svg>
    <CircularChart
      dimension={{ height: h, width: w, x, y }}
      fetchDelay={fetchDelay}
      selectedObject={widget}
      textColor={invertColor(backgroundColor)}
    />
    <svg x={x} y={y} height={h} width={w} viewBox="0 0 100 100">
      <circle cx={50} cy={50} r={50} fillOpacity="0.0" stroke="none" />
    </svg>
  </>
);

CircularChartWidget.propTypes = {
  backgroundColor: PropTypes.string,
  fetchDelay: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    title: PropTypes.string,
    showGoalToggle: PropTypes.bool,
    goal: PropTypes.number,
    machineId: PropTypes.string,
    valueType: PropTypes.string,
  }).isRequired,
};
CircularChartWidget.defaultProps = {
  backgroundColor: '',
};

export default CircularChartWidget;

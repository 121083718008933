import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';
import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import API from '~services/endpoints';
import { AudioRecorder } from '~components/UI/AudioRecorder/AudioRecorder';
import { RootState } from '~services/store';
import { DefaultModal } from '~UI/DefaultModal';
import './ChatPopup.scss';
import { FontAwesome } from '~components/UI';

type MessageProps = {
  role: 'user' | 'assistant' | 'code';
  text: string;
};

type AssistantProps = {
  id: string;
  name: string;
  type: string;
};

type RequestBody = {
  prompt: any;
  machines?: object;
  clientName?: string;
};

const UserMessage = ({ text }: { text: string }) => <div className="userMessage">{text}</div>;

const md = new MarkdownIt();

const AssistantMessage = ({ text }: { text: string }) => {
  const markdownHtml = md.render(text);

  return (
    <div className="assistantMessage">
      {React.createElement('div', { dangerouslySetInnerHTML: { __html: markdownHtml } })}
    </div>
  );
};

const Message = ({ role, text }: MessageProps) => {
  const { t } = useTranslation();
  const [dots, setDots] = useState('');

  useEffect(() => {
    if (text === t('typing') || text === t('initializingChat')) {
      const interval = setInterval(() => {
        setDots(prevDots => (prevDots.length < 3 ? `${prevDots}.` : ''));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [text]);

  if (text === t('typing') || text === t('initializingChat')) {
    return (
      <div className="typingIndicator">
        {text}
        {dots}
      </div>
    );
  }

  switch (role) {
    case 'user':
      return <UserMessage text={text} />;
    case 'assistant':
      return <AssistantMessage text={text} />;
    default:
      return null;
  }
};

const Chat = ({ show, closePopup }) => {
  const aiAssistants = useSelector((state: RootState) => state.settings.settings.aiAssistants);
  const machines = useSelector((state: RootState) => state.machines);
  const clientName = useSelector((state: RootState) => state.settings.settings.googleAnalytics.clientName);
  const clientDescription = useSelector((state: RootState) => state.settings.settings.clientDescription);

  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<string | null>(null);
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [threadId, setThreadId] = useState('');
  const [assistant, setAssistant] = useState<AssistantProps>({ id: '', name: '', type: '' });
  const [makeResponse, setMakeResponse] = useState<any>(null);
  const { t } = useTranslation();

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  useEffect(() => {
    setMessages([]);
    setThreadId('');
    setSelectedFile(null);
    setFileData(null);
    if (assistant.id !== '' && assistant.type === 'gpt') {
      setInputDisabled(true);
      setMessages([{ role: 'assistant', text: `${t('initializingChat')}` }]);
      API.initializeChat(assistant.id).then(response => {
        setThreadId(response.threadId);
        setMessages([]);
        setInputDisabled(false);
      }).catch(error => console.error('Error initializing chat:', error));
    }
  }, [assistant]);

  const sendMessageGPT = async text => {
    API.sendMessageGPT(text, assistant.id, threadId).then(response => {
      setMessages(response.messages);
      setInputDisabled(false);
    }).catch(error => console.error('Error sending message:', error));
  };

  const sendMessageMake = async text => {
    let requestBody: RequestBody = { prompt: text, ...makeResponse };

    if (messages.length === 0) {
      requestBody = {
        ...requestBody,
        clientName,
        machines,
      };
    }
    API.sendMessageMake(requestBody, assistant.id, fileData).then(response => {
      setMakeResponse(response);
      const newMessage = { role: 'assistant', text: response.result };
      setMessages(prevMessages => {
        const filteredMessages = prevMessages.filter(message => message.text !== t('typing'));
        return [...filteredMessages, newMessage] as MessageProps[];
      });
      setInputDisabled(false);
    }).catch(error => console.error('Error sending message:', error));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!userInput.trim()) return;
    setMessages(prevMessages => [
      ...prevMessages,
      { role: 'user', text: userInput },
      { role: 'assistant', text: `${t('typing')}` },
    ]);
    if (assistant.type === 'gpt') {
      await sendMessageGPT(userInput);
    } else if (assistant.type === 'make') {
      await sendMessageMake(userInput);
    }
    setUserInput('');
    setInputDisabled(true);
    scrollToBottom();
  };

  const handleFileChange = file => {
    setSelectedFile(file);

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const binaryData = reader.result as ArrayBuffer;
      const base64Data = btoa(String.fromCharCode(...new Uint8Array(binaryData)));
      setFileData(base64Data);
    };
  };

  return (
    <DefaultModal
      closePopup={closePopup}
      headless
      show={show}
      children={(
        <>
          <div className="chatTitle">
            {t('assistantAI')}
          </div>
          <div className="breakLine" />
          <div>
            <Select
              name="valueId"
              options={aiAssistants?.map(a => ({ label: a.name, value: a }))}
              onChange={e => setAssistant(e)}
              placeholder={t('selectAssistant')}
              style={{ width: '230px', paddingTop: '10px' }}
            />
          </div>
          <div className="chatContainer">
            <div className="messages">
              {messages?.map((msg, index) => (
                <Message key={index} role={msg.role} text={msg.text} />
              ))}
              <div ref={messagesEndRef} />
            </div>
            <form
              onSubmit={handleSubmit}
              className="inputForm clearfix"
            >
              <div className="inputWrapper">
                <input
                  type="text"
                  className="input"
                  value={userInput}
                  onChange={e => setUserInput(e.target.value)}
                  placeholder={t('typeMessage')}
                />
                <input
                  type="file"
                  id="chatFileUpload"
                  accept=",image/*"
                  className="fileInput"
                  onChange={e => {
                    const file = e?.target?.files?.[0];
                    if (file) {
                      handleFileChange(file);
                    }
                  }}
                />
                <label
                  htmlFor="chatFileUpload"
                  className="chatFileUpload"
                >
                  <FontAwesome className="chatFileUploadIcon" icon="paperclip" />
                </label>
              </div>
              <div className="audioChat">
                <AudioRecorder setText={text => setUserInput(userInput + text)} />
              </div>
              <button
                type="submit"
                className="button"
                disabled={assistant.id === '' || inputDisabled}
              >
                {t('send')}
              </button>
            </form>
            {selectedFile && (
              <div className="filePreview" onClick={() => openModal(URL.createObjectURL(selectedFile))}>
                <p className="filePreviewName">
                  {selectedFile.name}
                </p>
              </div>
            )}
            {modalImage && (
              <DefaultModal
                show={!!modalImage}
                closePopup={closeModal}
                title="Image Preview"
                fullSize
              >
                <div style={{ height: '80vh' }}>
                  <div className="framed flex V">
                    <img
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain',
                      }}
                      src={modalImage}
                      alt="Failed to load"
                    />
                  </div>
                </div>
              </DefaultModal>
            )}
          </div>
        </>
      )}
    />
  );
};

Chat.propTypes = {
  show: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default Chat;

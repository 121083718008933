import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Input } from '@intelligenceindustrielle/react-ui-components';
import RuleEditor from '~components/forms/Rule/RuleEditor';
import { FormStyle } from '~components/forms/FormStyle';
import { shouldDisplayFormContent } from '~components/forms/NavigationTabs';
import { reducersTypes, reduxOperations } from '~services/index';
import { CancelButton, SubmitButton, DeleteButton } from '~UI/index';
import TileForms from './FormTileType';

const TileForm = ({
  navSelected, handleSubmit, updateFunction, match, tile, tileType, onHide, noTitle,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [onCreationRules, setOnCreationRules] = useState([]);

  const Form = TileForms[tileType] || TileForms.empty;

  const handleDelete = () => {
    const { selectedDashboard } = match.params;
    return reduxOperations.dashboards.deleteDashboardTile(selectedDashboard, tile.id)(dispatch);
  };

  if (shouldDisplayFormContent(navSelected)) {
    return (
      <div style={{ flexGrow: 1 }}>
        <form id="Dashboard__Edition__Popup__Form" onSubmit={e => e.preventDefault()}>
          <div
            className={classnames({
              hiddenDiv: navSelected === 'style' || navSelected === 'color',
            })}
          >
            <div
              style={{ display: 'flex' }}
            >
              <div style={{ flexGrow: 1 }}>
                <div
                  className={classnames({
                    hiddenDiv: navSelected !== 'parameters',
                  })}
                >
                  {
                    !noTitle && (
                      <>
                        <div className="inputTitle">{t('title')}</div>
                        <Input
                          defaultValue={tile ? tile.title : ''}
                          name="title"
                        />
                      </>
                    )
                  }

                </div>
                <Form
                  selectedTile={tile}
                  tileType={tileType}
                  navSelected={navSelected}
                />
              </div>
            </div>
          </div>

          <div
            className={classnames({
              hiddenDiv: navSelected !== 'style',
            })}
          >
            <FormStyle
              selectedObject={tile}
              type={tileType}
              isTile
            />
          </div>
          {navSelected === 'color' && (
            <RuleEditor
              selectedObjectId={tile.id}
              rules={tile.rules}
              tileWidgetType={tile.type}
              type={navSelected}
              updateFunction={updateFunction}
              modifiedItemId={match.params.selectedDashboard}
              onCreationRules={onCreationRules}
              setOnCreationRules={setOnCreationRules}
            />
          )}
          {(!tile || navSelected !== 'color') && (
            <div className="buttonsHolder flexSpaceBetween">
              <div>
                {tile && tile.id && <DeleteButton handleDelete={handleDelete} askConfirmation />}
              </div>
              <div>
                <CancelButton onClick={onHide} />
                <SubmitButton onClick={e => { handleSubmit(e, onCreationRules); setOnCreationRules([]); }} />
              </div>
            </div>
          )}
          <input
            type="hidden"
            name="type"
            readOnly
            value={tileType}
          />
        </form>
      </div>
    );
  }

  return (
    <div>
      <RuleEditor
        selectedObjectId={tile.id}
        rules={tile.rules}
        tileWidgetType={tile.type}
        type={navSelected}
        updateFunction={updateFunction}
        modifiedItemId={match.params.selectedDashboard}
      />
    </div>
  );
};

TileForm.propTypes = {
  tile: reducersTypes.dashboards.tile.isRequired,
  navSelected: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateFunction: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  tileType: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  noTitle: PropTypes.bool,
};

export { TileForm };

import React from 'react';
import PropTypes from 'prop-types';
import { reducersTypes } from '~services/index';
import { invertColor } from '~utils/index';
import { getValueFontSizeNOPADDING } from '~utils/responsiveValue';

const getSplitText = text => text.split('\n');
const longestString = splitText => splitText.reduce((a, b) => (a.length > b.length ? a : b));

const getPosition = (height, index, totalLines) => {
  if (totalLines === 1) {
    return (height / 2);
  }
  return (height / totalLines) * (index + 0.5);
};

const TextWidgetComponent = ({
  x, y, w, h, content, backgroundColor, widget,
}) => {
  const { contentSize, title, zoom } = widget;
  const splittedText = getSplitText(content);
  const height = title ? h * 0.85 : h;
  const deltaY = title ? h * 0.18 : 0;
  const contentFontSize = contentSize
    || getValueFontSizeNOPADDING(longestString(splittedText), w, height, splittedText.length, zoom);

  return splittedText.map((line, index) => (
    <text
      x={x + w / 2}
      y={y + deltaY + getPosition(height, index, splittedText.length)}
      fill={invertColor(backgroundColor)}
      style={{ fontSize: `${contentFontSize}px`, fontWeight: 'bold' }}
    >
      {line}
    </text>
  ));
};

TextWidgetComponent.propTypes = {
  widget: reducersTypes.topviews.widget,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
  content: PropTypes.string,
};
TextWidgetComponent.defaultProps = {
  widget: {},
  backgroundColor: '',
  content: '',
};

export default TextWidgetComponent;

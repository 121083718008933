import React from 'react';
import PropTypes from 'prop-types';

import SvgWidget from '../BaseWidgets/SvgWidget';
import { Scorecard } from '~UI/svg/Scorecard';

const TITLE_POSITION = 0.10;

const ScorecardWidget = ({
  backgroundColor, h, w, x, y, widget, fetchDelay,
}) => {
  const scaledDownHeight = widget.title ? h * (1 - TITLE_POSITION) : h;
  const movedPosition = widget.title ? y + h * TITLE_POSITION : y;
  const loaderPos = {
    x,
    y: y + h / 3,
    width: w,
    height: h,
  };

  return (
    <SvgWidget
      dimension={{ x, y, w, h }}
      backgroundColor={backgroundColor}
      shape={widget.format.shape}
      title={widget.title}
      titleSize={widget.titleSize}
    >
      <Scorecard
        selectedObject={widget}
        fetchDelay={fetchDelay}
        dimension={{ x, y: movedPosition, w, h: scaledDownHeight }}
        loaderPos={loaderPos}
        backgroundColor={backgroundColor}
        isCircle={widget.format && widget.format.shape === 'circle'}
      />
    </SvgWidget>
  );
};

ScorecardWidget.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  fetchDelay: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    format: PropTypes.shape({
      shape: PropTypes.string,
    }),
    title: PropTypes.string,
    titleSize: PropTypes.number,
  }).isRequired,
};

export default ScorecardWidget;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { useShift } from '~utils/hooks';
import { serverTime, getDataRangeStartEnd } from '~utils/time';
import { getMetric } from '~components/utils';
import { round } from '~utils/math';
import Tick from '~utils/Tick';

import { Gauge } from './GaugeSVG';

const GaugeVariables = ({ selectedObject, dimension, textColor }) => {
  const socket = getSocket();
  const selectedObjectRef = useRef(selectedObject);

  useEffect(() => {
    selectedObjectRef.current = selectedObject;
  });

  const variables = useSelector(state => state.variables);
  const streams = useSelector(state => state.streams);
  const machines = useSelector(state => state.machines);
  const { t } = useTranslation();

  const properties = [].concat(...streams.map(s => s.properties));
  const machinesKPIS = [].concat(...machines.map(m => m.kpis));
  const all = [...properties, ...variables, ...machinesKPIS];
  const initialValue = all.find(elem => elem.id === selectedObject.valueId);

  const [isSocketInitialized, setIsSocketInitialized] = useState(false);
  const [values, setValues] = useState([]);
  const [displayedValue, setDisplayedValue] = useState(null);
  const [value, setValue] = useState(initialValue);
  const [goal, setGoal] = useState(selectedObject.goal);

  const [currentShift] = useShift(selectedObject.machineId);

  const fetchVariables = async () => {
    if (selectedObject.isAggregated) {
      const intervalType = selectedObject.intervalType || 'shift';
      const { start, end } = getDataRangeStartEnd(intervalType, currentShift);

      const { values: newValues } = await API.getValues(selectedObject.valueId,
        { timestamp: { $gte: start, $lt: end } });

      setValue(newValues);
    } else {
      API.getValues(selectedObject.valueId, {}, 1).then(response => {
        setDisplayedValue(response && response.values[0] && round(response.values[0].value, 2));
      });
    }
  };

  const filterValues = () => {
    const intervalType = selectedObject.intervalType || 'shift';
    const { start, end } = getDataRangeStartEnd(intervalType, currentShift);

    setValues(prevValues => prevValues.filter(v => v.timestamp >= start && v.timestamp <= end));
  };

  const handleSocketData = socketData => {
    if (socketData.id === selectedObjectRef.current.valueId) {
      if (selectedObjectRef.current.isAggregated) {
        const newValue = {
          timestamp: serverTime(),
          valueId: selectedObjectRef.current.valueId,
          value: socketData.value,
        };
        setValues(prevValues => [...prevValues, newValue]);
        filterValues();
      } else {
        setDisplayedValue(round(socketData.value, 2));
      }
    }
    if (selectedObjectRef.current.objectiveType === 'variable' && socketData.id === selectedObjectRef.current.goal) {
      const goalToSet = round(socketData.value, 2);
      if (goalToSet >= selectedObjectRef.current.intervalMin && goalToSet <= selectedObjectRef.current.intervalMax) {
        setGoal(goalToSet);
        return;
      }
      setGoal(-1);
    }
  };

  const evaluateVariables = () => {
    let aggregatedValue = null;

    if (values.length) {
      switch (selectedObject.aggregateType) {
        case 'sum':
          aggregatedValue = (values.reduce((prev, curr) => (prev + curr.value), 0)).toFixed(2);
          break;
        case 'min':
          aggregatedValue = Math.min(...values.map(v => v.value)).toFixed(2);
          break;
        case 'max':
          aggregatedValue = Math.max(...values.map(v => v.value)).toFixed(2);
          break;
        default:
          aggregatedValue = (values.reduce((prev, curr) => (prev + curr.value), 0)).toFixed(2);
          break;
      }
    }
    const roundedValue = aggregatedValue ? round(aggregatedValue, 1) : '';
    setDisplayedValue(roundedValue);
  };

  useEffect(() => {
    if (value) {
      fetchVariables();
    }
  }, []);

  useEffect(() => {
    if (socket && !isSocketInitialized) {
      socket.on('value', handleSocketData);
      setIsSocketInitialized(true);
    }

    if (selectedObject.isAggregated) {
      // We have to unsubscribe/subscribe because if the Tick was not started before, we have to do it now
      Tick.subscribe(filterValues, 5); // 5 seconds
    }
    const newProperties = [].concat(...streams.map(s => s.properties));
    const newMachinesKPIS = [].concat(...machines.map(m => m.kpis));
    const newAll = [...newProperties, ...variables, ...newMachinesKPIS];
    const newValue = newAll.find(elem => elem.id === selectedObject.valueId);
    setValue(newValue);
    if (newValue) {
      fetchVariables();
    }

    if (selectedObject.objectiveType === 'variable' && selectedObject.goal) {
      API.getValues(selectedObject.goal, {}, 1).then(response => {
        const goalToSet = response?.values[0] && round(response.values[0].value, 2);
        if (goalToSet >= selectedObject.intervalMin && goalToSet <= selectedObject.intervalMax) {
          setGoal(goalToSet);
          return;
        }
        setGoal(-1);
      });
    } else {
      setGoal(selectedObject.goal);
    }

    return () => {
      if (socket) {
        socket.removeListener('value', handleSocketData);
      }
      Tick.unsubscribe(filterValues);
    };
  }, [socket, selectedObject, currentShift]);

  const getVariableMetric = () => {
    const isKPI = machinesKPIS.find(kpi => kpi.id === value.id);
    if (isKPI) {
      return getMetric(value.name);
    }
    return 'unit';
  };

  useEffect(() => {
    evaluateVariables();
  }, [values]);

  if (!value) {
    return <div style={{ fontSize: '25px' }}>{t('variableDeleted')}</div>;
  }

  return (
    <Gauge
      selectedObject={selectedObject}
      currentValue={displayedValue}
      interval={getVariableMetric() === 'percentage'
        ? { min: selectedObject.intervalMin / 100, max: selectedObject.intervalMax / 100 }
        : { min: selectedObject.intervalMin, max: selectedObject.intervalMax }}
      metric={getVariableMetric()}
      dimension={dimension}
      textColor={textColor}
      showGoalToggle={typeof selectedObject.showGoalToggle === 'boolean' ? selectedObject.showGoalToggle : typeof selectedObject.goal === 'number'} // for backward compatibiltity with no toogle
      goal={getVariableMetric === 'percentage' ? goal / 100 : goal}
      units={selectedObject.units}
      aboveGoalColor={selectedObject.aboveGoalColor}
      belowGoalColor={selectedObject.belowGoalColor}
    />
  );
};

GaugeVariables.propTypes = {
  selectedObject: PropTypes.shape({
    showGoalToggle: PropTypes.bool,
    objectiveType: PropTypes.string,
    goal: PropTypes.number,
    aboveGoalColor: PropTypes.string,
    belowGoalColor: PropTypes.string,
    id: PropTypes.string,
    machineId: PropTypes.string,
    title: PropTypes.string,
    valueType: PropTypes.string,
    valueId: PropTypes.string,
    intervalMin: PropTypes.number,
    intervalMax: PropTypes.number,
    intervalType: PropTypes.string,
    units: PropTypes.string,
    isAggregated: PropTypes.bool,
    aggregateType: PropTypes.string,
  }).isRequired,
  dimension: PropTypes.object.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default GaugeVariables;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SelectionGrid } from '@intelligenceindustrielle/react-ui-components';
import { reduxOperations } from '~services';
import {
  CancelButton, DefaultModal, SubmitButton, FileUpload, FontAwesome,
} from '~UI/index';
import { showUpload, showError } from '~utils/toast';
import ImageGenerationForm from './ImageGenerationForm';

const ImagePopupForm = ({ onHide, show, onSubmit, addImage }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [currentPageNb, setCurrentPageNb] = useState(0);
  const [currentUploadType, setCurrentUploadType] = useState('upload');

  const handleSubmit = e => {
    e.preventDefault();
    if (!file) {
      showError(t('noFileSelected'));
      return;
    }
    if (onSubmit) {
      onSubmit(file);
      onHide();
      setFile(null);
      return;
    }
    showUpload(
      addImage(file),
      t('uploadingImage'),
      t('showSuccessAdded'),
    ).then(() => {
      onHide();
      setFile(null);
    });
  };

  const showPreviousPage = () => {
    setCurrentPageNb(0);
    setFile(null);
  };

  const showBackArrow = text => (
    <>
      {!!currentPageNb && (
        <FontAwesome
          icon="arrow-left"
          className="backButtonArrow"
          size="sm"
          onClick={showPreviousPage}
        />
      )}
      {text}
    </>
  );

  useEffect(() => {
    if (show) {
      setCurrentPageNb(0);
    }
  }, [show]);

  const handleSelectUploadType = type => {
    setCurrentPageNb(currentPageNb + 1);
    setCurrentUploadType(type);
  };

  return (
    <DefaultModal
      show={show}
      title={currentPageNb === 0 ? t('addImage') : showBackArrow(currentUploadType === 'upload' ? t('uploadImage') : t('generateImage'))}
      closePopup={onHide}
      children={
        currentPageNb === 0 ? (
          <SelectionGrid
            onChange={handleSelectUploadType}
            options={[
              {
                bgColor: '#333',
                label: t('uploadImage'),
                value: 'upload',
                Icon: <FontAwesome icon="upload" style={{ fontSize: '40px' }} />,
              },
              {
                bgColor: '#333',
                label: t('generateImage'),
                value: 'generate',
                Icon: <FontAwesome icon="sparklesGradient" height="40px" />,
              },
            ]}
          />
        ) : (
          <form
            id="Image__form"
          >
            {(currentUploadType === 'upload' && (
              <>
                <FileUpload type="popupForm" onFileSelect={setFile} />
                {file && (
                  <div className="fileName">
                    <div>
                      {t('fileAdded')}
                      :
                    </div>
                    -
                    {' '}
                    {file.name}
                  </div>
                )}
              </>
            ))
            || (currentUploadType === 'generate' && <ImageGenerationForm setFile={setFile} />)}

            <div className="buttonsHolder" style={{ textAlign: 'right' }}>
              <CancelButton onClick={onHide} />
              <SubmitButton label={t('create')} onClick={handleSubmit} />
            </div>
          </form>
        )
      }
    />
  );
};

ImagePopupForm.propTypes = {
  onHide: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addImage: reduxOperations.images.addImage,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(ImagePopupForm);

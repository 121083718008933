import { round } from '~utils/math';
import { serverTime } from '~utils/time';

// This function does NOT takes into account the start of the shift
// The given values should already be adjusted for this function
const getAverageValue = (values, decimals) => {
  if (!values || values.length === 0) {
    return 0;
  }

  const start = values.reduce((a, b) => (a.timestamp < b.timestamp ? a : b), 0).timestamp;
  const end = serverTime();
  const totalTime = end - start;
  const valuesWithRatio = values.map((value, index) => {
    if (index < values.length - 1) {
      return { ...value, ratio: (values[index + 1].timestamp - value.timestamp) / totalTime };
    }
    return { ...value, ratio: (end - value.timestamp) / totalTime };
  });

  return round(valuesWithRatio.reduce((a, b) => a + (b.value * b.ratio), 0), decimals);
};

// TODO: we have the pondered value. We should give the option to compute the average of a data set (no timeseries)
const getAggregatedValue = (values, aggregateType, decimals) => {
  if (!values || values.length === 0) {
    return 0;
  }

  switch (aggregateType) {
    case 'sum':
      return round(values.reduce((a, b) => a + b.value, 0), decimals);
    case 'avg':
      return getAverageValue(values, decimals);
    case 'max':
      return round(values.reduce((a, b) => (a.value > b.value ? a : b), 0).value, decimals);
    case 'min':
      return round(values.reduce((a, b) => (a.value < b.value ? a : b), 0).value, decimals);
    default:
      break;
  }
  return 'Error';
};

const createComparisonMetricObject = (comparisonMetric, currentValue, valueToCompare) => {
  if (currentValue === valueToCompare) {
    return {
      value: `0${comparisonMetric === 'percentage' ? '%' : ''}`,
      color: 'grey',
      isUp: null,
    };
  }

  const percentage = round(-(((valueToCompare - currentValue) / valueToCompare) * 100), 2);

  if (comparisonMetric === 'percentage' && Math.abs(percentage) !== Infinity) {
    return {
      value: `${percentage}%`,
      color: currentValue > valueToCompare ? 'green' : 'red',
      isUp: currentValue > valueToCompare,
    };
  }
  if (comparisonMetric === 'absolute' || comparisonMetric === 'percentage') {
    return {
      value: round(currentValue - valueToCompare, 2),
      color: currentValue > valueToCompare ? 'green' : 'red',
      isUp: currentValue > valueToCompare,
    };
  }
  return {};
};

// if aggregateType is null, it means the parameter "valuesToCompare" is already the result we need
const getComparisonMetricObject = (currentValue, valuesToCompare, aggregateType, comparisonMetric) => {
  const valueToCompare = aggregateType ? getAggregatedValue(valuesToCompare, aggregateType) : valuesToCompare;
  return createComparisonMetricObject(comparisonMetric, currentValue, valueToCompare);
};

export {
  getAverageValue,
  getAggregatedValue,
  getComparisonMetricObject,
  createComparisonMetricObject,
};

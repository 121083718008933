import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import { Text } from './Text';

const ResponsiveScorecard = memo(({
  x,
  y,
  w,
  h,
  selectedObject,
  value,
  units,
  hasLoaded,
  comparisonMetric,
  backgroundColor,
  isCircle,
  loaderPos,
}) => {
  const relativeWidth = isCircle ? 100 : (w / h) * 100; // if height is 100 what the width should be
  let displayedValue = value;
  if (typeof value === 'boolean') {
    displayedValue = value ? 'true' : 'false';
  }

  if (hasLoaded) {
    return (
      <svg viewBox={`0 0 ${relativeWidth} 100`} x={x} y={y} width={w} height={h}>
        <text>
          <Text
            selectedObject={selectedObject}
            value={displayedValue.toString()}
            units={units}
            comparisonMetric={comparisonMetric}
            backgroundColor={backgroundColor}
            relativeWidth={relativeWidth}
          />
        </text>
      </svg>
    );
  }

  return (
    <svg>
      <foreignObject {...loaderPos}>
        <Loader />
      </foreignObject>
    </svg>
  );
});

ResponsiveScorecard.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  selectedObject: PropTypes.object.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  w: PropTypes.number,
  h: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  units: PropTypes.string,
  hasLoaded: PropTypes.bool,
  comparisonMetric: PropTypes.string,
  isCircle: PropTypes.bool,
  loaderPos: PropTypes.object,
};
ResponsiveScorecard.defaultProps = {
  x: 0,
  y: 0,
  w: 0,
  h: 0,
  value: '',
  units: '',
  hasLoaded: false,
  comparisonMetric: '',
  isCircle: false,
  loaderPos: null,
};

export default ResponsiveScorecard;
